<div class="address-validation-flags">
    <ng-container *ngIf="validationResult?.error">
        <mat-icon 
            class="address-check-icon error-2-icon"
            matTooltipClass="break-line-tooltip" 
            [matTooltip]="formatErrorTooltip(validationResult.error)"
        >
            error
        </mat-icon>
    </ng-container>

    <ng-container *ngIf="validationResult.validationErrors.length; else addressCheckNoErrors">
        <div class="icon-with-badge">
            <mat-icon 
                class="address-check-icon error-icon"
                matTooltipClass="break-line-tooltip" 
                [matTooltip]="formatTooltip(validationResult.validationErrors, 'ERRORS')">
                error
            </mat-icon>
            <span class="badge">{{validationResult.validationErrors.length}}</span>
        </div>
    </ng-container>
    
    <ng-template #addressCheckNoErrors>
        <div class="icon-with-indicator" *ngIf="validationResult.isEdited; else normalCheck">
            <mat-icon class="address-check-icon check-icon" [ngClass]="{'blue-bg': validationResult.validationLevel === 4}">
                check
            </mat-icon>
            <mat-icon class="indicator-icon">edit</mat-icon>
        </div>
        <ng-template #normalCheck>
            <mat-icon class="address-check-icon check-icon"  [ngClass]="{'blue-bg': validationResult.validationLevel === 4}">
                check
            </mat-icon>
        </ng-template>
    </ng-template>

    <ng-container *ngIf="validationResult.validationWarnings.length">
        <div class="icon-with-badge">
            <mat-icon 
                class="address-check-icon warning-icon"
                matTooltipClass="break-line-tooltip"
                [matTooltip]="formatTooltip(validationResult.validationWarnings, 'WARNINGS')">
                error
            </mat-icon>
            <span class="badge">{{validationResult.validationWarnings.length}}</span>
        </div>
    </ng-container>

    <ng-container *ngIf="validationResult.emailInvalidWarning != ''">
        <mat-icon class="address-check-icon email-icon" matTooltip="{{'pages.address-check.validations.emailInvalidWarning' | translate}}">
            alternate_email
        </mat-icon>
    </ng-container>
    <ng-container *ngIf="validationResult.qstatRelocationFlag">
        <mat-icon class="address-check-icon monetization-icon">
            monetization_on
        </mat-icon>
    </ng-container>
</div>
