import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';

import { PdfAnalysisPreset } from '../../../../state/app-state';

@Component({
  selector: 'app-preset-item',
  templateUrl: './preset-item.component.html',
  styleUrls: ['./preset-item.component.scss'],
})
export class PresetItemComponent implements OnChanges {
  @Input() preset: PdfAnalysisPreset | null = null;
  @Output() onEdit: EventEmitter<string> = new EventEmitter();
  @Output() onDelete: EventEmitter<string> = new EventEmitter();
  displayedColumns: string[] = [
    'type',
    'width',
    'height',
    'topLeftX',
    'topLeftY',
  ];
  DATA_MAPPINGS: { [key: string]: string } = {
    postalAddressBoxLocation: 'Postal Address Box Location',
    emailAddressBoxLocation: 'Email Address Box Location',
    mobileNumberBoxLocation: 'Mobile Number Box Location',
    whiteLineBoxLocation: 'White Line Box Location',
  };
  tableData: any[] = [];

  ngOnChanges(changes: SimpleChanges) {
    if (changes['preset'] && changes['preset'].currentValue) {
      this.processData();
    }
  }

  processData() {
    this.tableData = Object.keys(this.DATA_MAPPINGS).map((key) => {
      const type = this.DATA_MAPPINGS[key];
      const data = this.preset![key as keyof PdfAnalysisPreset] as any;
      return {
        type,
        height: data.height,
        topLeftX: data.topLeftX,
        topLeftY: data.topLeftY,
        width: data.width,
      };
    });
  }

  onEditClick(id: string) {
    this.onEdit.emit(id);
  }

  onDeleteClick(id: string) {
    this.onDelete.emit(id);
  }

  getDynamicStyle(): { [key: string]: any } {
    let dynamicStyle: { [key: string]: any } = {
      'flex-direction': 'row',
      'justify-content': 'center',
      'align-items': 'center',
      'padding': '5px 16px',
      'gap': '10px',
      'width': '117px',
      'min-height': '24px',
      'border-radius': '100px',
      'line-height': '23px',
      'color': '#FFFFFF',
      'text-align': 'center',
      'font-family': 'Roboto',
      'background': this.getBackgroundColor(), 
    };

    return dynamicStyle;
  }

  getBackgroundColor(): string {
    switch (this.preset?.status) {
      case 'draft':
        return '#1186DB';
      case 'published':
        return '#82BE00';
      default:
        return '#1186DB'; 
    }
  }
}
