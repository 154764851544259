import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

import { PdfAnalysisPreset } from '../../../state/app-state';

@Component({
  selector: 'app-set-base-preset-dialog',
  template: `
    <div class="dialog-header">
      <img
        class="file-exchange-icon"
        alt="file-exchange"
        height="96"
        width="96"
        src="assets/icons/file-exchange.png"
      />
      <h2 mat-dialog-title>
      {{"pages.orderBasics.saveSettings.note" | translate}}
      </h2>
      <p>
      {{"pages.orderBasics.saveSettings.madeChanges" | translate}}
      </p>
    </div>
    <mat-dialog-content>
      <form [formGroup]="confirmForm" novalidate>
        <mat-radio-group formControlName="confirmation">
          <mat-radio-button value="ignore-proceed">
            {{"pages.orderBasics.saveSettings.ignoreProceed" | translate}}
          </mat-radio-button>
          <mat-radio-button value="apply-changes" [disabled]="data.isPublic || !data.hasDifferences">
            {{getApplyChangesLabel()}}
          </mat-radio-button>
          <mat-radio-button value="create-new">
            {{"pages.orderBasics.saveSettings.createNew" | translate}}
          </mat-radio-button>
          <div
            class="create-new-fields"
            *ngIf="confirmForm.value.confirmation === 'create-new'"
          >
            <div class="preset-name-field-wrapper">
              <span>{{'dialogs.nameConfigSet' | translate}}*</span>
              <mat-form-field appearance="outline" class="custom-mat-field">
                <input
                  matInput
                  [placeholder]="'dialogs.nameConfigSet' | translate"
                  formControlName="name"
                />
              </mat-form-field>
            </div>
            <mat-checkbox formControlName="isDefault">
              {{'dialogs.setNameConfigSet' | translate}}
            </mat-checkbox>
          </div>
        </mat-radio-group>
      </form>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
      <div class="button-bar">
        <button mat-raised-button class="footer-btn" (click)="cancel()">
          {{ 'presets.buttonLabels.cancel' | translate }}
        </button>
        <button
          mat-raised-button
          color="primary"
          class="footer-btn next-btn"
          (click)="next()"
        >
          {{ "buttons.saveAndNext" | translate}}
        </button>
      </div>
    </mat-dialog-actions>
  `,
  styles: [
    `
      .dialog-header {
        padding-top: 40px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        h2 {
          padding: 0;
        }
      }

      form {
        max-width: 26rem;
        margin: 0 auto;
      }

      mat-radio-group {
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
      }

      .create-new-fields {
        padding: 0 2.5rem;
        .preset-name-field-wrapper {
          margin-top: 0.5rem;
          padding: 0 12px;
          .custom-mat-field {
            margin: 0.5rem 0 1rem 0;
          }
        }
      }

      .button-bar {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 16px;
        border-top: 1px solid #f4f4f4;
        padding-top: 15px;
        padding-bottom: 15px;

        .footer-btn {
          min-width: 10rem;
          white-space: nowrap;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
        }

        .next-btn {
          border: 2px solid #ffffff;
        }
      }
    `,
  ],
})
export class OrderPresetDialogComponent {
  public confirmForm: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<OrderPresetDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { isPublic: boolean, hasDifferences: boolean, presetName: string },
    private fb: FormBuilder,
    private translate: TranslateService
  ) {
    this.confirmForm = this.fb.group({
      confirmation: new FormControl<
        'ignore-proceed' | 'apply-changes' | 'create-new'
      >('ignore-proceed'),
      name: new FormControl(''),
      isDefault: new FormControl(false),
    });
  }

  cancel() {
    this.dialogRef.close({ action: 'cancel' });
  }

  next() {
    this.dialogRef.close({ action: 'save-next', data: this.confirmForm.value });
  }

  getApplyChangesLabel(): string {
    if (this.data.isPublic || !this.data.hasDifferences) {
      return this.translate.instant('pages.orderBasics.saveSettings.modification');
    } else {
      return this.translate.instant('dialogs.applyChanges', { value: this.data.presetName });
    }
  }
}
