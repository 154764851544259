import { Injectable } from '@angular/core';
import { Firestore, Timestamp } from '@angular/fire/firestore';
import {
  collection,
  query,
  where,
  getDoc,
  getDocs,
  addDoc,
  updateDoc,
  doc,
} from 'firebase/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { Subscription, from, combineLatest } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { map } from 'rxjs/operators';

import {
  AppState,
  OrderStatus,
  OrderType,
  OrdersType,
  CurrentEditStep,
} from '../state/app-state';

import { AppStateService } from './app-state.service';
import { isObjectEmpty } from '../components/shared/utils/helper';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class OrderService {
  private appStateSubscription?: Subscription;
  private currentState!: AppState;

  constructor(
    private firestore: Firestore,
    private auth: AngularFireAuth,
    private translate: TranslateService,
    private appStateService: AppStateService,
    private toastr: ToastrService
  ) {
    this.appStateSubscription = this.appStateService.appState$.subscribe(
      (state) => {
        this.currentState = state;
      }
    );
  }

  async createNewOrder(order: Partial<OrderType>): Promise<OrderType> {
    const user = await this.auth.currentUser;
    if (!isObjectEmpty(user)) {
      const newOrder = {
        schemaVersion: 4,
        userId: user!.uid,
        userLoginSnapshot: user!.email,
        profileId: this.currentState.profileId!,
        status: 'Draft',
        createdAt: Timestamp.now(),
        modifiedAt: Timestamp.now(),
        numRecipients: 0,
        amountCents: 0,
        currentEditStep: 'doc-upload',
        deliveryTypeLineItems: [],
        allowedChannels: {
          isEpostAllowed: true,
          isPrintAllowed: true,
          isEmailAllowed: true,
        },
        ...order,
      } as OrderType;
      // store the order using angular fire
      const ordersCollectionRef = collection(this.firestore, 'orders');
      const docRef = await addDoc(ordersCollectionRef, newOrder);
      const insertedOrder: OrderType = {
        ...newOrder,
        id: docRef.id,
      };
      const orderList = this.currentState.orders.list;
      const currentEditOrder = insertedOrder;

      orderList.set(currentEditOrder.id, currentEditOrder);
      this.appStateService.updateState({
        currentEditOrder,
        orders: {
          ...this.currentState.orders,
          list: orderList,
        },
      });

      return currentEditOrder as OrderType;
    } else {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate.get('errMsg.notLoggedIn').subscribe((res: string) => {
          errorMessage = res;
        });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });

      throw new Error(errorMessage || 'Unknown error');
    }
  }

  async getOrder(orderId: string): Promise<void> {
    const orderRef = doc(this.firestore, 'orders', orderId);
    const docSnapshot = await getDoc(orderRef);
    if (docSnapshot.exists()) {
      const currentEditOrder = {
        id: docSnapshot.id,
        ...docSnapshot.data(),
      } as OrderType;

      this.appStateService.updateState({ currentEditOrder });
    } else {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate.get('errMsg.noSuchOrder').subscribe((res: string) => {
          errorMessage = res;
        });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
    }
  }

  async getOrderWithPdfAnalysisResults(orderId: string): Promise<void> {
    // Get a reference to the order document
    const orderRef = doc(this.firestore, 'orders', orderId);

    // Fetch the order data
    const order$ = from(getDoc(orderRef)).pipe(
      map((docSnapshot) => {
        const data = docSnapshot.data();
        const id = docSnapshot.id;
        return { id, ...data };
      })
    );

    // Get a reference to the pdfAnalysisResults subcollection
    const pdfAnalysisResultsRef = collection(orderRef, 'pdfAnalysisResults');

    // Fetch the pdfAnalysisResults data
    const pdfAnalysisResults$ = from(getDocs(pdfAnalysisResultsRef)).pipe(
      map((querySnapshot) =>
        querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const id = doc.id;
          return {
            id,
            numPages: data['numPages'] ?? 0,
            startsAtPage: data['startsAtPage'] ?? 0,
            pages: data['pages'] ?? [],
            messages: data['messages'] ?? [],
          };
        })
      )
    );

    return new Promise((resolve, reject) => {
      combineLatest([order$, pdfAnalysisResults$]).subscribe(
        ([order, pdfAnalysisResults]) => {
          const currentEditOrder = {
            ...order,
            pdfAnalysisResults,
          } as OrderType;
          this.appStateService.updateState({ currentEditOrder });
          resolve();
        },
        (error) => {
          let errorMessage = error?.error?.error_message;
          if (!errorMessage) {
            this.translate
              .get('errMsg.fetchOrderData')
              .subscribe((res: string) => {
                errorMessage = res;
              });
          }

          this.translate.get('errMsg.error').subscribe((res: string) => {
            this.toastr.error(errorMessage, res);
          });

          reject();
        }
      );
    });
  }

  updateOrder(updateData: Partial<OrderType>): Promise<void> {
    const currentOrder = this.currentState.currentEditOrder as OrderType;
    const orderRef = doc(this.firestore, 'orders', currentOrder.id);

    const updatedProps: Partial<OrderType> = {
      ...updateData,
      modifiedAt: Timestamp.now(),
    };

    return updateDoc(orderRef, updatedProps)
      .then(() => {
        const orderList = this.currentState.orders.list;
        const updatedOrder = {
          ...currentOrder,
          ...updatedProps,
        } as OrderType;

        orderList.set(updatedOrder.id, updatedOrder);
        this.appStateService.updateState({
          currentEditOrder: updatedOrder,
          orders: {
            ...this.currentState.orders,
            list: orderList,
          },
        });
      })
      .catch((error) => {
        let errorMessage = error?.error?.error_message;
        if (!errorMessage) {
          this.translate.get('errMsg.updateData').subscribe((res: string) => {
            errorMessage = res;
          });
        }

        this.translate.get('errMsg.error').subscribe((res: string) => {
          this.toastr.error(errorMessage, res);
        });
      });
  }

  updateCurrentOrderEditStep(editStep: CurrentEditStep): Promise<void> {
    const currentEditOrder = {
      ...this.currentState.currentEditOrder,
      currentEditStep: editStep,
    } as OrderType;
    this.appStateService.updateState({ currentEditOrder });
    return this.updateOrder({ currentEditStep: editStep });
  }

  updateOrderPdfAnalysis(): Promise<void> {
    const status: OrderStatus = 'PDFAnalysis';
    const currentOrderId = this.currentState.currentEditOrder?.id;
    const orderRef = doc(this.firestore, 'orders', currentOrderId!);
    const updatedProps: Partial<OrderType> = {
      status,
      modifiedAt: Timestamp.now(),
      // pdfAnalysisStatus: {
      //   preparingIsFileDownloaded: false,
      //   stage: 'WAITING',
      //   preparingDocumentCurrentNum: 0,
      //   preparingDocumentCount: 0,
      //   detectingCurrentPageNum: 0,
      //   detectingNumPages: 0,
      //   segregatingCurrentPageNum: 0,
      //   segregatingNumPages: 0,
      //   validationCurrentMessageNum: 0,
      //   validationNumMessages: 0,
      // },
    };
    return updateDoc(orderRef, updatedProps)
      .then(() => {
        const currentEditOrder = {
          ...this.currentState.currentEditOrder!,
          ...updatedProps,
        };
        const orderList = new Map(
          Array.from(this.currentState.orders.list.entries()).map(
            ([id, order]) =>
              id === currentOrderId
                ? [id, { ...order, ...updatedProps }]
                : [id, order]
          )
        );

        this.appStateService.updateState({
          currentEditOrder,
          orders: {
            ...this.currentState.orders,
            list: orderList,
          },
        });
      })
      .catch((error) => {
        let errorMessage = error?.error?.error_message;
        if (!errorMessage) {
          this.translate
            .get('errMsg.updateStatusPDFAnalysis')
            .subscribe((res: string) => {
              errorMessage = res;
            });
        }

        this.translate.get('errMsg.error').subscribe((res: string) => {
          this.toastr.error(errorMessage, res);
        });
      });
  }

  async loadDoneOrders() {
    const doneOrdersQuery = query(
      collection(this.firestore, 'orders'),
      where('profileId', '==', this.currentState.currentProfile!.id),
      where('status', '==', 'Done')
    );

    return this.loadOrdersByQuery(doneOrdersQuery, 'listDone');
  }

  async loadOrdersNotDone() {
    const statuses = [
      'Draft',
      'Submitted',
      'Processing',
      'PDFAnalysis',
      'ChannelDetermination',
      'PdfSplitting',
      'MessageProduction',
      'InProduction',
      'Error',
      'PDFMismatch',
      'PDFPageLimit',
    ];
    const notDoneOrdersQuery = query(
      collection(this.firestore, 'orders'),
      where('profileId', '==', this.currentState.currentProfile!.id),
      where('status', 'in', statuses)
    );

    return this.loadOrdersByQuery(notDoneOrdersQuery, 'list');
  }

  private async loadOrdersByQuery(
    ordersQuery: any,
    stateProperty: 'list' | 'listDone'
  ) {
    try {
      const querySnapshot = await getDocs(ordersQuery);
      const orders: Map<string, OrderType> = new Map();
      querySnapshot.docs.forEach((doc: any) => {
        const order = { id: doc.id, ...doc.data() } as OrderType;
        orders.set(order.id, order);
      });

      // Determine the property to update based on the stateProperty argument
      const updatedState: Partial<OrdersType> = {
        isLoaded: true,
      };
      updatedState[stateProperty] = orders;

      // Update the appropriate state property.
      this.appStateService.updateState({
        orders: {
          ...this.currentState.orders,
          ...updatedState,
        },
      });
    } catch (error: any) {
      this.handleLoadOrdersError(error);
    }
  }

  private handleLoadOrdersError(error: any) {
    let errorMessage = error?.error?.error_message;
    if (!errorMessage) {
      this.translate.get('errMsg.loadingOrders').subscribe((res: string) => {
        errorMessage = res;
      });
    }

    this.translate.get('errMsg.error').subscribe((res: string) => {
      this.toastr.error(errorMessage, res);
    });
  }

  ngOnDestroy(): void {
    this.appStateSubscription?.unsubscribe();
  }
}
