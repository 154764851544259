import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
    selector: 'app-confirmation-dialog',
    template: `
      <h2 mat-dialog-title>{{"confirmation" | translate}}</h2>
      <mat-dialog-content>{{ data.message }}</mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="false">{{"presets.no" | translate}}</button>
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial>{{"presets.yes" | translate}}</button>
      </mat-dialog-actions>
    `
  })
export class ConfirmationDialogComponent {
constructor(
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
) { }
}
  