<div class="background">
    <h1 class="title">{{"pages.noPofileFound.title" | translate}}</h1>
    <p class="message">
        {{"pages.noPofileFound.line1" | translate}}
    </p>
    <p class="message">
        {{"pages.noPofileFound.line2" | translate}}
    </p>
    <button mat-raised-button color="primary" class="action-btn" (click)="logout()">
        {{"pages.noPofileFound.signOut" | translate}}
    </button>
</div>