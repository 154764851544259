import {
  Component,
  OnInit,
  Input,
  ViewChild,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';

import {
  AppState,
  OrderType,
  AddressValidationResult,
  OrderLevelToMsgProps,
  PdfAnalysisResult,
} from '../../../../state/app-state';
import { PdfAnalysisResultService } from '../../../../services/pdf-analysis-result.service';
import { AppStateService } from '../../../../services/app-state.service';
import { extractAddressInformation } from '../../utils/address-utils';
import { isObjectEmpty, isArrayEmpty } from '../../utils/helper';

interface RecipientInfo {
  email: string;
  recipient: string;
}

interface klaraStatus {
  klaraDeliveryStatus: string;
  klaraDeliveryStatusMessage: string;
}

@Component({
  selector: 'app-order-item-messages',
  templateUrl: './order-item-messages.component.html',
  styleUrls: ['./order-item-messages.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderItemMessagesComponent implements OnInit {
  @Input() orderId!: string;
  @ViewChild(MatSort, { static: false }) sort!: MatSort;
  order: OrderType | null = null;
  dataSource = new MatTableDataSource([] as AddressValidationResult[]);
  // TODO changes pipe
  isRecipientType(item: any): item is RecipientInfo {
    return (
      item && typeof item === 'object' && 'recipient' in item && 'email' in item
    );
  }
  columns = [
    {
      columnDef: 'recipient',
      header: 'Recipient',
      cell: (element: AddressValidationResult): RecipientInfo => {
        const email = element.email != undefined ? element.email : null;
        const recipient = element?.address?.recipient;
        return { email: email || '', recipient };
      },
      cellClass: '',
      sortable: true,
    },
    {
      columnDef: 'envelope',
      header: 'Envelope',
      cell: (element: AddressValidationResult) => {
        let envelopeSize = element?.envelopeSize;
        if (element.selectedChannel !== 'print') {
          envelopeSize = 'n/a';
        } else {
          const pages = !element.additionalBlankPage
            ? element.numPages + element.attachmentNumPages
            : element.numPages + 1 + element.attachmentNumPages;
          envelopeSize = pages <= 14 ? 'C5' : 'C4';
        }

        return envelopeSize;
      },
      cellClass: 'cell-pages',
      sortable: true,
    },
    {
      columnDef: 'pages',
      header: 'Pages',
      cell: (element: AddressValidationResult) =>
        !element.additionalBlankPage
          ? element.numPages + element.attachmentNumPages
          : element.numPages + 1 + element.attachmentNumPages,
      cellClass: 'cell-pages',
      sortable: true,
    },
    {
      columnDef: 'country',
      header: 'Country',
      cell: (element: AddressValidationResult) => element,
      cellClass: '',
      sortable: true,
    },
    {
      columnDef: 'subject',
      header: 'Subject',
      cell: (element: AddressValidationResult) => element,
      cellClass: 'subject',
      sortable: false,
    },
    {
      columnDef: 'channel',
      header: 'Channel',
      cell: (element: any) => element.selectedChannel || 'n/a',
      cellClass: 'channel',
      sortable: true,
    },
    {
      columnDef: 'delivery',
      header: 'Delivery',
      cell: (element: AddressValidationResult): klaraStatus => {
        const klaraStatus = element.klaraDeliveryStatus != undefined ? element.klaraDeliveryStatus : 'n/a';
        const klaraStatusMessage = element?.klaraDeliveryStatusMessage;
        return { klaraDeliveryStatus: klaraStatus || 'n/a', klaraDeliveryStatusMessage: klaraStatusMessage || '' };
      },
      cellClass: 'delivery',
      sortable: true,
    },
  ];
  displayedColumns: string[] = ['recipient', 'pages', 'envelope', 'country', 'subject', 'channel','delivery'];

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private pdfAnalysisResultService: PdfAnalysisResultService,
    private stateService: AppStateService
  ) {}

  async ngOnInit(): Promise<void> {
    await this.pdfAnalysisResultService
      .getPdfAnalysisResults(this.orderId)
      .then(async (order: OrderType) => {
        this.order = order;

        if (!isObjectEmpty(this.order) && !isArrayEmpty(this.order?.pdfAnalysisResults)) {
          await this.updateDataSource();
        }
      });
  }

  async updateDataSource() {
    this.dataSource = new MatTableDataSource(this.getAllAddresses);
    this.dataSource.sortingDataAccessor = (
      item: AddressValidationResult,
      property: string
    ): string | number => {
      if (property === 'recipient') {
        return item.overrideRecipientDetails?.address?.recipient
          ? item.overrideRecipientDetails?.address?.recipient
          : item.address.recipient;
      }

      if (property === 'subject') {
        return item.subject || 'n/a';
      }

      if (property === 'channel') {
        return item.selectedChannel || 'n/a';
      }

      if (property === 'delivery') {
        return item.klaraDeliveryStatus || 'n/a';
      }
   
      return item.address?.recipient ?? '';
    };
    this.dataSource.sort = this.sort;

    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  get getAllAddresses(): AddressValidationResult[] {
    const {
      pdfAnalysisResults: orderPdfAnalysisResults,
      subject,
      docType,
      postalDeliveryTypeDomestic,
      printMetadata,

    } = this.order || {};
    const pdfAnalysisResults = orderPdfAnalysisResults as PdfAnalysisResult[];
    const orderLevelToMsgProps = {
      subject,
      docType,
      postalDeliveryTypeDomestic,
      printMetadata,
    } as OrderLevelToMsgProps;

    const addressValidationResults = pdfAnalysisResults
      // Flatten the messages arrays into a single array
      ?.flatMap((pdfAnalysisResult) =>
        pdfAnalysisResult.messages.map((message) => {
          return {
            ...message,
            docId: pdfAnalysisResult.id,
            klaraDeliveryStatus: message.klaraDeliveryStatus,
            klaraDeliveryStatusMessage: message.klaraDeliveryStatusMessage
          };
        })
      )
      // Don't include deleted messages.
      .filter((message) => (!message?.isDeleted ? true : false))
      .map((x) => extractAddressInformation(x, orderLevelToMsgProps))
      // Sort the order item messages based on delivery status
      .sort((a, b) => {
        if (a.klaraDeliveryStatus === 'FAILED') {
          return -1;
        }
        else if (b.klaraDeliveryStatus === 'FAILED') {
          return 1;
        }
        return 0;
      });

    return addressValidationResults ?? [];
  }

  shouldDisplayColumn(columnDef: string): boolean {
    const excludedColumns = ['channel', 'country', 'subject', 'delivery'];
    return !excludedColumns.includes(columnDef);
  }

}
