<div style="height: 100%; display: flex; flex-direction: row; align-items: stretch;">
    <div style="overflow-x: auto;">
        <div *ngFor="let image of selectedRecipient?.fileUrls; let i = index"
            style="display: inline-block; margin: 20px 24px 20px 48px;">
            <!-- Default black image as a placeholder -->
            <div *ngIf="!imageLoaded[i]" class="skeleton-loader mat-elevation-z8"></div>
            <!-- Actual image -->
            <div class="a4-page-container">
                <div *ngIf="imageLoaded[i] && i === 0" class="address-box" [ngStyle]="getPresetBoxStyle()"></div>
                <img [src]="image" (load)="imageLoaded[i] = true" [style.display]="imageLoaded[i] ? 'inline' : 'none'"
                style="height: 100%; width: 100%; border: 1px solid #d1d1d1;" class="mat-elevation-z8">
            </div>
        </div>
    </div>
    <div style="
        display: flex;
        flex-direction: column;
        height: 100%;
        align-items: stretch;
        flex-grow: 1;">
        <button mat-icon-button (click)="closeDialog()" style="align-self: end" tabindex="-1">
            <img src="assets/logos/close-icon.svg" style="height: 16px; width: 16px">
        </button>
        <form 
            class="recipient-edit-form" 
            [formGroup]="recipientForm" 
            (ngSubmit)="onClickSave()" 
        >

            <div mat-dialog-content style="max-height: 100%; height: 100%;">
                <mat-tab-group dynamicHeight [(selectedIndex)]="selectedTabIndex">
                    <mat-tab label="{{'pages.addressCheck.editRecipient.basics' | translate}}">
                    <div [class.error-margin]="recipientForm.get('recipient')?.invalid">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{"pages.addressCheck.editRecipient.recipient" | translate}}</mat-label>
                            <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1"
                                cdkAutosizeMaxRows="5" [placeholder]="'pages.addressCheck.editRecipient.recipient' | translate" formControlName="recipient"></textarea>
                            <mat-error *ngIf="isRecipientCustomError">
                                {{ formValidationService.fieldFlagsStates['recipient'].customErrorMessage }}
                            </mat-error>
                            <mat-error *ngIf="recipientForm.get('recipient')?.hasError('required')">
                                {{"pages.addressCheck.editRecipient.recipientRequired" | translate}}
                            </mat-error>
                            <mat-hint *ngIf="isRecipientCustomWarning" class="custom-warning">
                                {{ formValidationService.fieldFlagsStates['recipient'].customWarningMessage  }}
                            </mat-hint>
                        </mat-form-field>
                    </div>
        
                    <div 
                        [class.error-margin]="(recipientForm.get('street')?.invalid || recipientForm.get('number')?.invalid)"
                        style="display: flex; flex-direction: row; justify-content: space-between;"
                    >
                        
                        <div style="flex-grow: 1; margin-right: 5px;">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label> {{"pages.addressCheck.editRecipient.street" | translate}}</mat-label>
                                <input matInput [placeholder]="'pages.addressCheck.editRecipient.street' | translate" formControlName="street">
                                <mat-error *ngIf="isStreetCustomError">
                                    {{ formValidationService.fieldFlagsStates['street'].customErrorMessage }}
                                </mat-error>
                                <mat-error *ngIf="recipientForm.get('street')?.hasError('required')">
                                    {{"pages.addressCheck.editRecipient.streetRequired" | translate}}
                                </mat-error>
                                <mat-hint *ngIf="isStreetCustomWarning" class="custom-warning">
                                    {{ formValidationService.fieldFlagsStates['street'].customWarningMessage }}
                                </mat-hint>
                                <mat-icon 
                                    *ngIf="suggestedAddress?.street && suggestedAddress?.street !== recipientForm.value.street"
                                    matSuffix
                                    tabindex="0"
                                    [appHoverPopover]="popoverContent1"
                                >
                                    info
                                </mat-icon>
                                <ng-template #popoverContent1>
                                    <div class="popover-content">
                                      <div class="popover-message">
                                        <p class="capitalize-first-letter">{{selectedRecipient?.validationEngine + " " + ("pages.addressCheck.editRecipient.suggestedCorrectValue" | translate)}}</p>
                                        <p class="suggested-value">{{suggestedAddress.street}}</p>
                                        <p *ngIf="suggestedAddress?.excessStreetText && !isStreetOverridden" class="capitalize-first-letter">
                                            {{"pages.addressCheck.editRecipient.recommended" | translate}} <b>{{"pages.addressCheck.editRecipient.recipient" | translate}}</b>
                                        </p>
                                        <p *ngIf="suggestedAddress?.excessStreetText && !isStreetOverridden" class="suggested-value">
                                            {{ suggestedAddress.excessStreetText }}
                                        </p>
                                      </div>
                                      <div class="action-buttons">
                                        <mat-icon 
                                            class="close" 
                                            tabindex="0" 
                                            (click)="hideAllPopovers($event)"
                                            (keydown.enter)="handleEnterKey($event, 'street')"
                                        >
                                            close
                                        </mat-icon>
                                        <mat-icon 
                                            class="check" 
                                            tabindex="0" 
                                            (click)="
                                                $event.stopPropagation(); 
                                                $event.preventDefault(); 
                                                handleSuggestionOverride('street', suggestedAddress.street!, (!isStreetOverridden ? suggestedAddress.excessStreetText : ''));
                                            "
                                            (keydown.enter)="handleSuggestionOverride('street', suggestedAddress.street!, (!isStreetOverridden ? suggestedAddress.excessStreetText : ''), $event);"
                                        >
                                            check
                                        </mat-icon>
                                      </div>
                                    </div>
                                </ng-template>
                            </mat-form-field>
                        </div>
        
                        <div style="max-width: 120px;">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>{{"pages.addressCheck.editRecipient.no" | translate}}</mat-label>
                                <input matInput [placeholder]="'pages.addressCheck.editRecipient.no' | translate" formControlName="number">
                                <mat-error 
                                    *ngIf="isNumberCustomError"
                                    class="error-ellipsis" 
                                    matTooltip="{{ formValidationService.fieldFlagsStates['number'].customErrorMessage }}"
                                >
                                    {{ formValidationService.fieldFlagsStates['number'].customErrorMessage }}
                                </mat-error>
                                <!-- <mat-error 
                                    class="error-ellipsis"
                                    matTooltip="No. is required."
                                    *ngIf="recipientForm.get('number')?.hasError('required')"
                                >
                                    No. is required.
                                </mat-error> -->
                                <mat-hint 
                                    *ngIf="isNumberCustomWarning"
                                    class="custom-warning"
                                    matTooltip="{{ formValidationService.fieldFlagsStates['number'].customWarningMessage }}" 
                                >
                                    {{ formValidationService.fieldFlagsStates['number'].customWarningMessage }}
                                </mat-hint>
                                <mat-icon
                                    *ngIf="suggestedNumberValue(suggestedAddress) && suggestedNumberValue(suggestedAddress) !== recipientForm.value.number"
                                    matSuffix 
                                    tabindex="0"
                                    [appHoverPopover]="popoverContent2"
                                >
                                    info
                                </mat-icon>
                                <ng-template #popoverContent2>
                                    <div class="popover-content">
                                      <div class="popover-message">
                                        <p class="capitalize-first-letter">{{selectedRecipient?.validationEngine + " " + ("pages.addressCheck.editRecipient.suggestedCorrectValue" | translate)}}</p>
                                        <p class="suggested-value">{{suggestedNumberValue(suggestedAddress)}}</p>
                                      </div>
                                      <div class="action-buttons">
                                        <mat-icon 
                                            class="close" 
                                            tabindex="0"
                                            (click)="hideAllPopovers($event);"
                                            (keydown.enter)="handleEnterKey($event, 'number');"
                                        >
                                            close
                                        </mat-icon>
                                        <mat-icon 
                                            class="check"
                                            tabindex="0" 
                                            (click)="
                                                $event.stopPropagation(); 
                                                $event.preventDefault(); 
                                                handleSuggestionOverride('number', suggestedNumberValue(suggestedAddress));
                                            "
                                            (keydown.enter)="handleSuggestionOverride('number', suggestedNumberValue(suggestedAddress), '', $event);"
                                        >
                                            check
                                        </mat-icon>
                                      </div>
                                    </div>
                                </ng-template>
                            </mat-form-field>
                        </div>
                    </div>
        
                    <div 
                        [class.error-margin]="(recipientForm.get('zip')?.invalid || recipientForm.get('city')?.invalid)"
                        style="display: flex; flex-direction: row; justify-content: space-between;"
                    >
                        
                        <div style="max-width: 120px;">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>{{"pages.addressCheck.editRecipient.zip" | translate}}</mat-label>
                                <input matInput [placeholder]="'pages.addressCheck.editRecipient.zip' | translate" formControlName="zip">
                                <mat-error
                                    *ngIf="isZipCustomError"
                                    class="error-ellipsis" 
                                    matTooltip="{{ formValidationService.fieldFlagsStates['zip'].customWarningMessage }}" 
                                >
                                    {{ formValidationService.fieldFlagsStates['zip'].customErrorMessage }}
                                </mat-error>
                                <mat-error
                                    *ngIf="recipientForm.get('zip')?.hasError('required')"
                                    class="error-ellipsis"
                                    matTooltip="{{'pages.addressCheck.editRecipient.zipRequired' | translate}}"
                                >
                                {{"pages.addressCheck.editRecipient.zipRequired" | translate}}
                                </mat-error>
                                <mat-hint 
                                    *ngIf="isZipCustomWarning" 
                                    class="custom-warning"
                                    matTooltip="{{ formValidationService.fieldFlagsStates['zip'].customWarningMessage }}"
                                >
                                    {{ formValidationService.fieldFlagsStates['zip'].customWarningMessage }}
                                </mat-hint>
                                <mat-icon 
                                    *ngIf="suggestedAddress?.zip && suggestedAddress?.zip !== recipientForm.value.zip"
                                    matSuffix
                                    tabindex="0"
                                    [appHoverPopover]="popoverContent3"
                                >
                                    info
                                </mat-icon>
                                <ng-template #popoverContent3>
                                    <div class="popover-content">
                                      <div class="popover-message">
                                        <p class="capitalize-first-letter">{{selectedRecipient?.validationEngine + " " + ("pages.addressCheck.editRecipient.suggestedCorrectValue" | translate)}}</p>
                                        <p class="suggested-value">{{suggestedAddress.zip}}</p>
                                      </div>
                                      <div class="action-buttons">
                                        <mat-icon 
                                            class="close"
                                            tabindex="0" 
                                            (click)="hideAllPopovers($event);"
                                            (keydown.enter)="handleEnterKey($event, 'zip');"
                                        >
                                            close
                                        </mat-icon>
                                        <mat-icon 
                                            class="check"
                                            tabindex="0" 
                                            (click)="
                                                $event.stopPropagation(); 
                                                $event.preventDefault(); 
                                                handleSuggestionOverride('zip', suggestedAddress.zip!);
                                            "
                                            (keydown.enter)="handleSuggestionOverride('zip', suggestedAddress.zip!, '', $event);"
                                        >
                                            check
                                        </mat-icon>
                                      </div>
                                    </div>
                                </ng-template>
                            </mat-form-field>
                        </div>
        
                        <div style="flex-grow: 1; margin-left: 5px;">
                            <mat-form-field appearance="outline" class="full-width">
                                <mat-label>{{"pages.addressCheck.editRecipient.city" | translate}}</mat-label>
                                <input matInput [placeholder]="'pages.addressCheck.editRecipient.city' | translate" formControlName="city">
                                <mat-error *ngIf="isCityCustomError">
                                    {{ formValidationService.fieldFlagsStates['city'].customErrorMessage }}
                                </mat-error>
                                <mat-error *ngIf="recipientForm.get('city')?.hasError('required')">
                                    {{"pages.addressCheck.editRecipient.cityRequired" | translate}}
                                </mat-error>
                                <mat-hint *ngIf="isCityCustomWarning" class="custom-warning">
                                    {{ formValidationService.fieldFlagsStates['city'].customWarningMessage }}
                                </mat-hint>
                                <mat-icon 
                                    *ngIf="suggestedAddress?.city && suggestedAddress?.city !== recipientForm.value.city"
                                    matSuffix
                                    tabindex="0"
                                    [appHoverPopover]="popoverContent4"
                                >
                                    info
                                </mat-icon>
                                <ng-template #popoverContent4>
                                    <div class="popover-content">
                                      <div class="popover-message">
                                        <p class="capitalize-first-letter">{{selectedRecipient?.validationEngine + " " + ("pages.addressCheck.editRecipient.suggestedCorrectValue" | translate)}}</p>
                                        <p class="suggested-value">{{suggestedAddress.city}}</p>
                                      </div>
                                      <div class="action-buttons">
                                        <mat-icon 
                                            class="close"
                                            tabindex="0" 
                                            (click)="hideAllPopovers($event);"
                                            (keydown.enter)="handleEnterKey($event, 'city');"
                                        >
                                            close
                                        </mat-icon>
                                        <mat-icon 
                                            class="check"
                                            tabindex="0" 
                                            (click)="
                                                $event.stopPropagation();
                                                $event.preventDefault();
                                                handleSuggestionOverride('city', suggestedAddress.city!);
                                            "
                                            (keydown.enter)="handleSuggestionOverride('city', suggestedAddress.city!, '', $event);"
                                        >
                                            check
                                        </mat-icon>
                                      </div>
                                    </div>
                                </ng-template>
                            </mat-form-field>
                        </div>
                    </div>

                    <div *ngIf="!selectedRecipient?.isSwissAddress">
                        <mat-form-field appearance="outline" class="full-width">
                          <mat-label>{{ 'pages.addressCheck.editRecipient.country' | translate }}</mat-label>
                          <div class="input-container">
                            <input matInput [placeholder]="'pages.addressCheck.editRecipient.country' | translate" formControlName="country">
                            <ng-container *ngIf="selectedRecipient?.address?.countryCode">
                              <span [ngClass]="'flag-icon fi fi-' + (selectedRecipient?.address?.countryCode | lowercase)" matTooltip="{{selectedRecipient?.address?.countryCode}}"></span>
                            </ng-container>
                          </div>
                        </mat-form-field>
                      </div>
                      
                        
                    <div [class.error-margin]="recipientForm.get('email')?.invalid">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{"pages.addressCheck.editRecipient.email" | translate}}</mat-label>
                            <input matInput [placeholder]="'pages.addressCheck.editRecipient.email' | translate" formControlName="email">
                            <mat-error *ngIf="recipientForm.get('email')?.invalid && recipientForm.get('email')?.touched">
                                {{"pages.addressCheck.editRecipient.emailValid" | translate}}
                            </mat-error>
                            <mat-hint 
                                class="custom-warning"
                                *ngIf="!isEmailValid && recipientForm.get('email')?.value && recipientForm.get('email')?.valid" 
                            >
                            {{"pages.addressCheck.editRecipient.emailInvalid" | translate}}
                            </mat-hint>
                        </mat-form-field>
                    </div>
        
                    <mat-divider></mat-divider>
                    <div style="margin-top: 1.5rem;">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{"pages.addressCheck.editRecipient.subject" | translate}}</mat-label>
                            <input matInput [placeholder]="'pages.addressCheck.editRecipient.subject' | translate" formControlName="subject">
                            <mat-icon 
                                *ngIf="!isSubjectEditMode" 
                                matSuffix 
                                matTooltip="{{ 'pages.addressCheck.editRecipient.editSubject' | translate }}" 
                                (click)="this.toggleEditMode('subject', 'isSubjectEditMode')"
                            >
                                mode_edit
                            </mat-icon>
                            <mat-icon 
                                *ngIf="isSubjectEditMode" 
                                matSuffix 
                                (click)="this.toggleEditMode('subject', 'isSubjectEditMode')"
                            >
                                check
                            </mat-icon>
                        </mat-form-field>
                    </div>
        
                    <mat-form-field appearance="outline" class="full-width">
                        <mat-label>{{"pages.addressCheck.editRecipient.docType" | translate}}</mat-label>
                        <mat-select [disabled]="!isDocTypeEditMode" formControlName="docType">
                            <mat-option value="invoice">{{"pages.addressCheck.editRecipient.invoice" | translate}}</mat-option>
                            <mat-option value="reminder">{{"pages.addressCheck.editRecipient.reminder" | translate}}</mat-option>
                            <mat-option value="message">{{"pages.addressCheck.editRecipient.message" | translate}}</mat-option>
                            <mat-option value="contract">{{"pages.addressCheck.editRecipient.contract" | translate}}</mat-option>
                        </mat-select>
                        <mat-icon 
                            *ngIf="!isDocTypeEditMode" 
                            matSuffix 
                            matTooltip="{{ 'pages.addressCheck.editRecipient.editDocType' | translate }}"  
                            (click)="this.toggleEditMode('docType', 'isDocTypeEditMode')"
                        >
                                mode_edit
                        </mat-icon>
                        <mat-icon 
                            *ngIf="isDocTypeEditMode" 
                            matSuffix 
                            (click)="this.toggleEditMode('docType', 'isDocTypeEditMode')"
                        >
                            check
                        </mat-icon>
                    </mat-form-field>
                    
                  </mat-tab>
              
                  <mat-tab label="{{'pages.addressCheck.editRecipient.postalNprint' | translate}}">

                    <div class="delivery-type" *ngIf="selectedRecipient?.isSwissAddress">
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{"pages.addressCheck.editRecipient.deliveryType" | translate}}</mat-label>
                            <mat-select [disabled]="!isDeliveryTypeEditMode" formControlName="postalDeliveryTypeDomestic">
                                <mat-option value="b-post">B-Post</mat-option>
                                <!-- <mat-option value="b-post mass">B-Post Mass</mat-option> -->
                                <mat-option value="a-post">A-Post</mat-option>
                                <!-- <mat-option value="a-post plus">A-Post Plus</mat-option> -->
                                <!-- <mat-option value="registered">Registered</mat-option> -->
                            </mat-select>
                            <mat-icon 
                                *ngIf="!isDeliveryTypeEditMode" 
                                matSuffix 
                                matTooltip="{{ 'pages.addressCheck.editRecipient.editDelType' | translate }}"  
                                (click)="this.toggleEditMode('postalDeliveryTypeDomestic', 'isDeliveryTypeEditMode')"
                            >
                                mode_edit
                            </mat-icon>
                            <mat-icon 
                                *ngIf="isDeliveryTypeEditMode" 
                                matSuffix 
                                (click)="this.toggleEditMode('postalDeliveryTypeDomestic', 'isDeliveryTypeEditMode')"
                            >
                                check
                            </mat-icon>
                        </mat-form-field>
                        <mat-divider></mat-divider>
                    </div>

                    <div class="print-metadata-group" formGroupName="printMetadata">
                        <div class="print-metadata-group-header">
                            <div class="label">{{"pages.addressCheck.editRecipient.printMetadata" | translate}}</div>
                        </div>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{"pages.addressCheck.editRecipient.color" | translate}}</mat-label>
                            <mat-select [disabled]="!isColorEditMode" formControlName="color">
                                <mat-option value="bw">{{"pages.addressCheck.editRecipient.blackNwhite" | translate}}</mat-option>
                                <mat-option value="color">{{"pages.addressCheck.editRecipient.colored" | translate}}</mat-option>
                            </mat-select>
                            <mat-icon 
                                *ngIf="!isColorEditMode" 
                                matTooltip="{{ 'pages.addressCheck.editRecipient.editColor' | translate }}" 
                                matSuffix 
                                (click)="this.toggleEditMode('printMetadata.color', 'isColorEditMode');"
                            >
                                mode_edit
                            </mat-icon>
                            <mat-icon 
                                *ngIf="isColorEditMode" 
                                matSuffix 
                                (click)="this.toggleEditMode('printMetadata.color', 'isColorEditMode');"
                            >
                                check
                            </mat-icon>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="full-width">
                            <mat-label>{{"pages.addressCheck.editRecipient.printType" | translate}}</mat-label>
                            <mat-select [disabled]="!isPrintTypeEditMode" formControlName="printType">
                                <mat-option value="duplex">{{"pages.addressCheck.editRecipient.duplex" | translate}}</mat-option>
                                <mat-option value="simplex">{{"pages.addressCheck.editRecipient.simplex" | translate}}</mat-option>
                            </mat-select>
                            <mat-icon 
                                *ngIf="!isPrintTypeEditMode" 
                                matSuffix 
                                matTooltip="{{'pages.addressCheck.editRecipient.editPrintType' | translate}}"  
                                (click)="this.toggleEditMode('printMetadata.printType', 'isPrintTypeEditMode');"
                            >
                                mode_edit
                            </mat-icon>
                            <mat-icon 
                                *ngIf="isPrintTypeEditMode" 
                                matSuffix 
                                (click)="this.toggleEditMode('printMetadata.printType', 'isPrintTypeEditMode');"
                            >
                                check
                            </mat-icon>
                        </mat-form-field>
                    </div>
                    
                  </mat-tab>
                </mat-tab-group>
            </div>
              

        </form>
 
        <div style="flex-grow: 1"></div>
        <div class="recipient-button-bar" style="align-self: auto; margin-left: 24px; margin-right: 48px;">
            <button mat-raised-button type="button" (click)="onRemoveRecipient()">
                <div style="display: flex; flex-direction: row; align-items: center;">
                    <img src="assets/logos/trash-icon.svg" style="height: 12px; width: 12px">
                    <span style="margin-left: 12px;">{{"pages.addressCheck.editRecipient.remove" | translate}}</span>
                </div>
            </button>
            <div style="flex-grow: 1"></div>
            <button mat-raised-button type="button" (click)="onCancel()">{{"buttons.cancel" | translate}}</button>
            <button mat-raised-button type="default" class="ok-btn" 
                [disabled]="!isRecipientValid || (selectedTabIndex !== 0)"
                (click)="onValidate()" style="background-color: #b0f389; border: 2px solid #ffffff;">
                <mat-icon>refresh</mat-icon>
                {{"pages.addressCheck.editRecipient.validate" | translate}}
            </button>
            <button 
                appCustomTooltip="{{ 'pages.addressCheck.editRecipient.ignoreEmail' | translate }}" 
                mat-raised-button 
                type="default" 
                class="ok-btn" 
                (click)="onClickSave()" 
                [disabled]="isDisabledSaveBtn" 
                style="background-color: #FFCC00; border: 2px solid #ffffff;"
            >
            {{"pages.addressCheck.editRecipient.save" | translate}}
            </button>
        </div>
    </div>

</div>