<div class="pdf-analysis-preset-select">
    <mat-form-field appearance="outline" [ngClass]="{'custom-field-width': useCustomField}" [class.label-hidden]="control.value">
        <mat-label *ngIf="!useCustomField">{{label || ('pages.order-basics.select-preset' | translate)}}</mat-label>
        <mat-label *ngIf="!selectDefault && !control.value">{{ placeholder }}</mat-label>
        <mat-select 
            [formControl]="control" 
            (selectionChange)="onChange($event.value)" 
            [compareWith]="comparePresets"
        >
            <mat-select-trigger>
                {{getDisplayText(control.value)}} 
                <span *ngIf="hasDifferences" class="changed-label">{{'(Changed)'}}</span>
            </mat-select-trigger>
            <mat-option *ngFor="let preset of presets" [value]="preset">
                <div class="preset-option-container">
                    <span class="accessibility-chip" [class]="preset?.accessibility">
                        {{preset?.accessibility}}
                    </span>
                    {{getDisplayText(preset)}}
                </div>
            </mat-option>
        </mat-select>
    </mat-form-field>
</div>