<ng-container *ngIf="preset">
    <mat-expansion-panel class="preset-item">
        <mat-expansion-panel-header class="custom-expansion-header">
            <mat-panel-title>
                <div class="panel-title-content">
                    <span class="sub-info">
                        {{'presets.createdAt' | translate }} {{preset.createdAt.toDate() | date: "medium"}} {{' ' + ('overview.by' | translate) + ' ' }}  {{ preset.createdBy }}
                    </span>
                    <span class="main-info">{{preset.nameEn}}</span>
                </div>
            </mat-panel-title>
            <mat-panel-description class="description">
                <div class="divider"></div>
                <div class="desc-info-wrapper">
                    <span class="sub-info">{{'presets.requiresBlankFirstPage' | translate}}</span>
                    <span class="main-info">{{ (!preset.requiresBlankFirstPage) ? ('presets.false' | translate) : ('presets.true' | translate)}}</span>
                </div>
                <div class="divider"></div>
                <div [ngStyle]="getDynamicStyle()">{{ (preset.status !== 'published') ? ('presetStatus.draft' | translate) : ('presetStatus.published' | translate) }}</div>
                <div class="divider"></div>
                <!-- <button 
                    mat-icon-button  
                    [matMenuTriggerFor]="menu" 
                    (click)="$event.stopPropagation();"
                >
                    <mat-icon>more_vert</mat-icon>
                </button> -->

                <button 
                    mat-icon-button (click)="onDeleteClick(preset.id)"
                    *ngIf="'generating' !== preset.status"
                    [matTooltip]="'presets.delete' | translate"
                >
                    <div class="button-content">
                        <mat-icon class="custom-icon-size">delete</mat-icon>
                    </div>
                </button>
                <button 
                    mat-icon-button (click)="onEditClick(preset.id)"
                    *ngIf="'generating' !== preset.status"
                    [matTooltip]="'presets.edit' | translate"
                >
                    <div class="button-content">
                        <mat-icon class="custom-icon-size">edit</mat-icon>
                    </div>
                </button>

            </mat-panel-description>
        </mat-expansion-panel-header>
        <!-- The Table -->
        <table mat-table [dataSource]="tableData" class="mat-elevation-z8">
            <!-- Type Column -->
            <ng-container matColumnDef="type">
                <th mat-header-cell *matHeaderCellDef> Type </th>
                <td mat-cell *matCellDef="let element"> {{element.type}} </td>
            </ng-container>

            <!-- Other columns (width, height, topLeftX, topLeftY) -->
            <ng-container *ngFor="let column of displayedColumns.slice(1)" [matColumnDef]="column">
                <th class="centered" mat-header-cell *matHeaderCellDef> {{column}} </th>
                <td class="centered" mat-cell *matCellDef="let element"> {{element[column]}} </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        
    </mat-expansion-panel>
</ng-container>