<div class="preset-studio" [style.top]="!isReuse ? '118px' : '160px'">
    <div style="display: flex; flex-direction: row; align-items: center;">
        <div style="margin-right: 24px">
            <img src="assets/img/upload_img.svg" width="79.13px" height="68.35px">
        </div>
        <div style="display: flex; flex-direction: column;">
            <div class="title" style="font-size: 18px;">{{"pages.orderBasics.settings" | translate}}</div>
            <div class="subtitle" style="margin-top:20px; margin-bottom:20px;">
                {{
                    !isReuse ? ("pages.orderBasics.giveConfigDescriptiveName" | translate) : 
                    ("pages.orderBasics.giveOrderDescriptiveName" | translate)
                }}
            </div>
        </div>
    </div>

    <div style="flex-grow: 1; margin-left: 104px; margin-top: 1.5rem; margin-right: 2rem; position: relative;">
        <form action="preset-form" [formGroup]="presetForm">
            <div class="header-form-wrapper">
                <div style="display: flex;">
                    <div class="custom-field-wrapper" *ngIf="isReuse">
                        <label class="custom-field-label" for="order-name">{{"pages.orderBasics.orderName" | translate}}*</label>
                        <mat-form-field appearance="outline" class="custom-mat-field">
                            <input matInput id="order-name" type="text" [placeholder]="'filter.orderName' | translate" [(ngModel)]="orderName" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                    <div class="custom-field-wrapper" *ngIf="isReuse">
                        <label class="custom-field-label" for="order-subject">{{"filter.orderSubject" | translate}}*</label>
                        <mat-form-field appearance="outline" class="custom-mat-field">
                            <input matInput id="order-subject" type="text" [placeholder]="'filter.orderSubject' | translate"  [(ngModel)]="orderSubject" [ngModelOptions]="{standalone: true}">
                        </mat-form-field>
                    </div>
                    <div class="custom-field-wrapper" *ngIf="isReuse">
                        <label class="custom-field-label" for="preset-name">{{"pages.orderBasics.configSet" | translate}}*</label>
                        <app-pdf-analysis-preset-select 
                            [control]="basePresetControl" 
                            [useCustomField]="true"
                            [hasDifferences]="presetHasDifferences"
                        >
                        </app-pdf-analysis-preset-select>
                    </div>
                    <div class="custom-field-wrapper" *ngIf="!isReuse">
                        <label class="custom-field-label" for="preset-name">{{"pages.orderBasics.configSetName" | translate}}</label>
                        <mat-form-field appearance="outline" class="custom-mat-field">
                            <input matInput id="preset-name" type="text" [placeholder]="'presets.configSetName' | translate" formControlName="name">
                        </mat-form-field>
                    </div>
                    <div class="custom-field-wrapper" *ngIf="!isReuse">
                        <label class="custom-field-label" for="preset-name">{{"presets.create.selectConfigSetLabel" | translate}}*</label>
                        <app-pdf-analysis-preset-select 
                            [control]="basePresetControl" 
                            [placeholder]="'presets.create.selectConfigSetPlaceholder' | translate" 
                            [useCustomField]="true"
                            [selectDefault]="false"
                        >
                        </app-pdf-analysis-preset-select>
                    </div>
                    <div class="custom-field-wrapper" *ngIf="!isReuse">
                        <label class="custom-field-label">{{"pages.orderBasics.defaultConfigSet" | translate}}</label>
                        <div class="checkbox-wrapper">
                            <input class="custom-checkbox" id="default-config" type="checkbox" formControlName="isDefault">
                            <label for="default-config">{{"pages.orderBasics.configSetForMe" | translate}}</label>
                        </div>
                    </div>
                </div>
                <div *ngIf="rectangleArray.length" class="location-values">
                    <ng-container *ngFor="let rectangle of rectangleArray; let idx = index; trackBy: trackByFunction">
                        <div class="box-location" [ngClass]="['left-border', 'target' + idx]" [class.disabled]="rectangle.disabled">
                            <div class="title-wrapper">
                                <mat-checkbox 
                                    [ngModelOptions]="{standalone: true}"
                                    [disabled]="rectangle.required || rectangle.disabled" 
                                    [(ngModel)]="rectangle.selected" 
                                    (change)="toggleSelected(idx, $event)"
                                >
                                </mat-checkbox>
                                <div class="rectangle-label-wrapper">
                                    <h3 
                                        [matTooltip]="'pages.orderBasics.rectangleArray.' + rectangle.label | translate"
                                    >
                                        {{ 'pages.orderBasics.rectangleArray.' + rectangle.label | translate }}
                                    </h3>
                                    <mat-icon 
                                        class="replay-icon" 
                                        [matTooltip]="rectangle.disabled ? '' : ('presets.resetInitial' | translate)"  
                                        (click)="!rectangle.disabled && resetRectangle(idx)"
                                    >
                                        replay
                                    </mat-icon>
                                </div>
                                <mat-icon 
                                    class="expand-icon" 
                                    [matTooltip]="rectangle.disabled ? '' : 'presets.expandCollapse' | translate"
                                    (click)="!rectangle.disabled && expandRectangle(idx)"
                                >
                                    {{ rectangle.expanded ? 'expand_less' : 'expand_more' }}
                                </mat-icon>
                            </div>
                            <div class="box-location-values" [class.expanded]="rectangle.expanded">
                                <div class="box-location-content">
                                    <div class="units">
                                        <div class="unit-item">
                                            <label [for]="'width' + idx">Width</label>
                                            <input 
                                                type="number" 
                                                [name]="'width' + idx" 
                                                [value]="rectangle.width" 
                                                (input)="onInputFieldChanges($event, 'width', idx)"
                                                (wheel)="preventScrollPropagation($event)"
                                                min="10"
                                                max="80"
                                            >
                                        </div>
                                        <div class="unit-item">
                                            <label [for]="'height' + idx">Height</label>
                                            <input 
                                                type="number" 
                                                [name]="'height' + idx" 
                                                [value]="rectangle.height" 
                                                (input)="onInputFieldChanges($event, 'height', idx)"
                                                (wheel)="preventScrollPropagation($event)"
                                                min="10"
                                                max="30"
                                            >
                                        </div>
                                        <div class="unit-item">
                                            <label [for]="'topLeftX' + idx">Top Left X</label>
                                            <input 
                                                type="number" 
                                                [name]="'topLeftX' + idx" 
                                                [value]="rectangle.topLeftX" 
                                                (input)="onInputFieldChanges($event, 'topLeftX', idx)"
                                                (wheel)="preventScrollPropagation($event)"
                                                min="0"
                                            >
                                        </div>
                                        <div class="unit-item">
                                            <label [for]="'topLeftY' + idx">Top Left Y</label>
                                            <input 
                                                type="number" 
                                                [name]="'topLeftY' + idx" 
                                                [value]="rectangle.topLeftY" 
                                                (input)="onInputFieldChanges($event, 'topLeftY', idx)"
                                                (wheel)="preventScrollPropagation($event)"
                                                min="0"
                                            >
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
            <div class="main-form-wrapper">
                <div class="preset-editor-placeholder" *ngIf="!rectangles.size"></div>
                <div class="preset-editor-wrapper" [ngClass]="{'expanded-rectangle': isAnyRectangleExpanded}" *ngIf="rectangles.size > 0">
                    <preset-editor 
                        [pageDimensionInUnit]="pageDimension" 
                        [rectangleInUnits]="rectangles" 
                        [pdfScreenshotUrl]="pdfScreenshotUrl"
                        [addressBoxLocation]="addressBoxLocation"
                        [showPerforatedLine]="perforatedLine"
                        (onRectangleResize)="handleRectangleResize($event)"
                    ></preset-editor>
                </div>
                <div class="main-form" [ngClass]="{'expanded-rectangle': isAnyRectangleExpanded}">
                    <mat-accordion [togglePosition]="'before'" multi>
                        <mat-expansion-panel class="form-expansion-panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{"pages.orderBasics.documentParams" | translate}}</mat-panel-title>
                                <mat-panel-description>
                                    {{!isReuse ? getValuesFromControls(['docType', 'processType']) :getValuesFromControls(['docType'])}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="flex-container m-b-1">
                                <span>{{"presets.create.docType" | translate}}*</span>
                                <mat-form-field appearance="outline" class="custom-mat-field">
                                    <mat-select formControlName="docType">
                                        <mat-option value="invoice">{{"presets.create.invoice" | translate}}</mat-option>
                                        <mat-option value="reminder">{{"presets.create.reminder" | translate}}</mat-option>
                                        <mat-option value="message">{{"presets.create.message" | translate}}</mat-option>
                                        <mat-option value="contract">{{"presets.create.contract" | translate}}</mat-option>
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="flex-container" *ngIf="!isReuse">
                                <span>{{"pages.orderBasics.numOfRecipients" | translate}}</span>
                                <mat-button-toggle-group class="custom-toggle-group" formControlName="processType">
                                  <mat-button-toggle value="single">{{"presets.create.single" | translate}}</mat-button-toggle>
                                  <mat-button-toggle value="multiple">{{"presets.create.multiple" | translate}}</mat-button-toggle>
                                </mat-button-toggle-group>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel class="form-expansion-panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{"pages.orderBasics.printSendParams" | translate}}</mat-panel-title>
                                <mat-panel-description>
                                    {{getValuesFromControls(['printMetadata.color', 'printMetadata.printType', 'postalDeliveryTypeDomestic', 'postalDeliveryTypeInternational'])}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div formGroupName="printMetadata">
                                <div class="flex-container m-b-1">
                                    <span>{{"presets.create.color" | translate}}*</span>
                                    <mat-button-toggle-group class="custom-toggle-group" formControlName="color">
                                        <mat-button-toggle value="color">{{"presets.create.colored" | translate}}</mat-button-toggle>
                                        <mat-button-toggle value="bw">{{"presets.create.blackNwhite" | translate}}</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                                <div class="flex-container m-b-1">
                                    <span>{{"presets.create.printType" | translate}}*</span>
                                    <mat-button-toggle-group class="custom-toggle-group" formControlName="printType">
                                      <mat-button-toggle value="duplex">{{"presets.create.duplex" | translate}}</mat-button-toggle>
                                      <mat-button-toggle value="simplex">{{"presets.create.simplex" | translate}}</mat-button-toggle>
                                    </mat-button-toggle-group>
                                </div>
                            </div>
                            <div class="flex-container m-b-1">
                                <span>{{"presets.create.deliveryTypeDomestic" | translate}}*</span>
                                <mat-form-field appearance="outline" class="custom-mat-field">
                                    <mat-select formControlName="postalDeliveryTypeDomestic">
                                        <mat-option value="b-post">B-Post</mat-option>
                                        <mat-option value="a-post">A-Post</mat-option>
                                        <!-- <mat-option value="b-post mass">B-Post Mass</mat-option> -->
                                        <!-- <mat-option value="a-post plus">A-Post Plus</mat-option> -->
                                        <!-- <mat-option value="registered">Registered</mat-option> -->
                                    </mat-select>
                                </mat-form-field>
                            </div>
                            <div class="flex-container">
                                <span>{{"presets.create.deliveryTypeInternational" | translate}}*</span>
                                <mat-form-field appearance="outline" class="custom-mat-field">
                                    <mat-select formControlName="postalDeliveryTypeInternational">
                                        <mat-option value="standard">Standard</mat-option>
                                        <!-- <mat-option value="express">Express</mat-option> -->
                                    </mat-select>
                                </mat-form-field>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel class="form-expansion-panel">
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{"pages.orderBasics.orderParams" | translate}}</mat-panel-title>
                                <mat-panel-description>
                                    {{getValuesFromControls(['orderName']) + ',' + getValuesFromControls(['subject'])}}
                                </mat-panel-description>
                            </mat-expansion-panel-header>
                            <div class="flex-container m-b-1">
                                <span>{{"presets.create.defaultOrderName" | translate}}*</span>
                                <mat-form-field appearance="outline" class="custom-mat-field">
                                    <input matInput type="text" [placeholder]="'presets.defaultSetName' | translate" formControlName="orderName">
                                </mat-form-field>
                                <div class="date-time-variable-wrapper">
                                    <div class="date-time-variable-item">
                                        <label for="order-name-date-variable">{{"presets.create.date" | translate}}</label>
                                        <mat-slide-toggle 
                                            id="order-name-date-variable" 
                                            color="primary"
                                            formControlName="orderNameDateVariable"
                                            [matTooltip]="!presetForm.get('orderNameDateVariable')?.value ? ('presets.create.setOrderDateToTrue' | translate) : ('presets.create.setOrderDateToFalse' | translate)"
                                        ></mat-slide-toggle>
                                    </div>
                                    <div class="date-time-variable-item">
                                        <label for="order-name-time-variable">{{"presets.create.time" | translate}}</label>
                                        <mat-slide-toggle 
                                            id="order-name-time-variable" 
                                            color="primary"
                                            formControlName="orderNameTimeVariable"
                                            [matTooltip]="!presetForm.get('orderNameTimeVariable')?.value ? ('presets.create.setOrderTimeToTrue' | translate) : ('presets.create.setOrderTimeToFalse' | translate)"
                                        ></mat-slide-toggle>
                                    </div>
                                </div>
                            </div>
                            <div class="flex-container">
                                <span>{{"presets.create.defaultOrderSubject" | translate}}*</span>
                                <mat-form-field appearance="outline" class="custom-mat-field">
                                    <input matInput type="text" [placeholder]="'presets.defaultOrderSubject' | translate" formControlName="subject">
                                </mat-form-field>
                                <div class="date-time-variable-wrapper">
                                    <div class="date-time-variable-item">
                                        <label for="order-subject-date-variable">{{"presets.create.date" | translate}}</label>
                                        <mat-slide-toggle 
                                            id="order-subject-date-variable" 
                                            color="primary"
                                            formControlName="orderSubjectDateVariable"
                                            [matTooltip]="!presetForm.get('orderSubjectDateVariable')?.value ? ('presets.create.setSubjectDateToTrue' | translate) : ('presets.create.setSubjectDateToFalse' | translate)"
                                        ></mat-slide-toggle>
                                    </div>
                                    <div class="date-time-variable-item">
                                        <label for="order-subject-time-variable">{{"presets.create.time" | translate}}</label>
                                        <mat-slide-toggle 
                                            id="order-subject-time-variable" 
                                            color="primary"
                                            formControlName="orderSubjectTimeVariable"
                                            [matTooltip]="!presetForm.get('orderSubjectTimeVariable')?.value ? ('presets.create.setSubjectTimeToTrue' | translate) : ('presets.create.setSubjectTimeToFalse' | translate)"
                                        ></mat-slide-toggle>
                                    </div>
                                </div>
                            </div>
                        </mat-expansion-panel>
                        <mat-expansion-panel class="form-expansion-panel" *ngIf="false"> <!-- HIDE E-BILL PARAMETERS --> 
                            <mat-expansion-panel-header>
                                <mat-panel-title>{{"pages.orderBasics.ebillParams" | translate}}</mat-panel-title>
                            </mat-expansion-panel-header>
                            <div class="flex-container">
                                <span>{{"pages.orderBasics.dueDate" | translate}}*</span>
                                <div class="custom-mat-radio-btn">
                                    <mat-radio-group [(ngModel)]="selectedValue" (ngModelChange)="updateFormEbillDueDate()" [ngModelOptions]="{standalone: true}">
                                      <mat-radio-button value="dynamic">{{"pages.orderBasics.dynamicDate" | translate}}</mat-radio-button>
                                      <div class="date-wrapper m-b-1">
                                        {{"pages.orderBasics.todayPlus" | translate}} 
                                        <mat-form-field appearance="outline" class="custom-mat-field custom-date-input">
                                          <input 
                                            matInput 
                                            [disabled]="selectedValue === 'fixed'" 
                                            [(ngModel)]="numberOfDays" type="number"
                                            (ngModelChange)="updateFormEbillDueDate()"
                                            [ngModelOptions]="{standalone: true}"
                                        >
                                        </mat-form-field>
                                        {{"pages.orderBasics.days" | translate}}
                                      </div>
                                      <mat-radio-button value="fixed">{{"pages.orderBasics.fixedDate" | translate}}</mat-radio-button>
                                      <div class="m-b-1">
                                        <mat-form-field appearance="outline" class="custom-mat-field">
                                            <input matInput
                                                   [disabled]="selectedValue === 'dynamic'"
                                                   [matDatepicker]="picker"
                                                   [placeholder]="'presets.create.selectEbillDue' | translate"
                                                   [(ngModel)]="dueDate"
                                                   (ngModelChange)="updateFormEbillDueDate()"
                                                   [ngModelOptions]="{standalone: true}">
                                            <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                                            <mat-datepicker #picker></mat-datepicker>
                                        </mat-form-field>
                                      </div>
                                    </mat-radio-group>
                                </div>
                            </div>
                        </mat-expansion-panel>
                    </mat-accordion>

                    <div class="toggle-container">
                        <mat-slide-toggle 
                            id="is-address-validation-required" 
                            color="primary"
                            formControlName="isAddressValidationRequired"
                            [matTooltip]="!presetForm.get('isAddressValidationRequired')?.value ? ('presets.setToTrue' | translate) : ('presets.setToFalse' | translate)"
                        ></mat-slide-toggle>
                        <label for="is-address-validation-required">{{"presets.create.performValidation" | translate}}</label>
                    </div>

                    <mat-divider class="custom-divider m-b-1"></mat-divider>
                    
                    <div class="blank-first-page-label">
                        <b>{{ presetForm.get('requiresBlankFirstPage')?.value ? ("presets.yes" | translate) : ("presets.no" | translate) }}</b>
                        <label>{{"pages.orderBasics.blankFirstPage" | translate}}</label>
                    </div>

                    <mat-divider class="custom-divider m-t-1 m-b-1"></mat-divider>

                    <div class="toggle-container">
                        <mat-slide-toggle 
                            id="show-cutoff-line" 
                            color="primary"
                            [matTooltip]="!perforatedLine ? ('presets.perforatedLineTrue' | translate) : ('presets.perforatedLineFalse' | translate)"
                            [(ngModel)]="perforatedLine"
                            [ngModelOptions]="{standalone: true}"
                        ></mat-slide-toggle>
                        <label for="show-cutoff-line">{{"presets.perforatedLine" | translate}}</label>
                    </div>

                </div>
                
            </div>
        </form>
    </div>
    <div class="action-buttons">
        <button mat-raised-button class="footer-btn" (click)="onCancel()">{{'presets.buttonLabels.cancel' | translate}}</button>
        <button 
            mat-raised-button color="primary"  
            class="footer-btn next-btn" 
            [disabled]="presetForm.invalid"
            (click)="onPublish()"
        >
            {{!isReuse ? ('presets.buttonLabels.publish' | translate) : ('buttons.saveAndNext' | translate)}}
        </button>
    </div>
</div>