<div class="preset-pdf-upload">
    <div style="display: flex; flex-direction: row; align-items: center;">
        <div style="margin-right: 24px">
            <img src="assets/img/upload_img.svg" width="79.13px" height="68.35px">
        </div>
        <div style="display: flex; flex-direction: column;">
            <div class="title" style="font-size: 18px;">{{"statement.letsStart" | translate}}</div>
            <div class="subtitle" style="margin-top:20px; margin-bottom:20px;">
                {{"presets.uploadSample" | translate}}
            </div>
        </div>
    </div>

    <div style="flex-grow: 1; margin-left: 104px; margin-top: 1.5rem; margin-right: 2rem; position: relative;">
        <div 
            class="upload-section"
            [ngClass]="{'drag-over': isDragOver || uploadedFileNamesArray.length}"
            (drop)="onFileDrop($event)"
            (dragover)="onDragOver($event)"
            (dragleave)="onDragLeave($event)"
        >
            <label class="upload-select-label" for="fileUpload">
                <ng-template [ngIf]="checkUploadsStatus === 'completed'" [ngIfElse]="pdfsStatus">
                    <img
                        class="upload-icon"
                        width="24" 
                        height="24" 
                        src="assets/icons/upload-bottom.png" 
                        alt="upload icon" 
                        matTooltip="{{'pages.docUpload.uploadTooltip' | translate}}"
                    >
                    <span class="small-text">
                        {{"presets.uploadHere" | translate}}
                    </span>
                </ng-template>
                <ng-template #pdfsStatus>
                    <div class="small-text">{{'presets.create.uploading' | translate}}</div>
                    <div style="width: 40%">
                        <mat-progress-bar mode="indeterminate"></mat-progress-bar>
                    </div>
                </ng-template>
            </label>
            <input 
                #fileUpload 
                id="fileUpload"
                type="file" 
                single
                accept=".pdf"
                style="display: none;"
                (change)="selectUploadFile(fileUpload)"
                [disabled]="checkUploadsStatus === 'uploading' || uploadedFileNamesArray.length"
            >
        </div>
        <div class="uploaded-files-wrapper uploaded-pdf-wrapper">
            <div 
                *ngFor="let uploadedFileName  of uploadedFileNamesArray" 
                style="
                    display: flex;
                    flex-direction: column;
                    width: 116px;
                    margin-right: 32px;
                "
            >
                <div style="
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 80px;
                    margin-left: 16px;
                    margin-right: 16px;
                    position: relative;
                ">
                    <!-- Improvement: Non encoded document preview -->
                    <img src="assets/img/pdf-attchment-placeholder.png" width="100%">
                    <button 
                        mat-mini-fab class="remove-btn"
                        matTooltip="Delete" 
                        *ngIf="!uploadProgressMap.get(uploadedFileName.filename)?.isUploading" 
                        (click)="removeFile(uploadedFileName.filename, uploadedFileName.id)"
                    >
                        <mat-icon>delete</mat-icon>
                    </button>
                </div>
                <mat-progress-bar
                    *ngIf="uploadProgressMap.get(uploadedFileName.filename)?.isUploading"
                    style="width: 80px; margin: 0.5rem auto; "
                    mode="determinate"
                    [value]="uploadProgressMap.get(uploadedFileName.filename)?.uploadProgress || 0"
                >
                </mat-progress-bar>
                <span 
                    style="
                        white-space: nowrap;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        font-size: 12px;
                        margin: 0.5rem 0;
                    "
                    [matTooltip]="uploadedFileName.name"
                    matTooltipPosition="above"
                >
                    <strong>{{ uploadedFileName.name }}</strong>
                </span>
            </div>
        </div>
    </div>
</div>