import { Injectable } from '@angular/core';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

import {
  AppState,
  RecipientValidationRequest,
  KeycloakTokens,
  ProfileType,
  TenantToken,
} from '../state/app-state';
import { AppStateService } from './app-state.service';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  private appStateSubscription?: Subscription;
  private currentState!: AppState;

  constructor(
    private toastr: ToastrService,
    private afAuth: AngularFireAuth,
    private translate: TranslateService,
    private appStateService: AppStateService
  ) {
    this.appStateSubscription = this.appStateService.appState$.subscribe(
      (state) => {
        this.currentState = state;
      }
    );
  }

  async deleteOrderInfo(profileId: string, orderId: string): Promise<any> {
    try {
      const orderList = this.currentState.orders.list;
      const functions = getFunctions(undefined, 'europe-west6');
      const deleteOrderInfo = httpsCallable(functions, 'deleteOrderInfo');

      await deleteOrderInfo({ profileId, orderId });
      orderList.delete(orderId);

      this.appStateService.updateState({
        orders: {
          ...this.currentState.orders,
          list: orderList,
        },
      });
    } catch (error: any) {
      let errorMessage = error?.error?.error_message;
      if (!errorMessage) {
        this.translate
          .get('errMsg.deleteOrderInfo')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
    }
  }

  async computeDeliveryItems(orderId: string): Promise<any> {
    try {
      const functions = getFunctions(undefined, 'europe-west6');
      const computeDeliveryItems = httpsCallable(
        functions,
        'computeDeliveryItems'
      );

      const result = await computeDeliveryItems({ orderId: orderId });
      const deliveryTypeLineItems = (result.data as any)
        .compDeliveryItems as any[];

      return deliveryTypeLineItems;
    } catch (error: any) {
      let errorMessage = error?.error?.error_message;
      if (!errorMessage) {
        this.translate
          .get('errMsg.errorComputeDeliveryItems')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
    }
  }

  async validateRecipient(recipient: RecipientValidationRequest): Promise<any> {
    try {
      const functions = getFunctions(undefined, 'europe-west6');
      const validateRecipientDetails = httpsCallable(
        functions,
        'validateRecipientDetails'
      );

      const result = await validateRecipientDetails({ ...recipient });
      return result.data;
    } catch (error: any) {
      let errorMessage = error?.error?.error_message;
      if (!errorMessage) {
        this.translate
          .get('errMsg.errorValidateRecipientDetails')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
    }
  }

  async setUserClaimsAndProfiles(data: {
    uid: string;
    currentUserProfile: { name: string; email: string };
    idToken: string;
    profiles: ProfileType[];
  }): Promise<void> {
    try {
      const functions = getFunctions(undefined, 'europe-west6');
      const setUserClaimsAndProfiles = httpsCallable(
        functions,
        'setUserClaimsAndProfiles'
      );

      await setUserClaimsAndProfiles(data);
    } catch (error: any) {
      let errorMessage = error?.error?.error_message;
      if (!errorMessage) {
        this.translate
          .get('errMsg.errorSetUserClaimsProfiles')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
    }
  }

  async getAndCreateTokens(data: {
    tokenUrl: string;
    body: {
      code: string;
      grant_type: string;
      client_id: string;
      client_secret: string;
      redirect_uri: string;
    };
  }): Promise<any> {
    try {
      const functions = getFunctions(undefined, 'europe-west6');
      const getAndCreateTokens = httpsCallable(functions, 'getAndCreateTokens');
      const response = (await getAndCreateTokens(data)) as {
        data: { firebaseToken: string; keycloakTokens: KeycloakTokens };
      };

      return response.data;
    } catch (error: any) {
      let errorMessage = error?.error?.error_message;
      if (!errorMessage) {
        this.translate
          .get('errMsg.errorGetAndCreateToken')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
    }
  }

  async signInWithCustomToken(customToken: string): Promise<string> {
    try {
      const response: any = await this.afAuth.signInWithCustomToken(
        customToken
      );
      const idToken = (await response.user.getIdToken()) as string;
      console.log('Successfully authenticated with Firebase.');
      return idToken;
    } catch (error) {
      console.error('Error authenticating with Firebase:', error);
      return Promise.reject(error);
    }
  }

  async refreshToken(data: {
    refreshUrl: string;
    body: {
      grant_type: string;
      client_id: string;
      client_secret: string;
      refresh_token: string;
    };
  }): Promise<any> {
    try {
      const functions = getFunctions(undefined, 'europe-west6');
      const refreshEpostToken = httpsCallable(functions, 'refreshEpostToken');
      const response = (await refreshEpostToken(data)) as {
        data: KeycloakTokens;
      };

      return response.data;
    } catch (error: any) {
      const errorMessage = error?.error?.error_message;
      console.log('Error: on refreshToken', errorMessage);
    }
  }

  async processTenantTokens(data: {
    refreshUrl: string;
    body: {
      grant_type: string;
      client_id: string;
      client_secret: string;
      refresh_token: string;
    };
    userToken: string;
  }): Promise<TenantToken[]> {
    try {
      const functions = getFunctions(undefined, 'europe-west6');
      const processTenantTokensFunction = httpsCallable(
        functions,
        'processTenantTokens'
      );
      const response = (await processTenantTokensFunction(data)) as {
        data: TenantToken[];
      };

      return response.data;
    } catch (error: any) {
      console.error('Error: on processTenantTokens', error);
      // Propagate the error to the caller
      throw error;
    }
  }

  async copyImageToNewDirectory(
    orderId: string,
    presetId: string
  ): Promise<void> {
    const profileId = this.currentState.profileId!;
    const sourcePath = `profiles/${profileId}/${orderId}/thumbnail.jpg`;
    const destinationPath = `presets/${profileId}/${presetId}/thumbnail.jpg`;

    try {
      const functions = getFunctions(undefined, 'europe-west6');
      const copyFileInStorageFunction = httpsCallable(
        functions,
        'copyFileInStorage'
      );
      const response: any = await copyFileInStorageFunction({
        sourcePath,
        destinationPath,
      });

      return response.data;
    } catch (error: any) {
      console.error('Error: on copyFileInStorageFunction', error);
      // Propagate the error to the caller
      throw error;
    }
  }

  ngOnDestroy(): void {
    this.appStateSubscription?.unsubscribe();
  }
}
