import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";


@Component({
    selector: 'app-warning-dialog',
    template: `
      <h2 mat-dialog-title>{{"warningDialog" | translate}}</h2>
      <mat-dialog-content>{{ data.message }}</mat-dialog-content>
      <mat-dialog-actions align="end">
        <button mat-button matDialogClose>Ok</button>
      </mat-dialog-actions>
    `
  })
export class WarningDialogComponent {
constructor(
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
) { }
}
  