<div class="background">
	<div class="login-container">
		<mat-toolbar color="primary" class="logo">
			<!-- <img src="assets/logos/post-logo-de.svg" height="47.67px"> -->
			<img src="assets/logos/schweizerische-post-logo.jpg" height="47.67px">
		</mat-toolbar>

		<div class="auth-container">
			<!-- Sign-in with OICD -->
			<button 
				mat-raised-button 
				style="min-width: 120px; max-width: 120px;margin-top:15px;"
				(click)="redirectToLogin()"
			>
				Login
			</button>
		</div>
	</div>
</div>