import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { filter, map, distinctUntilChanged } from 'rxjs/operators';

import { AppStateService } from '../../../services/app-state.service';
import { AppState, ProfileType } from '../../../state/app-state';

@Injectable({ providedIn: 'root' })
export class ProfileIdGuard implements CanActivate {
  constructor(private stateService: AppStateService, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    const profileId =
      route.params['profileId'] ||
      (route.parent && route.parent.params['profileId']);

    if (profileId && this.stateService.appState$.value.profileId !== profileId) {
      this.stateService.updateState({ profileId });
    }

    return this.stateService.appState$.pipe(
      distinctUntilChanged(
        (prev, curr) => prev.profiles.list === curr.profiles.list
      ),
      filter((appState) => appState.profiles.isLoaded),
      map((state: AppState) => {
        const profileIds = (state.profiles.list || []).map(
          (profile: ProfileType) => profile.id
        );
        const profileExists = profileIds.includes(profileId);

        if (!profileIds.length) {
          this.router.navigate(['/no-profile-found']);
          return false;
        }

        if (!profileExists) {
          this.router.navigate(['/invalid-profile']);
          return false;
        }

        return profileExists;
      })
    );
  }
}
