import { Pipe, PipeTransform } from '@angular/core';
import { OrderType } from '../../../state/app-state';

type PdfResultProgress = {
  processStatus:
    | 'CHANNEL DETERMINATION'
    | 'PDF SPLITTING'
    | 'MESSAGE PRODUCTION';
  currentCount: number;
  totalCount: number;
  ProgressPercentage: number;
};

@Pipe({
  name: 'pdfResultsProgress',
  pure: true,
})
export class CalculatePdfResultsProgressPipe implements PipeTransform {
  transform(order: OrderType): PdfResultProgress {
    return calculatePdfResultsProgress(order);
  }
}

const calculatePdfResultsProgress = (order: OrderType): PdfResultProgress => {
  const { status, channelDeterminationStatus, orderDeliveryStepStatus } =
    order || {};
  switch (status) {
    case 'ChannelDetermination':
      return {
        processStatus: 'CHANNEL DETERMINATION',
        currentCount: channelDeterminationStatus?.currentMessageNum || 0,
        totalCount: channelDeterminationStatus?.numMessages || 0,
        ProgressPercentage: channelDeterminationStatus
          ? (channelDeterminationStatus?.currentMessageNum /
              channelDeterminationStatus?.numMessages) *
            100
          : 0,
      };
      break;
    case 'PdfSplitting':
      return {
        processStatus: 'PDF SPLITTING',
        currentCount: orderDeliveryStepStatus?.currentMessageNum || 0,
        totalCount: orderDeliveryStepStatus?.numMessages || 0,
        ProgressPercentage: orderDeliveryStepStatus
          ? (orderDeliveryStepStatus?.currentMessageNum /
              orderDeliveryStepStatus?.numMessages) *
            100
          : 0,
      };
      break;
    case 'MessageProduction':
      return {
        processStatus: 'MESSAGE PRODUCTION',
        currentCount: orderDeliveryStepStatus?.currentMessageNum || 0,
        totalCount: orderDeliveryStepStatus?.numMessages || 0,
        ProgressPercentage: orderDeliveryStepStatus
          ? (orderDeliveryStepStatus?.currentMessageNum /
              orderDeliveryStepStatus?.numMessages) *
            100
          : 0,
      };
      break;
    default:
      return {
        processStatus: 'MESSAGE PRODUCTION',
        currentCount: 0,
        totalCount: 0,
        ProgressPercentage: 0,
      };
      break;
  }
};
