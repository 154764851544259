<ng-container *ngIf="this.stateService.appState$ | async as appState">
	<div 
		*ngIf="currentOrderHasValue"
		[style.top]="!['Done', 'InProduction'].includes(currentOrder?.status || '') ? '160px' : '116px'"  
		style="
			position: absolute;
			top: 160px;
			bottom: 49px;
			left: 68px;
			right: 68px;
			display: flex;
			flex-direction: column;
			box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.2);
			border-radius: 8px;" [style]="isReadonly ? 'cursor: default': ''
		"
	>
		<!-- edit order subheader -->
		<app-order-name-subject-sub-header></app-order-name-subject-sub-header>

		<div 
			style="
				display: flex;
				flex-direction: row;
				align-items: center;
				margin-top: 1rem;
				padding: 0 2rem;
			"
		>
			<div style="margin-right: 24px">
				<img src="assets/img/upload_img.svg" width="79.13px" height="68.35px">
			</div>
			<div style="
				display: flex;
				flex-direction: column;">
				<div class="title" style="font-size: 18px">{{"pages.summary.orderSummary" | translate}}</div>
				<div *ngIf="!isReadonly" class="subtitle">{{"pages.summary.takeAmoment" | translate}}</div>
			</div>
		</div>

		<div style="
			flex-grow: 1;
			margin-left: 104px;
			display: grid;
			grid-template-columns: auto;
			grid-template-rows: auto auto;
			gap: 10px;
			grid-auto-rows: min-content;
			overflow: auto;
			padding: 0 2rem;
		">
			<div style="
				grid-column: 1;
				grid-row: 1;
				margin-top: 30px;
				display: flex;
				flex-direction: column;
			">
				<div style="display: flex" class="small-text">
					<span style="width: 20%">
						{{(currentOrder?.messagePdfs || []).length > 1 ? 'pages.summary.documents' : 'pages.summary.document' | translate}}
					</span>
					<span style="width: 80%">{{messagePdfsNames}}</span>
				</div>

				<div style="display: flex" class="small-text">
					<span style="width: 20%">
						{{(currentOrder?.attachmentPdfs || []).length > 1 ? "table.Attachments" : "table.Attachment" | translate}}
					</span>
					<span style="width: 80%; color: grey;">{{attachmentPdfsNames || '---'}}</span>
				</div>
				<div style="display: flex" class="small-text">
					<span style="width: 20%">{{"pages.summary.numOfRecipients" | translate}}</span>
					<span style="width: 80%">{{currentOrder?.numRecipients}}</span>
				</div>
			</div>
			<div style="
				grid-column: 1;
				grid-row: 2;
				margin-top: 32px;
				margin-bottom: 50px;
				display: flex;
				flex-direction: column;
				overflow: auto;
			">
				<app-delivery-type-items-table 
					[deliveryTypeLineItems]="(currentOrder?.deliveryTypeLineItems || [])"
					[totalNumberOfRecipients]="currentOrder?.numRecipients || 0"
					[totalAmountInCents]="currentOrder?.amountCents || 0"
					[printMetadata]="currentOrder?.printMetadata"
				></app-delivery-type-items-table>
			</div>
		</div>

		<div style="padding: 0 2rem;">
			<div class="button-bar">
				<button *ngIf="!isReadonly" mat-raised-button class="footer-btn" (click)="cancel()">{{"buttons.cancel" | translate}}</button>
				<button *ngIf="isReadonly" mat-raised-button class="footer-btn" (click)="goBack()">{{"buttons.back" | translate}}</button>
				<button *ngIf="!isReadonly" mat-raised-button color="primary" class="footer-btn next-btn" (click)="onNext()">{{"buttons.orderForFee" | translate}}</button>
			</div>
		</div>

	</div>
</ng-container>