import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from '@angular/router';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { AppStateService } from '../../../services/app-state.service';
import { isObjectEmpty } from '../utils/helper';

@Injectable({
  providedIn: 'root',
})
export class ApiKeyGuard implements CanActivate {
  constructor(private stateService: AppStateService, private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.stateService.appState$.pipe(
      map((appState) =>
        !isObjectEmpty(appState?.currentProfile)
          ? !!appState?.currentProfile?.klaraApiKey
          : true
      ),
      tap((hasApiKey) => {
        if (hasApiKey === false) {
          this.router.navigate(['/api-key-missing']);
        }
      })
    );
  }
}
