import {
  Component,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
  ChangeDetectionStrategy,
} from '@angular/core';

import { OrderType, ProfileType } from '../../../../state/app-state';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-order-item',
  templateUrl: './order-item.component.html',
  styleUrls: ['./order-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderItemComponent {
  @Input() order: OrderType = {} as OrderType;
  @Input() isExpandDisabled = false as boolean;
  @Input() isDelivered = false as boolean;
  @Output() onViewOrderSummary: EventEmitter<string> = new EventEmitter();
  @Output() onEditOrder: EventEmitter<string> = new EventEmitter();
  @Output() onDeleteOrder: EventEmitter<string> = new EventEmitter();
  @Output() onAdjustOrder: EventEmitter<string> = new EventEmitter();
  @Output() onPageLimitOrder: EventEmitter<string> = new EventEmitter();
  isMessagesExpanded: boolean = false;
  statusArray: string[] = ['Draft', 'Processing', 'Done', 'ChannelDetermination', 'MessageProduction', 'PdfSplitting', 'InProduction', 'Error', 'PDFMismatch'];
  statusProcessing: string[] = ['PDFAnalysis','PdfSplitting','ChannelDetermination','MessageProduction'];
  statusWithSpecificDisplay: string [] = ['Draft', 'Done','InProduction','Error','PDFMismatch'];


  constructor(private translate: TranslateService) {
  }

  onMessagesPanelExpanded() {
    this.isMessagesExpanded = true;
  }

  handleOnViewOrderSummary(orderId: string) {
    this.onViewOrderSummary.emit(orderId);
  }

  handleOnEditOrder(orderId: string) {
    this.onEditOrder.emit(orderId);
  }

  handleOnDelete(orderId: string) {
    this.onDeleteOrder.emit(orderId);
  }

  handleOnAdjust(orderId: string) {
    this.onAdjustOrder.emit(orderId);
  }

  handleonPageLimit(orderId: string) {
    this.onPageLimitOrder.emit(orderId);
  }

  getDynamicStyle(): { [key: string]: any } {
    let dynamicStyle: { [key: string]: any } = {
      'padding': '5px 16px',
      'width': '144px',
      'max-height': '30px',
      'border-radius': '100px',
      'background': this.getBackgroundColor(), 
    };

    if (this.isDelivered) {
      dynamicStyle['width'] = '128px';
    }

    return dynamicStyle;
  }

  getBackgroundColor(): string {
    switch (this.order.status) {
      case 'InProduction':
        return '#1186DB';
      case 'Error':
        return '#FF5959';
      case 'PDFMismatch':
        return '#FF5959';
      case 'PDFPageLimit':
        return '#FF5959';
      case 'Draft':
        return '#953CB4';
      case 'Done':
        return '#82BE00';
      default:
        return '#CC9300'; 
    }
  }


  getButtonLabel() {
    switch (this.order.currentEditStep) {
      case 'doc-upload':
        return 'uploadDocument';
      case 'order-basics':
        return 'checkSettings';
      case 'address-check':
        return 'checkAddress';
      case 'channel-check':
        return 'defineDeliveryType';
      case 'summary':
        return 'checkSummary';
      case 'completed':
        return '';
      default:
        return 'checkSettings';
    }
  }

  tooltipText() {
    let text = '';
    
    switch (this.order.status) {
      case 'Draft':
        text = this.translate.instant(`overview.tooltipText.draft`);
        return text;
      case 'PDFAnalysis':
      case 'PdfSplitting':
      case 'ChannelDetermination':
      case 'MessageProduction':
        text = this.translate.instant(`overview.tooltipText.preparing`);
        return text;
      case 'InProduction':
      case 'Done':
        text = `${this.translate.instant('overview.tooltipText.inProduction')} ${this.order.klaraDeliveryId}`;
        return text;
      case 'MessageProduction':
        text = `${this.translate.instant(`overview.tooltipText.inDelivery`)} ${this.order.klaraDeliveryId}`;
        return text;
      case 'PDFMismatch':
        text = this.translate.instant(`overview.tooltipText.mismatch`);
        return text;
        case 'Error':
          text = this.translate.instant(`overview.tooltipText.error`);
          return text;
      default:
        text = this.translate.instant(`overview.tooltipText.draft`);
        return text;
    }
  }
  
}
