<div class="order-name-subject-sub-header">
    <div class="read-only-wrapper">
        <ng-template [ngIf]="!isEditMode" [ngIfElse]="editMode">
            <div class="read-only">
                <div class="text">{{ form.value.name }}</div>
                <mat-divider class="divider" vertical></mat-divider>
                <div class="text">{{ form.value.subject }}</div>
                <mat-icon 
                    *ngIf="!['Done', 'InProduction'].includes(currentEditOrder?.status || '')"
                    class="edit-icon" 
                    matTooltip="Edit order name and subject" 
                    (click)="onToggleEdit()"
                >
                    mode_edit
                </mat-icon>
            </div>
        </ng-template>
    </div>
    <ng-template #editMode>
        <div class="edit-mode">
            <form [formGroup]="form">
                <mat-form-field class="name-field">
                    <mat-label>Order Name</mat-label>
                    <input matInput placeholder="Order Name" formControlName="name">
                </mat-form-field>
                <mat-form-field class="subject-field">
                    <mat-label>Subject</mat-label>
                    <input matInput placeholder="Subject" formControlName="subject">
                </mat-form-field>
            </form>
            <div class="action-buttons">
                <mat-icon class="close" matTooltip="Cancel" (click)="onCancel()">
                    close
                </mat-icon>
                <mat-icon 
                    class="check" 
                    [ngClass]="{ 'disabled': !form.valid }"
                    matTooltip="Save"
                    (click)="form.valid && onClickSave()"
                >
                    check
                </mat-icon>
            </div>
        </div>
    </ng-template>
</div>