import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root',
})
export class ErrorMessagesService {
  constructor(private translate: TranslateService) {}

  private errorTitles: Record<string, string> = {
    error1: 'errMsg.msgDelivery.error1',
    error2: 'errMsg.msgDelivery.error2',
    error3: 'errMsg.msgDelivery.error3',
    // ...add more error codes and translation keys as needed
  };

  getErrorMessage(errorCode: string): string | undefined {
    let errorKey = '';

    switch (errorCode) {
      case 'FAILED_TO_STORE': {
        errorKey = this.errorTitles['error1'];
        break;
      }
      case 'EMAIL_ADDRESS_CANNOT_BE_SEND': {
        errorKey = this.errorTitles['error2'];
        break;
      }
      // ...temporary as we still don't have the defined error codes yet
      case 'Could not store document metadata in sender folder.': {
        errorKey = this.errorTitles['error1'];
        break;
      }

      default: {
        errorKey = this.errorTitles['error3'];
        break;
      }
    }

    return this.translate.instant(errorKey || 'errMsg.genericError');
  }
}
