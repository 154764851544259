<ng-container *ngIf="stateService.appState$ | async as appState">
	<mat-toolbar class="header-toolbar">
		<button 
			class="btn-menu" 
			mat-icon-button 
			[matTooltip]="!isNavExpanded ? 'Expand Menu' : 'Minimize Menu'"
			(click)="toggleSidenav()"
		>
			<mat-icon [class.vertical]="!isNavExpanded">menu</mat-icon>
		</button>
		<button 
			*ngIf="appState.profiles.isLoaded"
			mat-button 
			[matMenuTriggerFor]="canChangeProfile && isMultipleProfiles ? company_menu : null"
		>
			<span>{{ appState.currentProfile?.name }}</span>
			<span *ngIf="canChangeProfile && isMultipleProfiles"><mat-icon>expand_more</mat-icon></span>
		</button>
		<span style="flex: 1 1 auto;"></span>
		<button mat-flat-button [matMenuTriggerFor]="language_menu" class="language-picker">
			<span>{{ selectedLanguage.language }}</span>
			<span><mat-icon>expand_more</mat-icon></span>
		  </button>
		  <mat-menu #language_menu>
			<button *ngFor="let lang of languages" mat-menu-item (click)="switchLanguage(lang)">
			  {{ lang.language }}
			</button>
		  </mat-menu>
		  
		  
		<button mat-icon-button [matMenuTriggerFor]="user_menu" class="user-menu-button">
			<img src="assets/icons/single-neutral-circle.png" alt="User" class="user-image"/>
		</button>
	</mat-toolbar>

	<mat-menu #company_menu="matMenu">
		<ng-container *ngFor="let item of appState.profiles.list; let i=index">
			<button 
				mat-menu-item
				*ngIf="appState.currentProfile?.id !== item.id"
				(click)="selectCompany(item.id)">
				{{item.name}}
			</button>
		</ng-container>
	</mat-menu>

	<mat-menu #user_menu="matMenu">
		<button mat-menu-item (click)="logout()">{{"shared.logout" | translate}}</button>
	</mat-menu>
</ng-container>