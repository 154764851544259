import { Injectable } from "@angular/core";

export type SupportedLanguages = 'en' | 'de' | 'fr' | 'it'; // Removed duplicate 'de'

@Injectable({ providedIn: 'root'})
export class LanguageService {

    get currentLanguage(): SupportedLanguages | null {
		const language = localStorage.getItem('current-language') as SupportedLanguages | null;
        
        // Improvement. Store in environment config.
        const defaultLanguage: SupportedLanguages = 'en';

        // Logging.
        if (language === null) { // Adjusted condition
            console.log("[LOG] No available language from local storage defaulting to ", defaultLanguage);
            return defaultLanguage;
        }
        
        console.log("[LOG] language from local storage using ", language);
		return language;
	}

	set currentLanguage(language: SupportedLanguages | null) {
        if (language) {
          localStorage.setItem('current-language', language);
        } else {
          localStorage.removeItem('current-language');
        }
    }
}
