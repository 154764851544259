import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { takeUntil, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { AppStateService } from '../../services/app-state.service';
import { AppState, ProfileType } from '../../state/app-state';
import { isArrayEmpty } from '../shared/utils/helper';

@Component({
  selector: 'app-invalid-profile',
  templateUrl: './invalid-profile.component.html',
  styleUrls: ['./invalid-profile.component.scss'],
})
export class InvalidProfileComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private defaultProfileId: string = '';

  constructor(private router: Router, private stateService: AppStateService) {}

  ngOnInit() {
    this.stateService.appState$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(
          (prev, curr) => prev.profiles.list === prev.profiles.list
        ),
        filter((appState) => !isArrayEmpty(appState.profiles.list))
      )
      .subscribe((appState: AppState) => {
        this.defaultProfileId = appState.profiles.list[0].id;
      });
  }

  goToOrders() {
    this.router.navigate([this.defaultProfileId, 'orders']);
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
