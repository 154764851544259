import {
  Component,
  Input,
  OnInit,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { Subscription, Subject, takeUntil, distinctUntilChanged } from 'rxjs';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Firestore, Timestamp } from '@angular/fire/firestore';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { skip } from 'rxjs/operators';

import { OrderPresetDialogComponent } from '../../shared/components/order-preset-dialog.component';
import { PdfPresetService } from '../../../services/pdf-preset.service';
import { AppStateService } from '../../../services/app-state.service';
import { FirebaseService } from '../../../services/firebase.service';
import { StorageService } from '../../../services/storage.service';
import { OrderService } from '../../../services/order.service';
import { 
  isEquivalent, 
  getCurrentDateString, 
  getCurrentTimeString 
} from '../../shared/utils/helper';
import {
  DueDate,
  AppState,
  OrderType,
  LoadingState,
  PdfAnalysisPreset,
} from '../../../state/app-state';

type RectangleLocation = {
  topLeftX: number;
  topLeftY: number;
  width: number;
  height: number;
};

type RectangleEntry = RectangleLocation & {
  label: string;
  selected: boolean;
  required: boolean;
  expanded: boolean;
  disabled: boolean;
};

type RectangleLocationMapping = {
  [key: string]: string;
  postalAddressBoxLocation: string;
  emailAddressBoxLocation: string;
};

type RectangleKey = keyof {
  postalAddressBoxLocation: RectangleLocation;
  emailAddressBoxLocation: RectangleLocation;
  mobileNumberBoxLocation?: RectangleLocation;
  whiteLineBoxLocation?: RectangleLocation;
};

type RectangleMapping = {
  key: RectangleKey;
  label: string;
  index: number;
};

const RECTANGLE_MAPPINGS: RectangleMapping[] = [
  {
    key: 'postalAddressBoxLocation',
    label: 'postalAddress',
    index: 0,
  },
  {
    key: 'emailAddressBoxLocation',
    label: 'emailAddress',
    index: 1,
  },
  {
    key: 'mobileNumberBoxLocation',
    label: 'mobileNumber',
    index: 2,
  },
  { key: 'whiteLineBoxLocation', label: 'whiteLine', index: 3 },
];

type DialogData = {
  confirmation: 'ignore-proceed' | 'apply-changes' | 'create-new';
  name?: string;
  isDefault?: boolean;
};

@Component({
  selector: 'app-preset-editor-form',
  templateUrl: './preset-editor-form.component.html',
  styleUrls: ['./preset-editor-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresetEditorFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() isReuse: boolean = false;
  @Input() order: OrderType | null = null;
  private destroy$ = new Subject<void>();
  private currentState!: AppState;
  private currentPreset: PdfAnalysisPreset | null = null;
  private isProcessingDocId: boolean = false;
  public pdfScreenshotUrl: string = '';
  public rectangles: Map<
    number,
    {
      xUnit: number;
      yUnit: number;
      widthUnit: number;
      heightUnit: number;
    }
  > = new Map();
  public pageDimension: {
    widthUnit: number;
    heightUnit: number;
  } = { widthUnit: 210, heightUnit: 297 };
  public combinedLocations: {
    postalAddressBoxLocation: RectangleLocation;
    emailAddressBoxLocation: RectangleLocation;
    mobileNumberBoxLocation?: RectangleLocation;
    whiteLineBoxLocation?: RectangleLocation;
  } = {
    postalAddressBoxLocation: { topLeftX: 0, topLeftY: 0, width: 0, height: 0 },
    emailAddressBoxLocation: { topLeftX: 0, topLeftY: 0, width: 0, height: 0 },
    mobileNumberBoxLocation: { topLeftX: 0, topLeftY: 0, width: 0, height: 0 },
    whiteLineBoxLocation: { topLeftX: 0, topLeftY: 0, width: 0, height: 0 },
  };
  public rectangleArray: RectangleEntry[] = [];
  public publicPresetForm: FormGroup = new FormGroup({
    basePreset: new FormControl<PdfAnalysisPreset | null>(null),
  });
  public presetForm: FormGroup = new FormGroup({});
  private previousTopLeftX: number = 0;
  private standardLeftPreset: PdfAnalysisPreset | null = null;
  private standardRightPreset: PdfAnalysisPreset | null = null;
  private selectedBasePreset: PdfAnalysisPreset | null = null;
  private isBasePresetSwitched: boolean = false;
  private basePresetName: string = '';
  public addressBoxLocation: 'left' | 'right' = 'left';
  public isBasePresetPublic: boolean = false;
  public isAnyRectangleExpanded: boolean = false;
  public selectedValue: string = 'dynamic';
  public numberOfDays: number = 30;
  public dueDate: Date | null = null;
  public presetHasDifferences: boolean = false;
  private subscriptions: Subscription = new Subscription();
  private defaultPresetName: string = '';
  private defaultOrderName: string = '';
  private defaultSubject: string = '';
  public orderSubject: string = '';
  public orderName: string = '';
  public perforatedLine: boolean = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dialog: MatDialog,
    private cdRef: ChangeDetectorRef,
    private pdfPresetService: PdfPresetService,
    private firebaseService: FirebaseService,
    private storageService: StorageService,
    private stateService: AppStateService,
    private orderService: OrderService,
    private translate: TranslateService
  ) {}

  async ngOnInit() {
    this.initializePresetForm(null);
    await this.pdfPresetService.loadPublicPresets();
    if (!this.isReuse) {
      this.route.paramMap
        .pipe(takeUntil(this.destroy$))
        .subscribe(async (params) => {
          const presetId = params.get('presetId');
          if (presetId && !this.currentPreset) {
            this.stateService.startLoading(LoadingState.PresetLoading);
            await this.pdfPresetService.getPreset(presetId).then(() => {
              this.stateService.stopLoading(LoadingState.PresetLoading);
            });
          } else {
            console.error('Preset ID not found in the route parameters.');
          }
        });
    }

    this.stateService.appState$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(
          (prev, curr) => prev.currentPreset === curr.currentPreset
        )
      )
      .subscribe((appState: AppState) => {
        this.currentState = appState;
        this.standardLeftPreset = appState.presets.listPublic.find(
          ({ nameEn, createdBy }) =>
            nameEn === 'Standard-Left' && createdBy === 'SmartSend Admin'
        ) as PdfAnalysisPreset;
        this.standardRightPreset = appState.presets.listPublic.find(
          ({ nameEn, createdBy }) =>
            nameEn === 'Standard-Right' && createdBy === 'SmartSend Admin'
        ) as PdfAnalysisPreset;

        if (!this.isReuse) {
          this.currentPreset = appState.currentPreset;
          if (this.currentPreset) {
            this.setPresetRectanglesValues(this.currentPreset);
            this.initializePresetForm(this.currentPreset);
            if (!this.isProcessingDocId) {
              this.isProcessingDocId = true;
              this.getPdfScreenshotUrl(this.currentPreset?.id);
            }
          }
        } else {
          this.getPdfScreenshotUrl();
        }
      });

      this.subscriptions.add(
        this.basePresetControl.valueChanges.pipe(
          // Skip the initial value set during form initialization
          skip(1)
        ).subscribe(
          (newValue: PdfAnalysisPreset) => {
            if (newValue) {
              if (this.isReuse) {
                this.setPresetValues(newValue);
              } else {
                this.setPresetValues({ ...newValue, isDefault: false });
              }
            }
          }
        )
      );

    this.subscriptions.add(
      this.translate.onLangChange.subscribe((event) => {
        if (!this.isReuse) {
          this.presetForm.get('name')?.setValue(`${this.translate.instant('buttons.newPreset')}`);
          this.presetForm.get('orderName')?.setValue(`${this.translate.instant('overview.newOrder')} <Date> <Time>`);
          this.presetForm.get('subject')?.setValue(`${this.translate.instant('pages.orderBasics.subject')}`);
        }
      })
    );
  }

  setPresetValues(preset: PdfAnalysisPreset) {
    this.selectedBasePreset = preset;
    this.basePresetName = this.getDisplayText(preset);
    this.setPresetRectanglesValues(preset);
    this.initializePresetForm(preset);

    if (!preset.profileIds || preset.profileIds.length === 0) {
      this.isBasePresetPublic = true;
    } else {
      this.isBasePresetPublic = false;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['order'] && changes['order'].currentValue) {
      this.orderName = changes['order'].currentValue.name || '';
      this.orderSubject = changes['order'].currentValue.subject || '';
    }
  }

  initializePresetForm(preset: PdfAnalysisPreset | null) {
    this.isBasePresetSwitched = true;
    const { 
      emailAdditionalInfo, 
      printMetadata, 
      orderName, 
      nameEn: presetName,
      subject,
      orderNameDateVariable,
      orderNameTimeVariable,
      orderSubjectDateVariable,
      orderSubjectTimeVariable
    } = preset || {};
    const currentDate = getCurrentDateString();
    const currentTime = getCurrentTimeString();

    if(!this.isReuse) {
      this.defaultPresetName = `${this.currentPreset?.nameEn || this.translate.instant('buttons.newPreset')}`;
      this.defaultOrderName = `${orderName || this.translate.instant('overview.newOrder')}`;
      this.defaultSubject = `${subject || this.translate.instant('pages.orderBasics.subject')}`;
    } else {
      this.orderName = orderName || this.order?.name || '';
      this.orderSubject = subject || this.order?.subject || '';
      this.defaultPresetName = presetName || '';
      this.defaultOrderName = orderName || '';
      this.defaultSubject = subject || '';

      // Update orderName
      if (orderNameDateVariable) {
        this.orderName += ` ${currentDate}`;
      }
      if (orderNameTimeVariable) {
        this.orderName += ` ${currentTime}`;
      }
    
      // Update orderSubject
      if (orderSubjectDateVariable) {
        this.orderSubject += ` ${currentDate}`;
      }
      if (orderSubjectTimeVariable) {
        this.orderSubject += ` ${currentTime}`;
      }
    }

    let ebillDueDate = preset?.ebillDueDate || null;
    this.numberOfDays = 30;
    this.dueDate = null;
    
    if (ebillDueDate) {
      this.selectedValue = ebillDueDate.type;
    
      if (ebillDueDate.type === 'fixed' && ebillDueDate.value instanceof Timestamp) {
        this.dueDate = ebillDueDate.value.toDate();
      } else if (ebillDueDate.type === 'dynamic') {
        this.numberOfDays = ebillDueDate.value as number;
      }
    } else {
      this.selectedValue = 'dynamic';
    }

    this.presetForm = new FormGroup({
      // Preset
      isDefault: new FormControl<boolean>(preset?.isDefault ?? false),
      name: new FormControl<string>(this.defaultPresetName ?? '', Validators.required),
      // Order Metadata
      subject: new FormControl<string>(this.defaultSubject ?? ''),
      orderName: new FormControl<string>(this.defaultOrderName ?? ''),
      orderNameDateVariable: new FormControl<boolean>(orderNameDateVariable || false),
      orderNameTimeVariable: new FormControl<boolean>(orderNameTimeVariable || false),
      orderSubjectDateVariable: new FormControl<boolean>(orderSubjectDateVariable || false),
      orderSubjectTimeVariable: new FormControl<boolean>(orderSubjectTimeVariable || false),
      // Document Parameters
      processType: new FormControl<string>(preset?.processType ?? 'single'),
      isAddressValidationRequired: new FormControl<boolean>(
        preset?.isAddressValidationRequired ?? false
      ),
      // eBill due date
      ebillDueDate: new FormControl<DueDate | null>(ebillDueDate),
      // Print Metadata
      docType: new FormControl<string>(preset?.docType ?? 'invoice'),
      postalDeliveryTypeDomestic: new FormControl<string>(
        preset?.postalDeliveryTypeDomestic ?? 'b-post'
      ),
      postalDeliveryTypeInternational: new FormControl<string>(
        preset?.postalDeliveryTypeInternational ?? 'standard'
      ),
      requiresBlankFirstPage: new FormControl<boolean>(
        preset?.requiresBlankFirstPage ?? false
      ),
      printMetadata: new FormGroup({
        color: new FormControl<string>(printMetadata?.color ?? 'bw'),
        printType: new FormControl<string>(printMetadata?.printType ?? 'duplex'),
      }),
    });

    this.subscriptions.add(
      this.presetForm.valueChanges.subscribe((_: any) => {
        this.checkDifferences();
      })
    );
  }

  setPresetRectanglesValues(preset: PdfAnalysisPreset) {
    const {
      postalAddressBoxLocation,
      emailAddressBoxLocation,
      mobileNumberBoxLocation,
      whiteLineBoxLocation,
    } = preset;
    this.rectangleArray = [
      {
        ...postalAddressBoxLocation,
        label: RECTANGLE_MAPPINGS[0].label,
        selected: true,
        required: true,
        expanded: false,
        disabled: false,
      },
      {
        ...emailAddressBoxLocation,
        label: RECTANGLE_MAPPINGS[1].label,
        selected: false,
        required: false,
        expanded: false,
        disabled: true,
      },
      {
        ...mobileNumberBoxLocation,
        label: RECTANGLE_MAPPINGS[2].label,
        selected: false,
        required: false,
        expanded: false,
        disabled: true,
      },
      {
        ...whiteLineBoxLocation,
        label: RECTANGLE_MAPPINGS[3].label,
        selected: false,
        required: false,
        expanded: false,
        disabled: true,
      },
    ];
    this.rectangles = new Map([
      [0, this.transformLocation(postalAddressBoxLocation)],
      [1, this.transformLocation(emailAddressBoxLocation)],
      [2, this.transformLocation(mobileNumberBoxLocation)],
      [3, this.transformLocation(whiteLineBoxLocation)],
    ]);
    this.combinedLocations = {
      postalAddressBoxLocation,
      emailAddressBoxLocation,
      mobileNumberBoxLocation,
      whiteLineBoxLocation,
    };
  }

  resetRectangle(index: number) {
    if (this.selectedBasePreset || this.currentPreset) {
      const preset = this.selectedBasePreset || this.currentPreset as PdfAnalysisPreset;
      const boxLocation = {
        ...preset[`${RECTANGLE_MAPPINGS[index].key}`],
      };

      const updatedRectangles = new Map(this.rectangles);
      this.rectangleArray[index] = {
        ...this.rectangleArray[index],
        ...boxLocation,
      };
      updatedRectangles.set(index, {
        xUnit: boxLocation.topLeftX,
        yUnit: boxLocation.topLeftY,
        widthUnit: boxLocation.width,
        heightUnit: boxLocation.height,
      });
      this.rectangles = updatedRectangles;
    }
  }

  expandRectangle(index: number) {
    this.rectangleArray[index] = {
      ...this.rectangleArray[index],
      expanded: !this.rectangleArray[index].expanded,
    };
    this.isAnyRectangleExpanded = this.rectangleArray.some(
      (rectangle) => rectangle.expanded
    );
  }

  get basePresetControl(): FormControl {
    return (
      (this.publicPresetForm.get('basePreset') as FormControl) ||
      new FormControl()
    );
  }

  async getPdfScreenshotUrl(presetId: string = '') {
    this.stateService.startLoading(LoadingState.PresetLoading);
    try {
      if (!this.isReuse && presetId) {
        this.pdfScreenshotUrl = await this.storageService.getPresetThumbnail(
          presetId
        );
      } else {
        if (this.order) {
          this.pdfScreenshotUrl =
            await this.storageService.getOrderPresetThumbnail(this.order.id);
        }
      }
      this.cdRef.markForCheck();
    } finally {
      this.stateService.stopLoading(LoadingState.PresetLoading);
      this.isProcessingDocId = false;
    }
  }

  private getRectangleLocationFromData(data: any): RectangleLocation {
    return {
      topLeftX: parseFloat(data.xUnit.toFixed(2)),
      topLeftY: parseFloat(data.yUnit.toFixed(2)),
      width: parseFloat(data.widthUnit.toFixed(2)),
      height: parseFloat(data.heightUnit.toFixed(2)),
    };
  }

  toggleSelected(index: number, event: MatCheckboxChange): void {
    const rectangle = this.rectangleArray[index];
    if (rectangle) {
      rectangle.selected = event.checked;
      const updatedRectangles = new Map(this.rectangles);
      if (event.checked) {
        updatedRectangles.set(index, {
          xUnit: rectangle.topLeftX,
          yUnit: rectangle.topLeftY,
          widthUnit: rectangle.width >= 10 ? rectangle.width : 10 * index,
          heightUnit: rectangle.height >= 10 ? rectangle.height : 10 * index,
        });
      } else {
        updatedRectangles.set(index, {
          xUnit: 0,
          yUnit: 0,
          widthUnit: 0,
          heightUnit: 0,
        });
        rectangle.topLeftX = 0;
        rectangle.topLeftY = 0;
        rectangle.width = 0;
        rectangle.height = 0;
      }
      this.rectangles = updatedRectangles;
    }
  }

  onInputFieldChanges(
    event: Event,
    field: keyof RectangleLocation,
    index: number
  ) {
    const inputElement = event.target as HTMLInputElement;
    const value = inputElement.valueAsNumber;
    const rectangle: RectangleLocation = { ...this.rectangleArray[index] };

    if (value !== null && value !== undefined) {
      rectangle[field] = value;

      const updatedRectangles = new Map(this.rectangles);

      updatedRectangles.set(index, {
        xUnit: rectangle.topLeftX,
        yUnit: rectangle.topLeftY,
        widthUnit: rectangle.width >= 10 ? rectangle.width : 10 * index,
        heightUnit: rectangle.height >= 10 ? rectangle.height : 10 * index,
      });

      this.rectangles = updatedRectangles;
    }
  }

  handleRectangleResize(event: any) {
    const rectanglesInUnit: Map<number, any> = event.rectanglesInUnit;

    RECTANGLE_MAPPINGS.forEach((mapping) => {
      const rectData = rectanglesInUnit.get(mapping.index);

      if (rectData) {
        const location = this.getRectangleLocationFromData(rectData);
        this.combinedLocations[mapping.key] = location;
        this.rectangleArray[mapping.index] = {
          ...this.rectangleArray[mapping.index],
          ...location,
          label: mapping.label,
          selected: !(location.height && location.width) ? false : true,
          required: mapping.index === 0,
        };
        this.rectangles.set(mapping.index, rectData);

        // Detect movement direction for address rectangle
        if (mapping.index === 0) {
          this.detectRectangleMovement(location);
        }
      }
    });
    this.checkDifferences();
  }

  setRequireBlankFirstPage(rectangle: RectangleLocation) {
    // default value is based on stand-left preset postal address
    const defaultRectangleValue =
      this.addressBoxLocation === 'left'
        ? {
            height: 25,
            topLeftX: 21,
            topLeftY: 55,
            width: 70,
          }
        : {
            height: 25,
            topLeftX: 117,
            topLeftY: 55,
            width: 60,
          };
    const requiresBlankFirstPage =
      rectangle.height > defaultRectangleValue.height + 5 ||
      rectangle.width > defaultRectangleValue.width + 10 ||
      Math.abs(rectangle.topLeftX - defaultRectangleValue.topLeftX) > 3 ||
      Math.abs(rectangle.topLeftY - defaultRectangleValue.topLeftY) > 3;

    this.presetForm.patchValue({ requiresBlankFirstPage });
  }

  trackByFunction(index: number) {
    return index;
  }

  async onPublish() {
    if (!this.isReuse) {
      this.stateService.startLoading(LoadingState.PresetLoading);
      try {
        const presetId = this.currentPreset?.id!;
        const { name, isDefault, ...otherProps } = this.presetForm.value;

        // Update all private presets with "isDefault: true" to "isDefault: false"
        if (isDefault) {
          await this.pdfPresetService.updateAllPresetsToNonDefault();
        }

        const updatedPreset: Partial<PdfAnalysisPreset> = {
          ...this.combinedLocations,
          ...otherProps,
          nameDe: name,
          nameEn: name,
          nameFr: name,
          nameIt: name,
          status: 'published',
          isDefault,
          deleteType: 'both',
        };

        await this.pdfPresetService.updatePreset(presetId, updatedPreset);
      } catch (error) {
        console.error('Error during preset creation or order update:', error);
      } finally {
        this.stateService.stopLoading(LoadingState.PresetLoading);
        this.onCancel();
      }
    } else {
      if (!this.presetHasDifferences) {
        await this.processOrderAndPublishPdfAnalysis();
      } else {
        await this.openDialog();
      }
    }
  }

  onCancel() {
    if (!this.isReuse) {
      this.router.navigate([this.currentState.profileId, 'presets']);
    } else {
      this.router.navigate([this.currentState.profileId, 'orders']);
    }
  }

  preventScrollPropagation(event: WheelEvent): void {
    event.stopPropagation();
  }

  detectRectangleMovement(newRectangle: RectangleLocation): void {
    const CENTER_LINE = this.pageDimension.widthUnit / 2; // Center line of A4 paper

    // Assuming you have a property to store the previous topLeftX value
    if (
      newRectangle.topLeftX > CENTER_LINE &&
      this.previousTopLeftX <= CENTER_LINE
    ) {
      console.log('Moved from Left to Right');
      this.addressBoxLocation = 'right';
      this.updateAddressBoxAndPreset(this.standardRightPreset!);
    } else if (
      newRectangle.topLeftX < CENTER_LINE &&
      this.previousTopLeftX >= CENTER_LINE
    ) {
      console.log('Moved from Right to Left');
      this.addressBoxLocation = 'left';
      this.updateAddressBoxAndPreset(this.standardLeftPreset!);
    }

    // Perform checking if blank first page is need
    this.setRequireBlankFirstPage(newRectangle);

    // Update previousTopLeftX for next movement detection
    this.previousTopLeftX = newRectangle.topLeftX;
  }

  updateAddressBoxAndPreset(preset: PdfAnalysisPreset): void {
    if (!this.isBasePresetSwitched) {
      this.publicPresetForm.setValue({ basePreset: preset });
    }
    this.isBasePresetSwitched = false;
  }

  getValuesFromControls(controlNames: string[]): string {
    return controlNames
      .map((name) => {
        const value = this.presetForm.get(name)?.value;
        let translatedValue; 
  
        if (name.includes('printMetadata') || name.includes('docType') || name.includes('processType')) {
          translatedValue = this.translate.instant('presets.create.' + value); 
        } else {
          translatedValue = value;
        }
        translatedValue = translatedValue === 'standard' ? 'Standard' : translatedValue === 'b-post' ? 'B-post'  : translatedValue === 'a-post' ? 'A-post' : translatedValue;
        return translatedValue;
      })
      .join(', ');
  }
  

  async openDialog(): Promise<void> {
    const dialogRef = this.dialog.open(OrderPresetDialogComponent, {
      width: '760px',
      data: {
        isPublic: this.isBasePresetPublic,
        presetName: this.basePresetName,
        hasDifferences: this.presetHasDifferences,
      },
    });

    dialogRef.afterClosed().subscribe(async (result: any) => {
      if (result && result.action === 'save-next') {
        try {
          this.stateService.startLoading(LoadingState.OrderLoading);

          const data = result.data as DialogData;
          if (data.confirmation === 'ignore-proceed') {
            await this.handleIgnoreProceed();
          } else if (
            ['apply-changes', 'create-new'].includes(data.confirmation)
          ) {
            const preset = this.presetFormData();
            await this.applyChangesOrCreateNew(preset, data);
          }

          await this.publishPdfAnalysis();
        } catch (err) {
          console.log('Error occurs:', err);
        } finally {
          this.stateService.stopLoading(LoadingState.OrderLoading);
          if (this.currentState.currentProfile?.id) {
            this.router.navigate([
              this.currentState.currentProfile.id,
              'orders',
            ]);
          }
        }
      }
    });
  }

  async handleIgnoreProceed() {
    const preset = this.presetFormData();
    const orderDetails = this.extractOrderDetailsFromPreset(preset);
    await this.orderService.updateOrder(orderDetails);
  }

  extractOrderDetailsFromPreset(preset: PdfAnalysisPreset): OrderType {
    const { 
      subject, 
      docType, 
      processType, 
      printMetadata, 
      postalDeliveryTypeDomestic, 
      postalDeliveryTypeInternational 
    } = preset;
   const dueDate = this.getEbillDueDate();
   const ebillDueDate = Timestamp.fromDate(dueDate);

    return {
      ...this.order,
      name: this.orderName,
      subject: this.orderSubject,
      docType,
      processType,
      printMetadata,
      postalDeliveryTypeDomestic,
      postalDeliveryTypeInternational,
      ebillDueDate,
      pdfAnalysisPresetSnapshot: preset,
      currentEditStep: 'address-check',
    } as OrderType;
  }

  presetFormData(): PdfAnalysisPreset {
    const profileId = this.currentState?.currentProfile?.id;
    const {
      name: _,
      ...otherPresetFormProps
    } = this.presetForm.value;
    return {
      ...this.selectedBasePreset,
      ...this.combinedLocations,
      ...otherPresetFormProps,
      profileIds: !profileId ? [] : [profileId],
    };
  }

  async applyChangesOrCreateNew(preset: PdfAnalysisPreset, data: DialogData) {
    const { id: presetId, ...otherPresetProps } = preset;
    const currentUser = this.currentState.currentUser;
    const user = currentUser?.displayName || currentUser?.email || '';
    const orderDetails = this.extractOrderDetailsFromPreset(preset);
    await this.orderService.updateOrder(orderDetails);

    if (data.confirmation === 'apply-changes') {
      await this.pdfPresetService.updatePreset(presetId, {
        ...otherPresetProps,
        modifiedBy: user,
        modifiedAt: Timestamp.now(),
      });
    }

    if (data.confirmation === 'create-new') {
      const { name: presetName, isDefault } = data;
      // Update all private presets with "isDefault: true" to "isDefault: false"
      if (isDefault) {
        await this.pdfPresetService.updateAllPresetsToNonDefault();
      }
      // create new preset
      const { id: resPresetId } = await this.pdfPresetService.createNewPreset({
        ...otherPresetProps,
        nameDe: presetName,
        nameEn: presetName,
        nameFr: presetName,
        nameIt: presetName,
        isDefault,
        createdBy: user,
        modifiedBy: user,
        createdAt: Timestamp.now(),
        modifiedAt: Timestamp.now(),
        deleteType: 'jpg',
      });
      // copy order thumbnail to new preset storage directory
      const orderId = this.order?.id!;
      await this.firebaseService.copyImageToNewDirectory(orderId, resPresetId);
    }
  }

  async publishPdfAnalysis() {
    const orderId = this.order?.id;
    const profileId = this.currentState.currentProfile?.id;

    const publishPdfAnalysisRequest = httpsCallable(
      getFunctions(undefined, 'europe-west6'),
      'publishPdfAnalysisRequest'
    );

    await publishPdfAnalysisRequest({ orderId, profileId });
    await this.orderService.updateOrderPdfAnalysis();
  }

  checkDifferences(): void {
    const presetFormData = this.presetFormData();

    if (!presetFormData || !this.selectedBasePreset) {
      return;
    }

    const profileIds = null;
    this.presetHasDifferences = !isEquivalent(
      { ...presetFormData, profileIds },
      { ...this.selectedBasePreset, profileIds }
    );

    this.isAnyRectangleExpanded = this.rectangleArray.some(
      (rectangle) => rectangle.expanded
    );
    this.cdRef.markForCheck();
  }

  transformLocation(boxLocation: RectangleLocation) {
    return {
      xUnit: boxLocation.topLeftX,
      yUnit: boxLocation.topLeftY,
      widthUnit: boxLocation.width,
      heightUnit: boxLocation.height,
    };
  }

  async processOrderAndPublishPdfAnalysis(): Promise<void> {
    this.stateService.startLoading(LoadingState.PresetLoading);
    try {
      await this.handleIgnoreProceed();
      await this.publishPdfAnalysis();

      this.stateService.stopLoading(LoadingState.PresetLoading);
      this.onCancel();
    } catch (error) {
      console.error('An error occurred:', error);
    } finally {
      this.stateService.stopLoading(LoadingState.PresetLoading);
    }
  }

  getEbillDueDate(): Date {
    let currentDate = new Date();

    if (this.selectedValue === 'dynamic') {
      return new Date(
        currentDate.setDate(currentDate.getDate() + this.numberOfDays)
      );
    } else if (this.selectedValue === 'fixed' && this.dueDate) {
      return this.dueDate;
    }

    // Default return value is 30 days ahead of the current date
    return new Date(currentDate.setDate(currentDate.getDate() + 30));
  }

  updateFormEbillDueDate() {
    // Check if the ebillDueDate control exists in the form
    const ebillDueDateControl = this.presetForm.get('ebillDueDate');
    if (!ebillDueDateControl) {
      console.error('ebillDueDate control does not exist in the form');
      return;
    }
  
    if (this.selectedValue === 'dynamic') {
      // For dynamic, set the value to the number of days
      ebillDueDateControl.setValue({ type: 'dynamic', value: this.numberOfDays });
    } else if (this.selectedValue === 'fixed' && this.dueDate) {
      // For fixed, convert the date to a Timestamp and set the value
      ebillDueDateControl.setValue({ type: 'fixed', value: Timestamp.fromDate(this.dueDate) });
    }
  }

  getDisplayText(preset: PdfAnalysisPreset): string {
    if (!preset) return '';

    let translationKey: string;

    const lang = this.translate.currentLang || this.translate.getDefaultLang();
    switch (lang) {
      case 'de':
        translationKey = preset.nameDe || preset.nameEn;
        break;
      case 'en':
        translationKey = preset.nameEn;
        break;
      case 'fr':
        translationKey = preset.nameFr || preset.nameEn;
        break;
      case 'it':
        translationKey = preset.nameIt || preset.nameEn;
        break;
      default:
        translationKey = preset.nameEn;
        break;
    }

    if (translationKey) {
      return this.translate.instant(translationKey);
    }

    return '';
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.currentPreset = null;
    this.stateService.updateState({ currentPreset: null });
    this.subscriptions.unsubscribe();
  }
}
