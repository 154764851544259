<div class="icon-container">
    <ng-container *ngIf="chosenChannel !== 'n/a'; else naBlock">
        <img *ngIf="chosenChannel === 'ePost'" class="icon" src="assets/img/ePost.png" alt="ePost" matTooltip="ePost" />
        <mat-icon class="icon mat-icon" [ngClass]="chosenChannel === 'print' ? 'print-icon' : 'email-icon'"
            *ngIf="chosenChannel !== 'ePost'" matTooltip="{{ chosenChannel === 'print' ? 'Print' : 'Email' }}">
            {{ chosenChannel === 'print' ? 'print' : 'alternate_email' }}
        </mat-icon>
    </ng-container>
    <ng-template #naBlock>
        <mat-icon class="icon mat-icon na-icon" matTooltip="N/A">not_interested</mat-icon>
    </ng-template>
</div>