import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { AppState, OrderType, LoadingState } from '../../../state/app-state';
import { AppStateService } from '../../../services/app-state.service';
import { OrderService } from '../../../services/order.service';
import { isObjectEmpty } from './../../shared/utils/helper';

@Component({
  selector: 'app-order-settings',
  templateUrl: './order-settings.component.html',
  styleUrls: ['./order-settings.component.scss'],
})
export class OrderSettingsComponent implements OnInit, OnDestroy {
  public currentOrder: OrderType | null = null;
  private destroy$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private stateService: AppStateService,
    private orderService: OrderService
  ) {}

  async ngOnInit() {
    this.route.parent?.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (params) => {
        const orderId = params.get('orderId');
        if (orderId) {
          this.stateService.startLoading(LoadingState.OrderLoading);
          await this.orderService.getOrder(orderId).then(() => {
            this.stateService.stopLoading(LoadingState.OrderLoading);
          });
        } else {
          console.error('Order ID is not available.');
        }
      });

    this.stateService.appState$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (appState: AppState) => {
        if (!isObjectEmpty(appState?.currentEditOrder)) {
          this.currentOrder = appState.currentEditOrder;
        }
      });
  }

  ngOnDestroy() {
    this.stateService.updateState({ currentEditOrder: null });
    this.destroy$.next();
    this.destroy$.complete();
  }
}
