import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

import { AppStateService } from '../../../services/app-state.service';
import { isObjectEmpty } from './../../shared/utils/helper';
import { AppState } from '../../../state/app-state';

@Component({
  selector: 'app-completed',
  templateUrl: './completed.component.html',
  styleUrls: ['./completed.component.scss'],
})
export class CompletedComponent implements OnInit, OnDestroy {
  private profileId: string = '';
  private subscriptions: Subscription = new Subscription();

  constructor(public stateService: AppStateService, private router: Router) {}

  ngOnInit() {
    this.subscriptions.add(
      this.stateService.appState$.subscribe(async (appState: AppState) => {
        if (
          !isObjectEmpty(appState.currentProfile) &&
          this.profileId !== appState?.currentProfile?.id
        ) {
          this.profileId = appState.currentProfile!.id;
        }
      })
    );
  }

  goToOrders() {
    this.router.navigate([this.profileId, 'orders']);
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.stateService.updateState({ currentEditOrder: null });
  }
}
