<ng-container *ngIf="stateService.appState$ | async as appState">
    <ng-container *ngIf="appState.profiles.isLoaded; else onlyRouterOutlet">
        <mat-sidenav-container class="sidenav-container">
            <mat-sidenav #sidenav [style.width.px]="isSidenavMaximized ? 240 : 80" mode="side" opened class="sidenav">
                <div class="sidenav-content">
                    <div class="nav-list-wrapper">
                        <mat-nav-list class="menus" *ngFor="let menu of menus;">
                            <ng-container *ngIf="menu.redirectUrl; else routerLinkMenu">
                              <a class="menu"
                                mat-list-item
                                (click)="openRedirectUrl(menu.redirectUrl)"
                                [matTooltip]="'appNav.' + menu.label | translate"
                                target="_blank"
                              >
                                <div class="menu-content" [style.justifyContent]="!isSidenavMaximized ? 'center': 'start'">
                                  <img 
                                      width="40"
                                      height="40"
                                      [src]="menu.icon.default"
                                      [alt]="'appNav.' + menu.label | translate"
                                      class="epost-icon"
                                    >
                                  <span class="menu-label" *ngIf="isSidenavMaximized">{{'appNav.' + menu.label | translate}}</span>
                                </div>
                              </a>
                            </ng-container>
                            <ng-template #routerLinkMenu>
                              <a class="menu"
                                mat-list-item
                                [routerLink]="'/' + selectedProfileId + menu.routerLink"
                                routerLinkActive="active-route"
                                #rla="routerLinkActive"
                              >
                                <div class="menu-content" [style.justifyContent]="!isSidenavMaximized ? 'center': 'start'">
                                  <img 
                                    width="24"
                                    height="24"
                                    [src]="rla.isActive ? menu.icon.active : menu.icon.default"
                                    [alt]="'appNav.' + menu.label | translate"
                                    [matTooltip]="'appNav.' + menu.label | translate"
                                  >
                                  <span class="menu-label" *ngIf="isSidenavMaximized">{{'appNav.' + menu.label | translate}}</span>
                                </div>
                              </a>
                            </ng-template>
                        </mat-nav-list>
                    </div>
                    <div class="header-section">
                        <img 
                          class="logo" 
                          src="assets/logos/schweizerische-post-logo.jpg" 
                          [style.height.px]="48"
                        >
                    </div>
                </div>
            </mat-sidenav>
        
            <mat-sidenav-content [style.marginLeft.px]="isSidenavMaximized ? 240 : 80">
                <router-outlet></router-outlet>
            </mat-sidenav-content>
            
        </mat-sidenav-container>
    </ng-container>
    
    <ng-template #onlyRouterOutlet>
        <router-outlet></router-outlet>
    </ng-template>
</ng-container>

<!-- Overlay when offline -->
<div *ngIf="isOverlayVisible$ | async" class="offline-overlay">
    No Internet Connection
</div>
