import { Pipe, PipeTransform } from '@angular/core';
import { OrderType, PdfAnalysisProgress } from '../../../state/app-state';

@Pipe({
  name: 'pdfAnalysisProgress',
  pure: true
})
export class CalculatePdfAnalysisProgressPipe implements PipeTransform {

  transform(order: OrderType): PdfAnalysisProgress {
    return calculatePdfAnalysisProgress(order);
  }
}

const calculatePdfAnalysisProgress = (order: OrderType): PdfAnalysisProgress  => {

  if (order.status !== 'PDFAnalysis' || (order.pdfAnalysisStatus === null || order.pdfAnalysisStatus === undefined)) {
    return {
      analysisStatus: "WAITING",
      prepared: false,
      preparingProgressPercentage: 0,
      detectingProgressPercentage: 0,
      segregatingProgressPercentage: 0,
      validatingProgressPercentage: 0,
    }
  }

  if (order.pdfAnalysisStatus?.stage === "WAITING") {
    return {
      analysisStatus: "WAITING",
      prepared: false,
      preparingProgressPercentage: 0,
      detectingProgressPercentage: 0,
      segregatingProgressPercentage: 0,
      validatingProgressPercentage: 0,
    }
  }

  if (order.pdfAnalysisStatus?.stage === "PREPARING") {
    return {
      analysisStatus: "PREPARING",
      prepared: order.pdfAnalysisStatus.preparingIsFileDownloaded,
      preparingProgressPercentage: (order.pdfAnalysisStatus.preparingDocumentCurrentNum / order.pdfAnalysisStatus.preparingDocumentCount) * 100,
      detectingProgressPercentage: 0,
      segregatingProgressPercentage: 0,
      validatingProgressPercentage: 0,
    }
  }

  if (order.pdfAnalysisStatus?.stage === "DETECTING") {
    return {
      analysisStatus: "DETECTING",
      prepared: true,
      preparingProgressPercentage: 100,
      detectingProgressPercentage: (order.pdfAnalysisStatus.detectingCurrentPageNum / order.pdfAnalysisStatus.detectingNumPages) * 100,
      segregatingProgressPercentage: 0,
      validatingProgressPercentage: 0,
    }
  }

  if (order.pdfAnalysisStatus.stage === "SEGREGATING") {
    return {
      analysisStatus: "SEGREGATING",
      prepared: true,
      preparingProgressPercentage: 100,
      detectingProgressPercentage: 100,
      segregatingProgressPercentage: (order.pdfAnalysisStatus.segregatingCurrentPageNum / order.pdfAnalysisStatus.segregatingNumPages) * 100,
      validatingProgressPercentage: 0,
    }
  }

  if (order.pdfAnalysisStatus.stage === "VALIDATING") {
    return {
      analysisStatus: "VALIDATING",
      prepared: true,
      preparingProgressPercentage: 100,
      detectingProgressPercentage: 100,
      segregatingProgressPercentage: 100,
      validatingProgressPercentage: (order.pdfAnalysisStatus.validationCurrentMessageNum / order.pdfAnalysisStatus.validationNumMessages) * 100,
    }
  }

  return {
    analysisStatus: "DONE",
    prepared: true,
    preparingProgressPercentage: 100,
    detectingProgressPercentage: 100,
    segregatingProgressPercentage: 100,
    validatingProgressPercentage: 100,
  }
};
