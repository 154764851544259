import { Component, Input } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { DeliveryTypeLineItem, OrderType } from '../../../../state/app-state';

@Component({
  selector: 'app-delivery-type-items-table',
  templateUrl: './delivery-type-items-table.component.html',
  styleUrls: ['./delivery-type-items-table.component.scss'],
})
export class DeliveryTypeItemsTableComponent {
  @Input() deliveryTypeLineItems: DeliveryTypeLineItem[] = [];
  @Input() totalNumberOfRecipients: number = 0;
  @Input() totalAmountInCents: number = 0;
  @Input() printMetadata: OrderType['printMetadata'] | null | undefined = null;
  columnDisplayNameMap: { [key: string]: string } = {
    selectedChannel: 'Delivery Type',
    print: 'Print',
    // envelope: 'Envelope',
    // priceCents: 'CHF Price',
    typeCount: 'Recipients',
    // total: 'CHF Amount',
  };
  columns: string[] = Object.keys(this.columnDisplayNameMap) as string[];
  footerColumns: string[] = ['selectedChannel', 'typeCount'];

  constructor(private translate: TranslateService) {}

  isPriceOrTotal(column: string): boolean {
    return column === 'priceCents' || column === 'total';
  }

  isSpecificColumn(column: string, colName: string): boolean {
    return column === colName;
  }

  getDisplayValue(column: string, value: any): string {
    if (this.isPriceOrTotal(column)) {
      return parseFloat(value).toFixed(2);
    }
    if (this.isNotPrint(column, value)) {
      return 'N/A';
    }

    if (value.toString() === 'Print & Send - Switzerland A-Post' || value.toString()=== 'Print & Send') {
      return this.translate.instant(`table.printAndSend`);
    }

    if (column === 'print') {
      return `${this.translate.instant(
        `presets.create.${this.printMetadata?.color}`
      )} / ${this.translate.instant(
        `presets.create.${this.printMetadata?.printType}`
      )}`;
    }

    return value.toString();
  }

  isNotPrint(column: string, value: string): boolean {
    return (
      (column === 'print' && value !== 'Color / Duplex') ||
      (column === 'envelope' && value !== 'C5')
    );
  }
}
