<div class="icon-container">
    <img class="icon" src="assets/img/ePost{{ supportedChannels.includes('ePost') ? '' : '-grayscale' }}.png"
        alt="ePost" matTooltip="ePost" />
    <mat-icon [ngClass]="supportedChannels.includes('print') ? 'active-print' : 'disabled'" class="icon mat-icon"
        matTooltip="Print">
        print
    </mat-icon>
    <!-- <mat-icon [ngClass]="supportedChannels.includes('email') ? 'active-email' : 'disabled'"
        class="icon mat-icon" matTooltip="Email">
        alternate_email
    </mat-icon> -->
</div>