import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

import { AppStateService } from '../../services/app-state.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit {
  ERROR_MAPPING: {
    [key: string]: string | { title: string; description: string };
  } = {
    '400': 'errMsg.logout.400',
    '401': 'errMsg.logout.401',
    '403': 'errMsg.logout.403',
    '429': 'errMsg.logout.429',
    '500': 'errMsg.logout.500',
    'noValidCompany': 'errMsg.logout.noValidCompany',
  };

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private stateService: AppStateService,
    private translate: TranslateService
  ) {}

  ngOnInit() {
    // Get the origin of the current URL (protocol + host)
    const originUrl = window.location.origin;
    // Get the current URL
    const currentUrl = new URL(window.location.href);
    // Check if code, root_url and error are present in the URL parameters and have a value
    const root_url = currentUrl.searchParams.get('root_url') || '';
    const error = currentUrl.searchParams.get('error') || '';

    if (root_url && originUrl !== root_url) {
      if (!error) {
        window.location.href = `${root_url}/logout`;
      } else {
        window.location.href = `${root_url}/logout?error=${error}`;
      }
    }

    if (error) {
      this.handleError(error);
    }
  }

  handleError(errorCode: string) {
    let errorMessage: string | { title: string; description: string } = '';
    this.translate
      .get(`${this.ERROR_MAPPING[errorCode]}`)
      .subscribe((error: any) => {
        errorMessage = error;
        if (typeof errorMessage === 'string') {
          this.toastr.error(errorMessage, `Error ${errorCode}`, {
            timeOut: 0,
            disableTimeOut: true,
            extendedTimeOut: 0,
          });
        } else {
          if (errorMessage) {
            this.toastr.error(errorMessage.description, errorMessage.title, {
              timeOut: 0,
              disableTimeOut: true,
              extendedTimeOut: 0,
            });
          }
        }
      });
  }

  redirectToLogin() {
    // Navigate to login page
    this.stateService.resetStateToDefault();
    this.router.navigate(['/login']);
  }
}
