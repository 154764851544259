<app-header></app-header>
<ng-container *ngIf="this.stateService.appState$ | async as appState">
    <mat-toolbar class="header-overview">
        <h3 class="title">{{'overview.configurationSets' | translate}}</h3>
		<ng-template [ngIf]="appState.presets.list.size > 0">
			<span style="flex: 1 1 auto;"></span>
			<input 
                matInput 
                [placeholder]="'presets.create.presetName' | translate" 
                class="name-search" 
                [(ngModel)]="presetNameSearchKeyword"
				(input)="onSearchChange($event)" 
            />
			<div class="vl"></div>
			<input 
                matInput 
                readonly 
                placeholder="Status" 
                class="status-filter" 
                [matMenuTriggerFor]="status_menu"
				[value]="'selectedStatusDisplay' | translate">
		</ng-template>
	</mat-toolbar>
    <mat-menu #status_menu="matMenu">
		<div class="status-menu-width"></div>
		<button 
            mat-menu-item 
            *ngFor="let item of status" 
            (click)="selectStatus($event, item)"
        >
			<mat-icon *ngIf="item.selected == false">check_box_outline_blank</mat-icon>
			<mat-icon *ngIf="item.selected == true">check_box</mat-icon>
			{{'presetStatus.' + item.name | translate}}
		</button>
	</mat-menu>
    <div class="presets-container">
        <button 
            mat-raised-button
            color="primary" 
            class="new-preset-btn" 
            (click)="onNewPresetClick()"
        >
        {{ "buttons.newPreset" | translate }}
        </button>
        <div class="no-presets-msg" *ngIf="appState.presets.list.size == 0">
            <p>Hi {{appState.currentUser?.email}}</p>
            <div>{{"statement.currentlyNoPreset" | translate}}</div>
            <div>
                {{"statement.presetsAllow" | translate}}
            </div>
            <div>
                {{"statement.thisEnsures" | translate}}
            </div>
        </div>
        <div class="preset-list-wrapper" *ngIf="appState.presets.list.size > 0">
            <ng-container *ngIf="presets.length > 0; else noActiveOrders">
                <ng-container *ngFor="let preset of presets; trackBy:trackByOPresetId">
                    <mat-accordion class="preset-list" [togglePosition]="'before'" multi>
                        <app-preset-item [preset]="preset" (onEdit)="onEdit($event)" (onDelete)="onDelete($event)">
                        </app-preset-item>
                    </mat-accordion>
                </ng-container>
            </ng-container>
            <ng-template #noActiveOrders>
                <div class="no-results-found">
                    <div class="no-order-message">{{"overview.noPresetsFound" | translate}}</div>
                </div>
            </ng-template>
        </div>
    </div>
</ng-container>