<div class="preset-editor">
	<img [src]="pdfScreenshotUrl" (load)="removeSkeletonLoader()" alt="PDF Screenshot" style="display: none;">
	<div #page class="paper skeleton-loader"  [style.background-image]="'url(' + pdfScreenshotUrl + ')'">
		
		<div class="overlay top">
			<span class="watermark">WHITE SPACE AREA</span>
		</div>
		<div class="overlay right"></div>
		<div class="overlay bottom"></div>
		<div class="overlay left-top"></div>
		<div class="overlay left-bottom"></div>
		<div *ngIf="addressBoxLocation === 'left'" class="overlay left-window">
			<div class="frankierbereich">
				<span class="watermark">FRANKING AREA</span>
			</div>
			<div class="adressbereich">
				<span class="watermark">ADDRESS AREA</span>
			</div>
			<div class="datamatrix">
				<span class="watermark">DATA MATRIX</span>
			</div>
		</div>
		<div *ngIf="addressBoxLocation === 'right'" class="overlay right-window">
			<div class="frankierbereich">
				<span class="watermark">FRANKING AREA</span>
			</div>
			<div class="adressbereich">
				<span class="watermark">ADDRESS AREA</span>
			</div>
			<div class="datamatrix">
				<span class="watermark">DATA MATRIX</span>
			</div>
		</div>
		<div *ngIf="showPerforatedLine" class="overlay horizontal-cutoff-line"></div>
		<div *ngIf="showPerforatedLine" class="overlay vertical-cutoff-line"></div>
		
		<ng-container *ngIf="renderState$ | async as uiState">
			<ng-container *ngFor="let item of uiState.rectanglesInPx | keyvalue; trackBy: trackByKey">
				<div [attr.id]="'target' + item.key" [ngClass]="'target target' + item.key" class="targetRectangle"
					[ngStyle]="{
						'width': item.value.widthPx + 'px',
						'height': item.value.heightPx + 'px',
						'transform': 'translate(' + item.value.xPx + 'px, '+item.value.yPx + 'px)'
					}"
				>
				</div>
			</ng-container>
			<ngx-moveable #moveable
				[target]="'.targetRectangle'"
				[draggable]="true"
				[resizable]="true"
				[keepRatio]="false"
				[snappable]="true"
				[bounds]="bounds"
				[throttleResize]="0"
				[throttleDrag]="1"
				[edgeDraggable]="false"
				[useResizeObserver]="true"
				[useMutationObserver]="true"
				[individualGroupable]="true"
				(drag)="onDrag($event)"
				(dragStart)="onDragStart($event)"
				(dragEnd)="onDragEnd($event)"
				[renderDirections]="['nw', 'n', 'ne', 'w', 'e', 'sw', 's', 'se']"
				[edge]="[]"
				[zoom]="1"
				[origin]="false"
				[padding]="{ left: 0, top: 0, right: 0, bottom: 0 }"
				(resizeStart)="onResizeStart($event)"
				(resize)="onResize($event)"
				(resizeEnd)="onResizeEnd($event)"
				(bound)="onBound($event)"
				>
			</ngx-moveable>
		</ng-container>
	</div>
</div>