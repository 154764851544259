import { Injectable } from '@angular/core';
import { Firestore, Timestamp } from '@angular/fire/firestore';
import {
  collection,
  doc,
  getDoc,
  addDoc,
  setDoc,
  deleteDoc,
  query,
  where,
  getDocs,
  writeBatch,
  updateDoc,
} from 'firebase/firestore';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

import { AppStateService } from './app-state.service';
import { AppState, PdfAnalysisPreset } from '../state/app-state';
import { isObjectEmpty } from '../components/shared/utils/helper';

@Injectable({
  providedIn: 'root',
})
export class PdfPresetService {
  private appStateSubscription?: Subscription;
  private currentState!: AppState;

  constructor(
    private firestore: Firestore,
    private toastr: ToastrService,
    private translate: TranslateService,
    private appStateService: AppStateService
  ) {
    this.appStateSubscription = this.appStateService.appState$.subscribe(
      (state) => {
        this.currentState = state;
      }
    );
  }

  async loadPresets(): Promise<void> {
    const profileId = this.currentState.profileId;
    if (!profileId) {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate
          .get('errMsg.profIdRequired')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      throw errorMessage;
    }

    const presetsCollectionRef = collection(
      this.firestore,
      'pdf-analysis-presets'
    );
    const q = query(
      presetsCollectionRef,
      where('profileIds', 'array-contains', profileId)
    );
    const querySnapshot = await getDocs(q);

    const presets: Map<string, PdfAnalysisPreset> = new Map();
    for (const doc of querySnapshot.docs) {
      const preset = { id: doc.id, ...doc.data() } as PdfAnalysisPreset;
      presets.set(preset.id, preset);
    }

    // Store in app state.
    this.appStateService.updateState({
      presets: {
        ...this.currentState.presets,
        isLoaded: true,
        list: presets,
      },
    });
  }

  async loadPublicPresets(): Promise<void> {
    const profileId = this.currentState.profileId;
    if (!profileId) {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate
          .get('errMsg.profIdRequired')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      throw errorMessage;
    }

    const presetsCollectionRef = collection(
      this.firestore,
      'pdf-analysis-presets'
    );
    const q = query(presetsCollectionRef, where('profileIds', '==', []));
    const querySnapshot = await getDocs(q);

    const presets: PdfAnalysisPreset[] = querySnapshot.docs.map((doc) => {
      return { id: doc.id, ...doc.data() } as PdfAnalysisPreset;
    });

    // Store in app state.
    this.appStateService.updateState({
      presets: {
        ...this.currentState.presets,
        listPublic: presets,
      },
    });
  }

  async getPreset(presetId: string): Promise<PdfAnalysisPreset | null> {
    if (!presetId) {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate
          .get('errMsg.presetIdRequired')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      throw errorMessage;
    }

    const presetDocRef = doc(this.firestore, 'pdf-analysis-presets', presetId);
    const presetSnapshot = await getDoc(presetDocRef);

    if (presetSnapshot.exists()) {
      const currentPreset = {
        ...presetSnapshot.data(),
        id: presetSnapshot.id,
      } as PdfAnalysisPreset;

      this.appStateService.updateState({ currentPreset });
      return currentPreset;
    } else {
      return null;
    }
  }

  async createNewPreset(
    partialPreset: Partial<PdfAnalysisPreset>
  ): Promise<PdfAnalysisPreset> {
    const profile = this.currentState.currentProfile;
    const currentUser = this.currentState.currentUser;
    const user = currentUser?.displayName || currentUser?.email;

    if (!isObjectEmpty(profile)) {
      const newPreset = {
        emailAddressBoxLocation: {
          height: 0,
          topLeftX: 0,
          topLeftY: 0,
          width: 0,
        },
        isDefault: false,
        mobileNumberBoxLocation: {
          height: 0,
          topLeftX: 0,
          topLeftY: 0,
          width: 0,
        },
        nameDe: '',
        nameEn: '',
        nameFr: '',
        nameIt: '',
        postalAddressBoxLocation: {
          height: 25,
          topLeftX: 21,
          topLeftY: 55,
          width: 70,
        },
        requiresBlankFirstPage: false,
        whiteLineBoxLocation: {
          height: 0,
          topLeftX: 0,
          topLeftY: 0,
          width: 0,
        },
        profileIds: [],
        status: 'draft',
        createdAt: Timestamp.now(),
        createdBy: user,
        modifiedAt: Timestamp.now(),
        modifiedBy: user,
        subject: '',
        docType: 'invoice',
        isAddressValidationRequired: false,
        processType: 'single',
        ebillDueDate: null,
        emailTemplate: '<h1>Hello</h1>',
        emailSubject: 'Email Subject',
        emailAdditionalInfo: {
          from: {
            name: '',
            email: '',
          },
          replyTo: {
            name: '',
            email: '',
          },
          cc: {
            name: '',
            email: '',
          },
          bcc: {
            name: '',
            email: '',
          },
        },
        postalDeliveryTypeDomestic: 'b-post',
        postalDeliveryTypeInternational: 'standard',
        printMetadata: {
          color: 'bw',
          printType: 'duplex',
        },
        deleteType: 'pdf',
        orderName: '',
        orderNameDateVariable: false,
        orderNameTimeVariable: false,
        orderSubjectDateVariable: false,
        orderSubjectTimeVariable:false,
        ...partialPreset,
      } as PdfAnalysisPreset;

      const presetsCollectionRef = collection(
        this.firestore,
        'pdf-analysis-presets'
      );

      const docRef = await addDoc(presetsCollectionRef, newPreset);

      const currentPreset: PdfAnalysisPreset = {
        ...newPreset,
        id: docRef.id,
      };

      this.appStateService.updateState({ currentPreset });
      return currentPreset;
    } else {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate
          .get('errMsg.userProfileNotFound')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      throw errorMessage;
    }
  }

  async updatePreset(
    presetId: string,
    updatedData: Partial<PdfAnalysisPreset>
  ): Promise<void> {
    const currentUser = this.currentState.currentUser;
    const user = currentUser?.displayName || currentUser?.email;
    if (!presetId || isObjectEmpty(updatedData)) {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate
          .get('errMsg.presetIdUpdateDataRequired')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      throw errorMessage;
    }
    const presetDocRef = doc(this.firestore, 'pdf-analysis-presets', presetId);

    // Update the preset in Firestore with merge option to true
    await setDoc(
      presetDocRef,
      { ...updatedData, modifiedAt: Timestamp.now(), modifiedBy: user },
      { merge: true }
    );
  }

  async deletePreset(presetId: string): Promise<void> {
    // Ensure that you have a valid currentPreset ID to delete
    if (!presetId) {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate
          .get('errMsg.presetId-required')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      throw errorMessage;
    }
    // Get a reference to the currentPreset document in Firestore
    const presetDocRef = doc(this.firestore, 'pdf-analysis-presets', presetId);
    // Delete the currentPreset from Firestore
    await deleteDoc(presetDocRef);

    const presetsList = this.currentState.presets.list;
    presetsList.delete(presetId);
    this.appStateService.updateState({
      currentPreset: null,
      presets: {
        ...this.currentState.presets,
        list: presetsList,
      },
    });
  }

  async updateAllPresetsToNonDefault(): Promise<void> {
    const profileId = this.currentState.profileId;
    if (!profileId) {
      let errorMessage = '';
      this.translate.get('errMsg.profIdRequired').subscribe((res: string) => {
        errorMessage = res;
      });

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      throw errorMessage;
    }

    const presetsCollectionRef = collection(
      this.firestore,
      'pdf-analysis-presets'
    );
    const q = query(
      presetsCollectionRef,
      where('profileIds', 'array-contains', profileId),
      where('isDefault', '==', true)
    );
    const querySnapshot = await getDocs(q);

    const updateBatch = writeBatch(this.firestore);

    for (const docSnapshot of querySnapshot.docs) {
      const presetDocRef = doc(
        this.firestore,
        `pdf-analysis-presets/${docSnapshot.id}`
      );
      updateBatch.update(presetDocRef, { isDefault: false });
    }

    await updateBatch.commit();
  }

  ngOnDestroy(): void {
    this.appStateSubscription?.unsubscribe();
  }
}
