import {
  Directive,
  Input,
  TemplateRef,
  ViewContainerRef,
  HostListener,
  ElementRef,
  NgZone,
  Inject,
} from '@angular/core';
import {
  Overlay,
  OverlayRef,
  OverlayPositionBuilder,
} from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { FocusTrap, FocusTrapFactory } from '@angular/cdk/a11y';
import { DOCUMENT } from '@angular/common';

@Directive({
  selector: '[appHoverPopover]',
  exportAs: 'appHoverPopover',
})
export class HoverPopoverDirective {
  @Input('appHoverPopover') contentTemplate!: TemplateRef<any>;
  // Overlay reference
  private overlayRef!: OverlayRef;

  // Flags to detect if the host or the overlay itself is hovered
  private isHostHovered = false;
  private isOverlayHovered = false;

  // Timeout to delay the hiding of the overlay
  private hideTimeout?: any;

  // Focus trap to ensure focus stays within the overlay
  private focusTrap?: FocusTrap;

  // Bindings for event listeners
  private boundOnMouseMove: any;
  private boundOverlayMouseEnter: any;
  private boundOverlayMouseLeave: any;

  // Flag to indicate manual closure of overlay
  private manualClose = false;

  constructor(
    private overlay: Overlay,
    private overlayPositionBuilder: OverlayPositionBuilder,
    private viewContainerRef: ViewContainerRef,
    private elementRef: ElementRef,
    private ngZone: NgZone,
    private focusTrapFactory: FocusTrapFactory,
    @Inject(DOCUMENT) private document: Document
  ) {
    // Initializing event listener bindings
    this.boundOnMouseMove = this.onMouseMove.bind(this);
    this.boundOverlayMouseEnter = this.overlayMouseEnter.bind(this);
    this.boundOverlayMouseLeave = this.overlayMouseLeave.bind(this);
  }

  // Display overlay when the host element is hovered
  @HostListener('mouseenter')
  onMouseEnter() {
    this.isHostHovered = true;
    this.show();
  }

  // Hide overlay when the mouse leaves the host element
  @HostListener('mouseleave')
  onMouseLeave() {
    this.isHostHovered = false;
    this.hide();
  }

  // Hide overlay when the host element loses focus
  @HostListener('focus')
  onFocus() {
    if (this.manualClose) {
      this.manualClose = false;
      return;
    }
    this.isHostHovered = true;
    this.show();
  }

  // Hide overlay when the host element loses focus
  @HostListener('blur')
  onBlur() {
    this.isHostHovered = false;
    this.hide();
  }

  // Check if the mouse is outside the bounds of the overlay
  private onMouseMove(event: MouseEvent) {
    if (this.overlayRef) {
      const rect = this.overlayRef.overlayElement.getBoundingClientRect();
      if (
        event.clientX <= rect.left ||
        event.clientX >= rect.right ||
        event.clientY <= rect.top ||
        event.clientY >= rect.bottom
      ) {
        this.isOverlayHovered = false;
        this.ngZone.run(() => this.hide());
      }
    }
  }

  // Mark the overlay as hovered when the mouse enters it
  private overlayMouseEnter() {
    this.isOverlayHovered = true;
    this.clearHideTimeout();
  }

  // Mark the overlay as not hovered when the mouse leaves it
  private overlayMouseLeave() {
    this.isOverlayHovered = false;
    this.hide();
  }

  // Handle Tab and Shift+Tab navigation within the overlay for accessibility
  private onKeydown(event: KeyboardEvent) {
    const focusableElements = this.overlayRef.overlayElement.querySelectorAll(
      'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])'
    );
    const firstFocusableEl: HTMLElement = focusableElements[0] as HTMLElement;
    const lastFocusableEl: HTMLElement = focusableElements[
      focusableElements.length - 1
    ] as HTMLElement;

    // Handling Tab key for the last focusable element in the overlay
    if (
      event.key === 'Tab' &&
      !event.shiftKey &&
      document.activeElement === lastFocusableEl
    ) {
      event.preventDefault();
      this.hideOverlay(); // Close the overlay
      // Optionally, you can set focus to the next appropriate element or let natural tabbing flow take over
    }

    // Handling Shift+Tab key combination for the first focusable element in the overlay
    if (
      event.key === 'Tab' &&
      event.shiftKey &&
      document.activeElement === firstFocusableEl
    ) {
      event.preventDefault();
      this.hideOverlay(); // Close the overlay
      // Set focus to the preceding element (e.g., the info icon or any other preceding focusable element)
      this.elementRef.nativeElement.focus();
    }
  }

  // Logic to show the overlay
  private show() {
    this.clearHideTimeout();

    if (!this.overlayRef) {
      const positionStrategy = this.overlayPositionBuilder
        .flexibleConnectedTo(this.elementRef)
        .withPositions([
          {
            originX: 'end',
            originY: 'bottom',
            overlayX: 'end',
            overlayY: 'top',
            offsetX: 15,
            offsetY: -10,
          },
        ]);

      this.overlayRef = this.overlay.create({ positionStrategy });

      // Listen for mouseenter, mouseleave, focus, and blur on the overlay
      this.ngZone.runOutsideAngular(() => {
        this.overlayRef.overlayElement.addEventListener('mouseenter', () => {
          this.isOverlayHovered = true;
          this.clearHideTimeout();
        });

        this.overlayRef.overlayElement.addEventListener('mouseleave', () => {
          this.isOverlayHovered = false;
          this.hide();
        });

        // Add focus and blur event listeners for the overlay
        this.overlayRef.overlayElement.addEventListener(
          'focus',
          () => {
            this.isOverlayHovered = true;
            this.clearHideTimeout();
          },
          true
        ); // true indicates the listener is in capturing phase

        this.overlayRef.overlayElement.addEventListener(
          'blur',
          () => {
            this.isOverlayHovered = false;
            this.hide();
          },
          true
        ); // true indicates the listener is in capturing phase

        // Make sure you only have one listener active for 'mousemove' event
        this.document.removeEventListener('mousemove', this.boundOnMouseMove);
        this.document.addEventListener('mousemove', this.boundOnMouseMove);
        this.overlayRef.overlayElement.addEventListener(
          'keydown',
          this.onKeydown.bind(this)
        );
      });
    }

    // If the host or overlay is hovered and no portal is already attached, attach the portal
    if (
      (this.isHostHovered || this.isOverlayHovered) &&
      !this.overlayRef.hasAttached()
    ) {
      this.overlayRef.attach(
        new TemplatePortal(this.contentTemplate, this.viewContainerRef)
      );
    }

    // Create and enable a focus trap for the overlay
    if (this.overlayRef) {
      this.focusTrap = this.focusTrapFactory.create(
        this.overlayRef.overlayElement
      );
      this.focusTrap.focusInitialElementWhenReady();
    }
  }

  // Logic to hide the overlay
  private hide() {
    this.setHideTimeout();

    this.ngZone.runOutsideAngular(() => {
      this.document.addEventListener('mousemove', this.boundOnMouseMove);
    });

    if (this.focusTrap) {
      this.focusTrap.destroy();
    }
  }

  // Set a timeout to delay hiding of the overlay
  private setHideTimeout() {
    this.hideTimeout = setTimeout(() => {
      if (!this.isHostHovered && !this.isOverlayHovered) {
        this.overlayRef?.detach();
      }
    }, 200);
  }

  // Clear any set hide timeouts
  private clearHideTimeout() {
    if (this.hideTimeout !== undefined) {
      clearTimeout(this.hideTimeout);
      this.hideTimeout = undefined;
    }
  }

  // Hide the overlay and clean up event listeners
  public hideOverlay() {
    if (this.overlayRef) {
      this.overlayRef.overlayElement.removeEventListener(
        'mouseenter',
        this.boundOverlayMouseEnter
      );
      this.overlayRef.overlayElement.removeEventListener(
        'mouseleave',
        this.boundOverlayMouseLeave
      );
      this.overlayRef.overlayElement.removeEventListener(
        'focus',
        this.boundOverlayMouseEnter,
        true
      );
      this.overlayRef.overlayElement.removeEventListener(
        'blur',
        this.boundOverlayMouseLeave,
        true
      );
      this.overlayRef.overlayElement.removeEventListener(
        'keydown',
        this.onKeydown.bind(this)
      );
    }

    this.manualClose = true;
    this.overlayRef?.detach();
    this.isHostHovered = false;
    this.isOverlayHovered = false;
    this.elementRef.nativeElement.focus();
    this.document.removeEventListener('mousemove', this.boundOnMouseMove);
  }
}
