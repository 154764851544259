import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { merge, fromEvent, map, Observable, Observer, BehaviorSubject } from 'rxjs';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class NetworkService {
  private previousStatus: boolean | null = null;
  toastId: number | undefined = undefined;

  private _isOverlayVisible = new BehaviorSubject<boolean>(false);
  isOverlayVisible$ = this._isOverlayVisible.asObservable();

  isOnline$: Observable<boolean> = merge(
    fromEvent(window, 'online').pipe(map(() => true)),
    fromEvent(window, 'offline').pipe(map(() => false)),
    new Observable((sub: Observer<boolean>) => {
      sub.next(navigator.onLine);
      sub.complete();
    })
  );

  constructor(private toastr: ToastrService, private translate: TranslateService) {
    this.isOnline$.subscribe((isOnline) => {
      if (this.previousStatus === false && isOnline) {
        let errorMessage = '';
        this.translate.get('errMsg.backOnline').subscribe((res: string) => {
          errorMessage = res;
        });
        this.toastr.success(errorMessage, 'Online', { timeOut: 3000 });
        this.toastr.clear(this.toastId);
        this.hideOverlay();
      }
      this.previousStatus = isOnline;
      if (!isOnline) {
        let errorMessage = '';
        this.translate.get('errMsg.offline').subscribe((res: string) => {
          errorMessage = res;
        });
        const toast = this.toastr.error(errorMessage, 'Offline', { timeOut: 0 });
        this.toastId = toast.toastId;
        this.showOverlay();
      }
    });
  }

  showOverlay() {
    this._isOverlayVisible.next(true);
  }

  hideOverlay() {
    this._isOverlayVisible.next(false);
  }

  get onlineStatus$(): Observable<boolean> {
    return this.isOnline$;
  }
}
