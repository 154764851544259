<div *ngIf="!deliveryTypeLineItems.length" class="loader-wrapper">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
</div>

<ng-container *ngIf="deliveryTypeLineItems.length">
    <table mat-table [dataSource]="deliveryTypeLineItems" class="full-width-table">
        <ng-container [matColumnDef]="column" *ngFor="let column of columns">
            <th mat-header-cell *matHeaderCellDef
                [ngClass]="{'centered': column === 'typeCount'}">
                {{ 'table.' + columnDisplayNameMap[column] | translate}}
            </th>
            <td mat-cell *matCellDef="let item" class="border-bottom"
                [ngClass]="{'centered': column === 'typeCount'}">
                {{ getDisplayValue(column, item[column]) }}
            </td>

            <ng-template matFooterCellDef *ngIf="isSpecificColumn(column, 'selectedChannel')">
                <td class="p-x-1  bold-text border-bottom" colspan="2">{{"table.Total" | translate}}</td>
            </ng-template>
            <ng-template matFooterCellDef *ngIf="isSpecificColumn(column, 'typeCount')">
                <td class="p-x-1 bold-text centered border-bottom"> {{ totalNumberOfRecipients }}</td>
            </ng-template>
            <ng-template matFooterCellDef *ngIf="isSpecificColumn(column, 'total')">
                <!-- <td class="p-x-1 bold-text right border-bottom"> {{ totalAmountInCents | number:'1.2-2' }}</td> -->
            </ng-template>
        </ng-container>

        <tr mat-header-row *matHeaderRowDef="columns"></tr>
        <tr mat-row *matRowDef="let row; columns: columns"></tr>
        <tr mat-footer-row *matFooterRowDef="footerColumns"></tr>
    </table>
</ng-container>