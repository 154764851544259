import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-subject-icon',
  templateUrl: './subject-icon.component.html',
  styleUrls: ['./subject-icon.component.scss'],
})
export class SubjectIconComponent {
  @Input() subjectInfo: { subject: string; isSubjectOverride: boolean } = {
    subject: 'n/a',
    isSubjectOverride: false,
  };
}
