import { Component, Input, OnChanges } from '@angular/core';

@Component({
  selector: 'app-pdf-results-process-indicator',
  templateUrl: './pdf-results-process-indicator.component.html',
  styleUrls: ['./pdf-results-process-indicator.component.scss'],
})
export class PdfResultsProcessIndicatorComponent implements OnChanges {
  @Input() status:
    | 'CHANNEL DETERMINATION'
    | 'PDF SPLITTING'
    | 'MESSAGE PRODUCTION' = 'CHANNEL DETERMINATION';
  @Input() progressPercentage: number = 0;
  @Input() numMessages: number = 0;
  @Input() currentMessageNum: number = 0;

  stepColor: string[] = ['', ''];
  showStepBar = false;

  ngOnChanges(): void {
    this.updateStepColors();
    this.updateProgressBar();
  }

  private updateStepColors(): void {
    this.stepColor = [
      this.status === 'PDF SPLITTING' ? 'orange' : 'green',
      this.status === 'MESSAGE PRODUCTION' ? 'orange' : 'grey',
    ];
  }

  private updateProgressBar(): void {
    if (this.status === 'CHANNEL DETERMINATION') {
      this.showStepBar = false;
    } else {
      this.showStepBar = true;
    }
  }
  getStatusWidth(status: string): string {
    if (status === 'MESSAGE PRODUCTION') {
      return '80px';
    } else {
      return '50px';
    }
  }
}
