<ng-container *ngIf="order">
    <mat-accordion class="order-item" [togglePosition]="'before'" multi>
        <mat-expansion-panel [disabled]="isExpandDisabled">
            <mat-expansion-panel-header class="custom-expansion-header">
                <mat-panel-title>
                    <div class="panel-title-wrapper">
                        <span class="date-text">
                            {{ order.createdAt ? (order.createdAt.toDate() | date: "medium") : 'Date not available' }} {{' ' + ('overview.by' | translate) + ' ' }} {{ order.userLoginSnapshot }}  
                        </span>
                        <div class="order-name-wrapper">
                            <span class="order-name-text" appEllipsisTooltip [matTooltip]="order.name">{{order.name}}</span>
                        </div>
                        <span class="subject-text" appEllipsisTooltip [matTooltip]="order.subject">{{order.subject}}</span>
                    </div>
                </mat-panel-title>
                <mat-panel-description class="panel-description" [style.justify-content]="!isDelivered ? 'space-between' : 'flex-end'">
                    <ng-container *ngIf="statusArray.includes(order.status) || statusProcessing.includes(order.status)">
                        <div class="recipients-count-wrapper">
                            <span class="recipients-label">{{"pages.orderItem.recipients" | translate}}</span>
                            <span class="recipients-count-text" [ngStyle]="{'color': order.numRecipients === 0 ? 'grey' : 'inherit'}">
                                {{ order.numRecipients === 0 ? '---' : (order.numRecipients | number:'1.0-0') }}
                            </span>
                        </div> 
             
                        <div class="status-btn" [ngStyle]="getDynamicStyle()">
                            <div class="status-text">
                                <span [matTooltip]="tooltipText()">
                                    <ng-container *ngIf="order.status === 'Draft'; else condition2">
                                        {{"overview.readyToProceed" | translate}}
                                    </ng-container>
                                    
                                    <ng-template #condition2>
                                        <ng-container *ngIf="statusProcessing.includes(order.status); else condition3">
                                            {{ 'status.' + order.status | translate }}
                                        </ng-container>
                                    </ng-template>

                                    <ng-template #condition3>
                                        {{ 'status.' + order.status | translate }}
                                    </ng-template>

                                    <ng-container *ngIf="order.status === 'PDFPageLimit'">
                                        <app-page-limit-flag [order]="order"></app-page-limit-flag>
                                    </ng-container>
                                </span>
                            </div>                          
                        </div>

                        <div *ngIf="!isDelivered" class="action-btn-process-indicator-wrapper">
                            <ng-container *ngIf="order.status === 'PDFAnalysis'">
                                <ng-container *ngIf="order | pdfAnalysisProgress as progressIndicatorData">
                                    <app-pdf-analysis-process-indicator
                                        [analysisStatus]="progressIndicatorData.analysisStatus"
                                        [prepared]="progressIndicatorData.prepared"

                                        [preparingProgressPercentage]="progressIndicatorData.preparingProgressPercentage"
                                        [preparingDocumentCurrentNum]="order.pdfAnalysisStatus?.preparingDocumentCurrentNum"
                                        [preparingDocumentCount]="order.pdfAnalysisStatus?.preparingDocumentCount"

                                        [detectingProgressPercentage]="progressIndicatorData.detectingProgressPercentage"
                                        [detectingCurrentPageNum]="order.pdfAnalysisStatus?.detectingCurrentPageNum"
                                        [detectingNumPages]="order.pdfAnalysisStatus?.detectingNumPages"
                                        
                                        [segregatingProgressPercentage]="progressIndicatorData.segregatingProgressPercentage"
                                        [segregatingCurrentPageNum]="order.pdfAnalysisStatus?.segregatingCurrentPageNum"
                                        [segregatingNumPages]="order.pdfAnalysisStatus?.segregatingNumPages"

                                        [validatingProgressPercentage]="progressIndicatorData.validatingProgressPercentage"
                                        [validationCurrentMessageNum]="order.pdfAnalysisStatus?.validationCurrentMessageNum"
                                        [validationNumMessages]="order.pdfAnalysisStatus?.validationNumMessages"
                                    ></app-pdf-analysis-process-indicator>
                                </ng-container>
                            </ng-container>
                            <ng-container *ngIf="['ChannelDetermination', 'PdfSplitting', 'MessageProduction'].includes(order.status)">
                                <ng-container *ngIf="order | pdfResultsProgress as progressIndicatorData">
                                    <app-pdf-results-process-indicator
                                        [status]="progressIndicatorData.processStatus"
                                        [currentMessageNum]="progressIndicatorData.currentCount"
                                        [numMessages]="progressIndicatorData.totalCount"
                                        [progressPercentage]="progressIndicatorData.ProgressPercentage"
                                    ></app-pdf-results-process-indicator>
                                </ng-container>
                            </ng-container>

                            <button 
                                *ngIf="['Draft'].includes(order.status)"
                                mat-raised-button color="primary"  
                                class="status-btn" 
                                (click)="handleOnEditOrder(order.id)"
                            >
                                {{'overview.buttonLabel.' + getButtonLabel() | translate }}
                            </button>
                        
                            <button 
                                *ngIf="order.status === 'PDFMismatch'"
                                mat-raised-button color="primary"  
                                class="status-btn" 
                                (click)="handleOnAdjust(order.id)"
                            >
                                {{'overview.buttonLabel.' + getButtonLabel() | translate }}
                            </button>
                        
                            <button 
                                *ngIf="['PDFPageLimit'].includes(order.status)"
                                mat-raised-button color="primary"  
                                class="status-btn" 
                                (click)="handleonPageLimit(order.id)"
                            >
                                {{'overview.buttonLabel.' + getButtonLabel() | translate }}
                            </button>
                        </div>
                    </ng-container>
                        
                    <div class="actions-btn-wrapper">
                        <div class="custom-vertical-divider"></div>
                        <button 
                            *ngIf="order.status !== 'Done'"
                            mat-icon-button (click)="handleOnDelete(order.id)"
                            [disabled]="['ChannelDetermination', 'PdfSplitting', 'MessageProduction', 'PDFAnalysis', 'InProduction'].includes(order.status)"
                            [matTooltip]="'pages.orderItem.delete' | translate"
                        >
                            <div class="button-content">
                                <mat-icon class="custom-icon-size">delete</mat-icon>
                            </div>
                        </button>

                        <button 
                            *ngIf="order.status === 'Done'"
                            mat-icon-button (click)="handleOnViewOrderSummary(order.id)"
                            [disabled]="['ChannelDetermination', 'PdfSplitting', 'MessageProduction', 'PDFAnalysis'].includes(order.status)"
                            [matTooltip]="'pages.orderItem.viewSummary' | translate"
                        >
                            <div class="button-content">
                                <mat-icon class="custom-icon-size">remove_red_eye</mat-icon>
                            </div>
                        </button>
                    </div>
      
                </mat-panel-description>
            </mat-expansion-panel-header>

            <table 
                style="
                    width: 100%;
                    border-collapse: collapse;
                    border-spacing: 0;
                "
            >
                <thead style="height: 48px; background-color: #F4F4F4; font-size: 12px;">
                    <tr>
                        <th style="text-align: start; padding-left: 20px;">{{"table.Delivery Type" | translate}}</th>
                        <th style="text-align: start;">{{"table.Print" | translate}}</th>
                        <!-- <th style="text-align: start;">{{"table.Envelope" | translate}}</th> -->
                        <!-- <th style="text-align: end;">{{"table.Price" | translate}}</th> -->
                        <th style="text-align: center;">{{"table.Recipients" | translate}}</th>
                        <!-- <th style="text-align: end; padding-right: 20px;">{{"table.Amount" | translate}}</th> -->
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let item of order['deliveryTypeLineItems'];" style="height: 48px;">
                        <td style="text-align: start; padding-left: 20px;">{{ ('table.printAndSend' | translate) }}</td>
                        <td style="text-align: start;">
                            {{ ('presets.create.' + order.printMetadata.color | translate) + ' / ' + ('presets.create.' + order.printMetadata.printType | translate) }}
                        </td>
                        <!-- <td style="text-align: start;">{{ item.envelope }}</td> -->
                        <!-- <td style="text-align: end;">CHF {{ item.priceCents | number:'1.2-2' }}</td> -->
                        <td style="text-align: center;">{{ item.typeCount }}</td>
                        <!-- <td style="text-align: end; padding-right: 20px;">
                            CHF {{ item.total | number:'1.2-2' }}
                        </td> -->
                    </tr>
                </tbody>
            </table>

            <ng-container *ngIf="order.status === 'Done'">
                <mat-accordion  [togglePosition]="'before'" multi>
                  <mat-expansion-panel (opened)="onMessagesPanelExpanded()">
                    <mat-expansion-panel-header>
                      <mat-panel-title>{{"table.messages" | translate}}</mat-panel-title>
                    </mat-expansion-panel-header>
                    <mat-panel-description>
                        <app-order-item-messages 
                            style="width: 100%;"
                            *ngIf="isMessagesExpanded" 
                            [orderId]="order.id"
                        ></app-order-item-messages>
                    </mat-panel-description>
                  </mat-expansion-panel>
                </mat-accordion>
            </ng-container>

        </mat-expansion-panel>
    </mat-accordion>
</ng-container>