import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, Scroll } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import {
  combineLatest,
  distinctUntilChanged,
  EMPTY,
  of,
  Subject,
  switchMap,
  takeUntil,
} from 'rxjs';
import { AppStateService } from '../../../../services/app-state.service';
import { OrderType } from '../../../../state/app-state';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-order-step-indicator',
  templateUrl: './order-step-indicator.component.html',
  styleUrls: ['./order-step-indicator.component.scss'],
})
export class OrderStepIndicatorComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private isDocUpload: boolean = false;
  allStepsCompleted = false;

  stepsTemplate = [
    { number: 1, caption: 'Upload Documents' },
    { number: 2, caption: 'Settings' },
    { number: 3, caption: 'Address Check' },
    { number: 4, caption: 'Delivery Type' },
    { number: 5, caption: 'Summary' },
    { number: 6, caption: 'Completed' },
  ];

  steps: any[] = [];

  get showStepIndicator() {
    this.isDocUpload = this.router.url.includes('doc-upload');
    const isCompleted = this.router.url.includes('completed');
    const isDraft = this.stateService.appState$.value.currentEditOrder?.status === 'Draft';
    const isMismatch =  this.stateService.appState$.value.currentEditOrder?.status === 'PDFMismatch';

    return isDraft || isCompleted || this.isDocUpload || isMismatch;
  }

  constructor(
    public stateService: AppStateService,
    private router: Router,
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private translate: TranslateService,
  ) {}

  async ngOnInit() {
    const onNavigationEnd$ = this.router.events.pipe(
      switchMap((x) =>
        x instanceof NavigationEnd || x instanceof Scroll ? of(x) : EMPTY
      ),
      distinctUntilChanged((a: NavigationEnd | Scroll, b: NavigationEnd | Scroll) => {
        if (a instanceof NavigationEnd && b instanceof NavigationEnd) {
          return a.url === b.url;
        }
        return false;
      })
    );

    combineLatest({
      navigationEnd: onNavigationEnd$,
      appState: this.stateService.appState$,
    })
      .pipe(takeUntil(this.destroy$))
      .subscribe(({ navigationEnd, appState }) => {
        this.isDocUpload = this.router.url.includes('doc-upload');
    
        if (appState?.currentEditOrder || this.isDocUpload) {
          const currentOrderEditStep = !this.isDocUpload
            ? appState?.currentEditOrder?.currentEditStep || 'doc-upload'
            : 'doc-upload';
          this.populateSteps(currentOrderEditStep);
        }
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private populateSteps(currentStep: OrderType['currentEditStep']) {
    const stepOrder = [
      'doc-upload',
      'order-basics',
      'address-check',
      'channel-check',
      'summary',
      'completed',
    ];
    const currentIndex = stepOrder.indexOf(currentStep);

    this.steps = this.stepsTemplate.map((step, index) => {
      if (index < currentIndex) return { ...step, status: 'past' };
      if (index === currentIndex) return { ...step, status: 'active' };
      return { ...step, status: 'future' };
    });

    this.allStepsCompleted = currentStep === 'completed';
  }

  
  translateStepCaption(caption: string): string {
    return this.translate.instant(`stepCaptions.${caption}`);
  }
}