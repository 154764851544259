import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

import { AppStateService } from '../../../../services/app-state.service';
import { OrderService } from '../../../../services/order.service';
import { AppState, OrderType } from '../../../../state/app-state';
import { isObjectEmpty } from '../../utils/helper';

@Component({
  selector: 'app-order-name-subject-sub-header',
  templateUrl: './order-name-subject-sub-header.component.html',
  styleUrls: ['./order-name-subject-sub-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OrderNameSubjectSubHeaderComponent implements OnInit {
  currentEditOrder: OrderType | null = null;
  form: FormGroup = new FormGroup({});
  isEditMode: boolean = false;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    public stateService: AppStateService,
    public orderService: OrderService,
  ) {}

  ngOnInit() {
    this.stateService.appState$.subscribe((appState: AppState) => {
      if (!isObjectEmpty(appState?.currentEditOrder)) {
        this.currentEditOrder = appState?.currentEditOrder;
        this.initializeForm(this.currentEditOrder);
      } else {
        this.initializeForm();
      }
    });
  }

  initializeForm(currentEditOrder: OrderType | null = null) {
    this.form = new FormGroup({
      name: new FormControl<string | null>(currentEditOrder?.name || '', [
        Validators.required,
      ]),
      subject: new FormControl<string | null>(currentEditOrder?.subject || '', [
        Validators.required,
      ]),
    });

    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  onToggleEdit() {
    this.isEditMode = !this.isEditMode;
  }

  onClickSave() {
    this.changeDetectorRef.detectChanges();
    this.isEditMode = false;
    const updatedOder = {
      ...this.currentEditOrder,
      ...this.form.value,
    } as Partial<OrderType>;
    this.orderService.updateOrder(updatedOder);
  }

  onCancel() {
    this.isEditMode = false;
    this.initializeForm(this.currentEditOrder);
  }
}
