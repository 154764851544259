<div class="order-item-messages">
    <div *ngIf="!(order?.pdfAnalysisResults || []).length" class="loader-wrapper">
        <mat-progress-bar class="loader" mode="indeterminate"></mat-progress-bar>
    </div>
    <table *ngIf="order?.pdfAnalysisResults" mat-table [dataSource]="dataSource" matSort>
        <ng-container *ngFor="let column of columns" [matColumnDef]="column.columnDef">
            <th 
                style="padding: 0 10px;"
                mat-header-cell *matHeaderCellDef mat-sort-header
                [ngClass]="['channel', 'subject', 'delivery'].includes(column.columnDef) ? 'centered' : ''"
            >
                {{'table.' + column.header | translate}} 
            </th>
            <td 
                mat-cell *matCellDef="let row"
                [ngClass]="['channel', 'subject', 'delivery'].includes(column.columnDef) ? 'centered' : ''"
            >
                <ng-container *ngIf="shouldDisplayColumn(column.columnDef); else block">
                    <ng-container *ngIf="column.columnDef !== 'recipient'">
                        {{ column.cell(row) }}
                    </ng-container>
                    <ng-container *ngIf="column.columnDef === 'recipient'">
                        <ng-container *ngIf="column.cell(row) as recipientInfo">
                            <div *ngIf="isRecipientType(recipientInfo)">
                                <div>{{recipientInfo.recipient}}</div>
                                <div>{{recipientInfo.email}}</div>
                            </div>
                        </ng-container>
                    </ng-container>
                </ng-container>
                <ng-template #block>
                    <app-chosen-channel 
                        *ngIf="column.columnDef === 'channel'"
                        [chosenChannel]="column.cell(row)"
                    ></app-chosen-channel>
                    <app-subject-icon 
                        *ngIf="column.columnDef === 'subject'" 
                        [subjectInfo]="column.cell(row)"
                    ></app-subject-icon>
                    <app-klara-status-flag
                        *ngIf="column.columnDef === 'delivery'" 
                        [pdfAnalysisMessage]="column.cell(row)"
                    ></app-klara-status-flag>
                    <ng-container *ngIf="column.columnDef === 'country'">
                        <span 
                            *ngIf="row?.address?.country; else noCountry"
                            [ngClass]="'flag-icon fi fi-' + (row?.address?.countryCode | lowercase)" 
                            matTooltip="{{row?.address?.country}}"
                        ></span>
                        <ng-template #noCountry>n/a</ng-template>
                    </ng-container>
                </ng-template>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>