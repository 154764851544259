import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  inject,
  OnInit,
  OnDestroy,
  NgZone
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  Storage,
  ref as storageRef,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { MatButtonToggleChange } from '@angular/material/button-toggle';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { Firestore, Timestamp } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { TranslateService } from '@ngx-translate/core';
import { onSnapshot, doc } from 'firebase/firestore';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

import { PDFLimitDialogComponent } from '../../shared/components/app-pdf-limit-dialog.component';
import { WarningDialogComponent } from '../../shared/components/app-warning-dialog.component';
import { OrderType, AppState, OrderFile, LoadingState } from '../../../state/app-state';
import { isObjectEmpty, getCurrentDateTimeString } from './../../shared/utils/helper';
import { AppStateService } from '../../../services/app-state.service';
import { StorageService } from '../../../services/storage.service';
import { OrderService } from '../../../services/order.service';


interface PubsubResData {
  status: string;
  message?: string;
}

@Component({
  selector: 'app-doc-upload',
  templateUrl: './doc-upload.component.html',
  styleUrls: ['./doc-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DocUploadComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();
  private storage: Storage = inject(Storage);
  private currentState!: AppState;
  private currentOrder: OrderType | null = null;
  private profileId: string = '';
  private isFirstLoad: boolean = false;
  private skipResetCurrentOrder: boolean = false;
  private tempPdfsCount: number = 0;
  private tempPdfsPageCount: number = 0;
  private pdfsCount: number = 0;
  private pdfsPageCount: number = 0;
  // Represent the main PDF file names
  uploadedPdfFileNames = new Map<string, { id: string, filename: string, name: string, processType: string, pageCount: number, errorMessage?: string }>();
  uploadedAttachmentFileNames= new Map<string, { filename: string, name: string, attachmentType: string }>();
  pdfUploadProgressMap = new Map<string, { uploadProgress: number, isUploading: boolean }>();
  attachmentUploadProgressMap = new Map<string, { uploadProgress: number, isUploading: boolean }>();
  isPdfDragOver: boolean = false;
  isAttachmentDragOver: boolean = false;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private auth: AngularFireAuth,
    private changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone,
    private dialog: MatDialog,
    private firestore: Firestore,
    public storageService: StorageService,
    public stateService: AppStateService,
    public orderService: OrderService,
    private translate: TranslateService,
  ) {}

  async ngOnInit() {
    this.subscriptions.add(
      this.route.parent?.paramMap.subscribe(async (params) => {
        const orderId = params.get('orderId');
        if (orderId && orderId !== 'null') {
          this.stateService.startLoading(LoadingState.OrderLoading);
          await this.orderService
            .getOrder(orderId)
            .then(() => {
              this.stateService.stopLoading(LoadingState.OrderLoading);
            });
        }
      })
    );
    
    this.subscriptions.add(
      this.stateService.appState$.subscribe(async (appState: AppState) => {
        this.currentState = appState;
        if (!isObjectEmpty(appState?.currentEditOrder) && !this.isFirstLoad) {
          this.currentOrder = appState.currentEditOrder!;
          const messagePdfs = appState?.currentEditOrder?.messagePdfs || [];
          const attachmentPdfs = appState?.currentEditOrder?.attachmentPdfs || [];
          this.isFirstLoad = true;
  
          if (messagePdfs.length) {
            messagePdfs.forEach((file) => {
              const { id, name, filename, processType, pageCount, errorMessage } = file;
              this.pdfUploadProgressMap.set(filename, {
                uploadProgress: 100,
                isUploading: false,
              });
              this.uploadedPdfFileNames.set(filename, { id, filename, name, processType: processType!, pageCount, errorMessage: errorMessage || '' });
              const pdfPageCount = processType === 'single' ? 1 : pageCount;
              this.pdfsCount = this.pdfsCount + 1;
              this.pdfsPageCount = this.pdfsPageCount + pdfPageCount;
            });
          }
  
          if (attachmentPdfs.length) {
            attachmentPdfs.forEach((file) => {
              const { name, filename, attachmentType } = file;
              this.attachmentUploadProgressMap.set(filename, {
                uploadProgress: 100,
                isUploading: false,
              });
              this.uploadedAttachmentFileNames.set(filename, {
                filename,
                name,
                attachmentType: attachmentType!
              });
            });
          }
        }

        if (
          !isObjectEmpty(appState.currentProfile) &&
          this.profileId !== appState?.currentProfile?.id
        ) {
          this.profileId = appState.currentProfile!.id;
        }
      })
    );
  }

  async createPartialOrder() {
    const partialOrder: Partial<OrderType> = {
      currentEditStep: 'doc-upload',
      name: `${this.translate.instant('overview.newOrder')} ${getCurrentDateTimeString()}`,
      subject: `${this.translate.instant('pages.orderBasics.subject')}`,
      userLoginSnapshot: this.currentState?.currentUser?.displayName! || this.currentState?.currentUser?.email!
    };

    try {
      this.stateService.startLoading(LoadingState.OrderLoading);
      this.currentOrder = await this.orderService.createNewOrder(partialOrder);
      this.stateService.stopLoading(LoadingState.OrderLoading);
    } catch (error) {
      console.error('Failed to create new order:', error);
    }
  }

  get uploadedPdfFileNamesArray(): { filename: string; name: string; processType: string; pageCount: number; errorMessage?: string }[] {
    return Array.from(this.uploadedPdfFileNames.values());
  }

  get uploadedAttachmentFileNamesArray(): { filename: string; name: string; attachmentType: string; }[] {
    return Array.from(this.uploadedAttachmentFileNames.values());
  }

  async onNext() {
    this.stateService.startLoading(LoadingState.OrderLoading);
    const currentEditOrder = this.currentState.currentEditOrder!;
    const userId = this.currentState?.currentUser?.uid;
    const orderId = currentEditOrder.id;

    const updatedOrderType = {
      ...currentEditOrder,
      currentEditStep: 'order-basics',
    } as OrderType;

    try {
      const publishUfaOrderInitializer = httpsCallable(
        getFunctions(undefined, 'europe-west6'),
        'publishUfaOrderInitializer'
      );
      const response = await publishUfaOrderInitializer({
        orderId,
        userId,
        profileId: this.profileId,
      });
      const pubsubResData = response.data as PubsubResData;

      if (pubsubResData.status === 'success') {
        const presetRef = doc(this.firestore, `orders/${orderId}`);
        const unsubscribe = onSnapshot(presetRef, async (docSnapshot) => {
          const orderData = docSnapshot.data();
          if (
            orderData?.['status'] !== 'Error' &&
            orderData?.['orderInitializerProcessing'] === 'DONE'
          ) {
            unsubscribe();
            this.skipResetCurrentOrder = true;
            this.stateService.stopLoading(LoadingState.OrderLoading);
            await this.orderService.updateOrder(updatedOrderType);
            this.router.navigate([
              this.profileId,
              'orders',
              orderId,
              'edit',
              'order-basics',
            ]);
          } else {
            if (orderData?.['status'] === 'Error') {
              unsubscribe();
              this.stateService.stopLoading(LoadingState.OrderLoading);
              this.router.navigate([this.profileId, 'orders']);
            }
          }
        });
      } else {
        console.error(
          'Error occurs on publish UFA Order Initializer',
          pubsubResData.message
        );
        this.stateService.stopLoading(LoadingState.OrderLoading);
      }
    } catch (error) {
      console.error(
        'Error occurs on publish UFA Order Initializer',
        error
      );
      this.stateService.stopLoading(LoadingState.OrderLoading);
    }
  }

  async removeDoc(filename: string, uploadType: 'pdf' | 'attachment' = 'pdf') {
    this.stateService.startLoading(LoadingState.OrderLoading);
    await this.storageService.deleteFile(filename, uploadType).then(() => {
      this.stateService.stopLoading(LoadingState.OrderLoading);
    });
    // Update local state.
    if (uploadType === 'attachment') {
      this.attachmentUploadProgressMap.delete(filename);
      this.uploadedAttachmentFileNames.delete(filename);
    } else {
      const selectedPdf = this.uploadedPdfFileNames.get(filename);
      const pdfPageCount = selectedPdf?.processType === 'single' ? 1 : selectedPdf?.pageCount || 1;
      this.pdfsCount = this.pdfsCount - 1;
      this.pdfsPageCount = this.pdfsPageCount - pdfPageCount;
      this.pdfUploadProgressMap.delete(filename);
      this.uploadedPdfFileNames.delete(filename);
    }

    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  goBack() {
    const currentEditOrderId = this.currentState?.currentEditOrder?.id!;
    this.orderService.updateCurrentOrderEditStep('order-basics');
    this.router.navigate([this.profileId, 'orders', currentEditOrderId, 'edit', 'order-basics']);
  }

  cancel() {
    this.stateService.updateState({ currentEditOrder: null });
    this.router.navigate([this.profileId, 'orders']);
  }

  get nextStepAllowed() {
    if (!this.currentOrder) return false;

    const currentEditOrder = this.currentOrder;
    const allMainDocsUploaded =
    this.checkAllPdfUploadsStatus === 'upload-all-complete' &&
    this.checkAllAttachmentUploadsStatus === 'upload-all-complete';
    const hasPdfAnalysisStatus = currentEditOrder?.pdfAnalysisStatus;
    const isPdfAnalysisStatusDone = currentEditOrder?.pdfAnalysisStatus?.stage === 'DONE';

    // Has no PDF analysis status
    if (!hasPdfAnalysisStatus) {
      // Check if has main pdf files and all docs are uploaded.
      return (
        this.uploadedPdfFileNames.size +
          this.uploadedAttachmentFileNames.size >
          0 && allMainDocsUploaded
      );
    } else {
      // Has PDF analysis status and is done.
      return isPdfAnalysisStatusDone;
    }
  }

  get checkAllPdfUploadsStatus(): 'upload-all-complete' | 'uploading' {
    return Array.from(this.pdfUploadProgressMap.values()).every(
      ({ isUploading }) => !isUploading
    )
      ? 'upload-all-complete'
      : 'uploading';
  }

  get checkAllAttachmentUploadsStatus(): 'upload-all-complete' | 'uploading' {
    return Array.from(this.attachmentUploadProgressMap.values()).every(
      ({ isUploading }) => !isUploading
    )
      ? 'upload-all-complete'
      : 'uploading';
  }

  async updateOrderFiles(orderFiles: OrderFile[], uploadType: 'pdf' | 'attachment' = 'pdf') {
    let updatedOrderType: Partial<OrderType> = {};

    if (uploadType === 'pdf') {
      const messagePdfs = this.currentState?.currentEditOrder?.messagePdfs || [];
      updatedOrderType = {
        messagePdfs: [...messagePdfs, ...orderFiles],
      };
    } 
    
    if (uploadType === 'attachment') {
      const attachmentPdfs = this.currentState?.currentEditOrder?.attachmentPdfs || [];
      updatedOrderType = {
        attachmentPdfs: [...attachmentPdfs, ...orderFiles],
      };
    }

    await this.orderService.updateOrder(updatedOrderType);
  }

  async uploadFile(
    file: File,
    id: string,
    filename: string,
    uploadType: 'pdf' | 'attachment' = 'pdf',
    pageCount: number
  ) {
    const currentUser = await this.auth.currentUser;
    if (!currentUser || !this.currentOrder) {
      return;
    }

    const { 
      id: orderId, 
      profileId, 
      pdfAnalysisPresetSnapshot: { processType = 'single' } = { processType: 'single' }
    } = this.currentOrder;

    const orderFilePath = `profiles/${profileId}/${orderId}`;
    let filePath = `${orderFilePath}/${filename}`;

    if (uploadType === 'pdf') {
      this.pdfUploadProgressMap.set(filename, {
        uploadProgress: 0,
        isUploading: true,
      });
      this.uploadedPdfFileNames.set(filename, {
        id,
        filename,
        name: file.name,
        processType,
        pageCount
      });
    }

    if (uploadType === 'attachment') {
      this.attachmentUploadProgressMap.set(filename, {
        uploadProgress: 0,
        isUploading: true,
      });
      this.uploadedAttachmentFileNames.set(filename, {
        filename,
        name: file.name,
        attachmentType: 'prepend'
      });
      filePath = `${orderFilePath}/attachments/${filename}`;
    }

    const fileRef = storageRef(this.storage, filePath);
    const task = uploadBytesResumable(fileRef, file);
    task.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        if (uploadType === 'pdf') {
          this.pdfUploadProgressMap.set(filename, {
            uploadProgress: progress,
            isUploading: true,
          });
        } 
        
        if (uploadType === 'attachment') {
          this.attachmentUploadProgressMap.set(filename, {
            uploadProgress: progress,
            isUploading: true,
          });
        }

        this.changeDetectorRef.detectChanges();

        switch (snapshot.state) {
          case 'paused':
            console.log(
              'Upload is paused',
              'name:',
              file.name,
              'progress:',
              progress
            );
            break;
          case 'running':
            console.log(
              'Upload is running',
              'name:',
              file.name,
              'progress:',
              progress
            );
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      () => {
        this.zone.run(() => {
          if (uploadType === 'pdf') {
            this.pdfUploadProgressMap.set(filename, {
              uploadProgress: 100,
              isUploading: false,
            });
          } 
          
          if (uploadType === 'attachment') {
            this.attachmentUploadProgressMap.set(filename, {
              uploadProgress: 100,
              isUploading: false,
            });
          }
          this.changeDetectorRef.detectChanges();
        });
      }
    );
  }

  async uploadFiles(
    input: HTMLInputElement,
    uploadType: 'pdf' | 'attachment' = 'pdf'
  ) {
    if (!input.files) {
      return;
    }

    // if currentOrder is null create new order
    if (!this.currentOrder) {
      await this.createPartialOrder();
    }

    const files: FileList = input.files;
    let orderFiles: OrderFile[] = [];

    const invalidFileType = await this.checkFileType(input.files as FileList);
    if (!invalidFileType) {
      input.value = '';
      return;
    }

    if (uploadType === 'pdf') {
      const limitExceed = await this.checkPDFsLimit(input.files as FileList);
      if (limitExceed) {
        input.value = '';
        return;
      }
    }

    for (let i = 0; i < files.length; i++) {
      const id = uuidv4();
      const fileExtension = files[i].name.split('.').pop()?.toLowerCase() ?? 'pdf'
      const fileNameWithExtension = `${id}.${fileExtension}`;
      const pageCount = await this.getPdfPageCount(files[i]) || 1;

      if (uploadType === 'pdf') {
        orderFiles.push({
          id,
          createdAt: Timestamp.now(),
          filename: fileNameWithExtension,
          fileType: uploadType,
          name: files[i].name,
          type: files[i].type,
          processType: 'single',
          pageCount
        });
      } 
      
      if (uploadType === 'attachment') {
        orderFiles.push({
          id,
          createdAt: Timestamp.now(),
          filename: fileNameWithExtension,
          fileType: uploadType,
          name: files[i].name,
          type: files[i].type,
          attachmentType: 'prepend',
          pageCount
        });
      }
      await this.uploadFile(files[i], id, fileNameWithExtension, uploadType, pageCount);
    }
    this.updateOrderFiles(orderFiles, uploadType);
    // Reset input value
    input.value = '';
  }

  async onFileDrop(event: DragEvent, uploadType: 'pdf' | 'attachment' = 'pdf') {
    const hasData = event.dataTransfer?.files[0];
    event.preventDefault();
    this.isPdfDragOver = false;
    this.isAttachmentDragOver = false;
    
    // if currentOrder is null create new order
    if (!this.currentOrder) {
      await this.createPartialOrder();
    }

    if (hasData) {
      let files: File[] = [];
      if (event.dataTransfer.items) {
        for (let i = 0; i < event.dataTransfer.items.length; i++) {
          if (event.dataTransfer.items[i].kind === 'file') {
            const file = event.dataTransfer.items[i].getAsFile();
            if (file) {
              files.push(file);
            }
          }
        }
      } else {
        for (let i = 0; i < event.dataTransfer.files.length; i++) {
          files.push(event.dataTransfer.files[i]);
        }
      }

      const invalidFileType = await this.checkFileType(files as unknown as FileList);
      if (!invalidFileType) { return; };

      if (uploadType === 'pdf') {
        const limitExceed = await this.checkPDFsLimit(files as unknown as FileList);
        if (limitExceed) { return; };
      }

      let orderFiles: OrderFile[] = [];
      for (let i = 0; i < files.length; i++) {
        const id = uuidv4();
        const fileExtension = files[i].name.split('.').pop()?.toLowerCase() ?? 'pdf';
        const fileNameWithExtension = `${id}.${fileExtension}`;
        const pageCount = await this.getPdfPageCount(files[i]) || 1;

        if (uploadType === 'pdf') {
          orderFiles.push({
            id,
            createdAt: Timestamp.now(),
            filename: fileNameWithExtension,
            fileType: uploadType,
            name: files[i].name,
            type: files[i].type,
            processType: 'single',
            pageCount
          });
        } 

        if (uploadType === 'attachment') {
          orderFiles.push({
            id,
            createdAt: Timestamp.now(),
            filename: fileNameWithExtension,
            fileType: uploadType,
            name: files[i].name,
            type: files[i].type,
            attachmentType: 'prepend',
            pageCount
          });
        }

        await this.uploadFile(files[i], id, fileNameWithExtension, uploadType, pageCount);
      }
      this.updateOrderFiles(orderFiles, uploadType);
    }
  }

  onDragOver(event: DragEvent, fileType: 'pdf' | 'attachment' = 'pdf') {
    event.preventDefault();
    if (fileType === 'pdf') {
      this.isPdfDragOver = true;
    } else {
      this.isAttachmentDragOver = true;
    }
  }

  onDragLeave(event: DragEvent, fileType: 'pdf' | 'attachment' = 'pdf') {
    event.preventDefault();
    if (fileType === 'pdf') {
      this.isPdfDragOver = false;
    } else {
      this.isAttachmentDragOver = false;
    }
  }

  onAttachmentTypeToggleChange(event: MatButtonToggleChange, filename: string) {
    this.stateService.startLoading(LoadingState.OrderLoading);

    const attachmentType = event.value;
    let attachmentPdfs = this.currentState?.currentEditOrder?.attachmentPdfs || [];
    let selectedAttachment = this.uploadedAttachmentFileNames.get(filename);
    
    if (selectedAttachment) {
      selectedAttachment = { ...selectedAttachment, attachmentType };
      this.uploadedAttachmentFileNames.set(filename, selectedAttachment);
    }

    attachmentPdfs = [
      ...attachmentPdfs.map((attachment) => {
        if (attachment.filename === filename) {
          return { ...attachment, attachmentType };
        }
        return attachment;
      }),
    ];

    this.orderService.updateOrder({ attachmentPdfs }).then(() => {
      this.stateService.stopLoading(LoadingState.OrderLoading);
    });
  }

  onProcessTypeToggleChange(event: MatButtonToggleChange, filename: string) {
    const processType = event.value;
    const selectedPdf = this.uploadedPdfFileNames.get(filename);
    let messagePdfs = this.currentState?.currentEditOrder?.messagePdfs || [];
    
    if (selectedPdf) {
      if (processType === 'single') {
        this.pdfsCount -= 1;
        this.pdfsPageCount -= selectedPdf.pageCount;
      } else {
        this.pdfsCount -= 1;
        this.pdfsPageCount -= 1;
      }

      const pdfPageCount = processType === 'single' ? 1 : selectedPdf.pageCount;
      this.tempPdfsCount = 1;
      this.tempPdfsPageCount = pdfPageCount;

      const limitExceed = this.checkPDFLimit();
      if (limitExceed) { 
        this.uploadedPdfFileNames.set(filename, { ...selectedPdf, processType: 'single' });
        return; 
      };

      this.uploadedPdfFileNames.set(filename, { ...selectedPdf, processType });
    }

    this.stateService.startLoading(LoadingState.OrderLoading);
    messagePdfs = [
      ...messagePdfs.map((pdf) => {
        if (pdf.filename === filename) {
          return { ...pdf, processType };
        }
        return pdf;
      }),
    ];

    this.orderService.updateOrder({ messagePdfs }).then(() => {
      this.stateService.stopLoading(LoadingState.OrderLoading);
    });
  }

  updateAllDocumentsProcessType(processType: 'single' | 'multiple') {
    // reset pdfsCount & pdfsPageCount value to zero
    this.pdfsCount = 0;
    this.pdfsPageCount = 0;

    this.stateService.startLoading(LoadingState.OrderLoading);
    let messagePdfs = this.currentState?.currentEditOrder?.messagePdfs || [];
    let updatedPdfs: any[] = [];
    const copyOfUploadedPdfFileNames = new Map(this.uploadedPdfFileNames);
  
    copyOfUploadedPdfFileNames.forEach((pdf, filename) => {
      const pdfPageCount = processType === 'single' ? 1 : pdf.pageCount;
      this.tempPdfsCount += 1;
      this.tempPdfsPageCount += pdfPageCount;
  
      const limitExceed = this.checkPDFLimit();
      if (limitExceed) {
        this.stateService.stopLoading(LoadingState.OrderLoading);
        return;
      }
  
      copyOfUploadedPdfFileNames.set(filename, { ...pdf, processType });
      updatedPdfs.push({ ...pdf, processType });
    });
  
    // If all checks pass, then we update the original Map
    if (updatedPdfs.length === this.uploadedPdfFileNames.size) {
      this.uploadedPdfFileNames = copyOfUploadedPdfFileNames;
      messagePdfs = [
        ...messagePdfs.map((pdf) => {
          const updatedPdf = updatedPdfs.find(updf => updf.filename === pdf.filename);
          return updatedPdf ? updatedPdf : pdf;
        }),
      ];
  
      this.orderService.updateOrder({ messagePdfs }).then(() => {
        this.stateService.stopLoading(LoadingState.OrderLoading);
      });
    }
  }
  
  async checkFileType(files: FileList): Promise<boolean> {
    let isValidType = true;
    let confirmationMessage = '';
    this.translate.get('errMsg.errorFiletype').subscribe((res: string) => {
      confirmationMessage = res;
    });
    for (let i = 0; i < files.length; i++) {
      if(!files[i].type.match('application/pdf.*')){    
          isValidType = false;
          this.dialog.open(WarningDialogComponent, {
          width: '400px',
          data: { message: confirmationMessage },
        })
      }

    }
    return isValidType;
  }

  async checkPDFsLimit(files: FileList): Promise<boolean> {
    // Convert FileList to Array and iterate
    for (const file of Array.from(files)) {
      this.tempPdfsCount += 1;
      this.tempPdfsPageCount += 1;
    }

    return this.checkPDFLimit();
  }

  checkPDFLimit(): boolean {
     // total number of attempted PDFs to upload exceeded
     if ((this.pdfsCount + this.tempPdfsCount) > 100) {
      this.dialog.open(PDFLimitDialogComponent, {
        width: '480px',
        data: {
          title: this.translate.instant('errMsg.exceededPdfLimitTitle'),
          message: `${this.translate.instant('errMsg.exceededPdfLimit')} ${this.pdfsCount + this.tempPdfsCount}`,
        },
      });

      this.tempPdfsCount = 0;
      this.tempPdfsPageCount = 0;
      return true;
    }
  
    // total number of attempted PDFs pages upload exceeded 
    if ((this.pdfsPageCount + this.tempPdfsPageCount) > 100) {
      this.dialog.open(PDFLimitDialogComponent, {
        width: '480px',
        data: {
          title: this.translate.instant('errMsg.exceededPageLimitTitle'),
          message: `${this.translate.instant('errMsg.exceededPageLimit')} ${this.pdfsPageCount + this.tempPdfsPageCount}`,
        },
      });

      this.tempPdfsCount = 0;
      this.tempPdfsPageCount = 0;
      return true;
    }

    this.pdfsCount = this.pdfsCount + this.tempPdfsCount;
    this.pdfsPageCount = this.pdfsPageCount + this.tempPdfsPageCount;
    this.tempPdfsCount = 0;
    this.tempPdfsPageCount = 0;
    return false;
  }
  
  async getPdfPageCount(file: File): Promise<number> {
    if (file && file.type === 'application/pdf') {
      try {
        return await this.countPdfPages(file);
      } catch (error) {
        console.error('Error loading PDF:', error);
      }
    }
    return 0;
  }

  countPdfPages(file: File): Promise<number> {
    return new Promise((resolve, reject) => {
      let offset = 0;
      const chunkSize = 1024 * 1024; // 1MB chunks
      let count = 0;

      function processChunk() {
        const chunk = file.slice(offset, offset + chunkSize);
        const reader = new FileReader();
        reader.onloadend = () => {
          if (reader.result) {
            const text = reader.result as string;
            const matches = text.match(/\/Type[\s]*\/Page[^s]/g);
            if (matches) {
              count += matches.length;
            }
            offset += chunkSize;
            if (offset < file.size) {
              processChunk(); // process the next chunk
            } else {
              resolve(count); // done
            }
          }
        };
        reader.onerror = () => {
          reject(new Error('FileReader error'));
        };
        reader.readAsBinaryString(chunk);
      }

      processChunk(); // start processing the first chunk
    });
  }

  checkOverallProcessType(): 'single' | 'multiple' | 'mixed' {
    let singleCount = 0;
    let multipleCount = 0;
  
    this.uploadedPdfFileNames.forEach(pdf => {
      if (pdf.processType === 'single') {
        singleCount++;
      } else if (pdf.processType === 'multiple') {
        multipleCount++;
      }
    });
  
    if (singleCount === this.uploadedPdfFileNames.size) {
      return 'single';
    } else if (multipleCount === this.uploadedPdfFileNames.size) {
      return 'multiple';
    } else {
      return 'mixed';
    }
  }

  get buttonGroupValue(): 'single' | 'multiple' | null {
    const overallProcessType = this.checkOverallProcessType();
    if (overallProcessType === 'mixed') {
      return null; // When mixed, no options should be selected
    }
    return overallProcessType;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    if (!this.skipResetCurrentOrder) {
      this.stateService.updateState({ currentEditOrder: null });
    }
  }
}
