import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  OnDestroy,
  ViewEncapsulation,
  ChangeDetectorRef,
  ViewChild,
  ViewChildren,
  QueryList,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, from, map, of, switchMap, tap } from 'rxjs';

import {
  Address,
  AppState,
  OrderType,
  LoadingState,
  AddressValidation,
  PdfAnalysisResult,
  PdfAnalysisPreset,
  AddressValidationResult,
  RecipientValidationRequest,
  RecipientValidationResponse,
} from '../../../state/app-state';
import {
  getCurrentOrderPdfAnalysisResultMessage,
  parseAddressValidationResultToAddress,
  analyzeAddressValidationFlags,
} from './../../shared/utils/address-utils';
import { ConfirmationDialogComponent } from '../../shared/components/app-confirmation-dialog.component';
import { RecipientFormValidationService } from '../../../services/recipient-form-validation.service';
import { CustomTooltipDirective } from '../../shared/directives/custom-tooltip.directive';
import { HoverPopoverDirective } from '../../shared/directives/hover-popover.directive';
import { PdfAnalysisResultService } from '../../../services/pdf-analysis-result.service';
import { AppStateService } from '../../../services/app-state.service';
import { FirebaseService } from '../../../services/firebase.service';
import { isObjectEmpty } from './../../shared/utils/helper';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recipient-edit',
  templateUrl: './recipient-edit.component.html',
  styleUrls: ['./recipient-edit.component.scss'],
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecipientEditComponent implements OnInit, OnDestroy {
  @ViewChildren(HoverPopoverDirective) popoverDirectives!: QueryList<HoverPopoverDirective>;
  @ViewChild(CustomTooltipDirective) tooltip!: CustomTooltipDirective;
  private currentState!: AppState;
  private appStateSubscription?: Subscription;
  private currentEditOrder!: OrderType | null;
  private recipientValidation: RecipientValidationResponse | any = {};
  private validationErrors: Map<string, { code: string; message: string }> = new Map();
  private validationWarnings: Map<string, { code: string; message: string }> = new Map();
  private isReValidated: boolean = false;
  selectedRecipient: AddressValidationResult | null = null;
  recipientForm: FormGroup = new FormGroup({});
  imageLoaded: boolean[] = [];
  selectedTabIndex = 0;
  isEmailValid: boolean = false;
  isSubjectEditMode: boolean = false;
  isDocTypeEditMode: boolean = false;
  isDeliveryTypeEditMode: boolean = false;
  isColorEditMode: boolean = false;
  isPrintTypeEditMode: boolean = false;
  isShippingRegionEditMode: boolean = false;
  defaultAddressValidation: AddressValidation = {
    recipient: '',
    street: '',
    excessStreetText: '',
    streetNo: '',
    streetSubNo: '',
    zip: '',
    city: '',
    countryCode: ''
  };
  suggestedAddress: AddressValidation = { ...this.defaultAddressValidation };
  requiresBlankFirstPage: boolean = false;
  /**
   * Current page dimension. All pages are presumed in A4 size.
   */
  readonly a4PageDimension = { heightInMm: 297, widthInMm: 210 } as const;
 
  constructor(
    private dialog: MatDialog,
    private cdr: ChangeDetectorRef,
    public dialogRef: MatDialogRef<ConfirmationDialogComponent>,
    private stateService: AppStateService,
    private firebaseService: FirebaseService,
    private pdfAnalysisResultService: PdfAnalysisResultService,
    public formValidationService: RecipientFormValidationService,
    private translate: TranslateService,
  ) {
    this.appStateSubscription = this.stateService.appState$.subscribe(
      (state) => {
        this.currentState = state;
      }
    );
  }

  ngOnInit(): void {
    this.selectedRecipient = this.currentState.currentRecipient;
    this.isEmailValid = this.selectedRecipient?.isEmailValid || false;
    this.currentEditOrder = this.currentState.currentEditOrder;
    this.requiresBlankFirstPage = this.currentEditOrder?.pdfAnalysisPresetSnapshot?.requiresBlankFirstPage!;
    this.imageLoaded = new Array(
      (this.selectedRecipient?.fileUrls || []).length
    ).fill(false);
    // Initialize custom form validation
    this.validationErrors = this.formValidationService.setValidationFlags(this.selectedRecipient?.validationErrors);
    this.validationWarnings = this.formValidationService.setValidationFlags(this.selectedRecipient?.validationWarnings);
    // Initialize form value
    if (this.selectedRecipient) {
      this.suggestedAddress = {
        ...this.defaultAddressValidation,
        ...(this.selectedRecipient?.suggestedAddress || {}),
      };

      this.initializeForm(
        this.selectedRecipient.address,
        this.selectedRecipient?.email,
        this.selectedRecipient.subject,
        this.selectedRecipient.docType,
        this.selectedRecipient.postalDeliveryTypeDomestic,
        this.selectedRecipient.printMetadata
      );
    }
  }

  initializeForm(
    address: Address,
    email: string = '',
    subject: string,
    docType: OrderType['docType'],
    postalDeliveryTypeDomestic: OrderType['postalDeliveryTypeDomestic'],
    printMetadata: OrderType['printMetadata']
  ) {
    const country = this.selectedRecipient?.address?.country || '';
    this.recipientForm = new FormGroup({
      recipient: new FormControl<string | null>(address?.recipient ?? null, [
        Validators.required,
        this.formValidationService.checkForErrors(this.validationErrors, 'recipient'),
      ]),
      street: new FormControl<string | null>(address?.street ?? null, [
        Validators.required,
        this.formValidationService.checkForErrors(this.validationErrors, 'street'),
      ]),
      number: new FormControl<string | null>(address?.number ?? null, [
        this.formValidationService.checkForErrors(this.validationErrors, 'number'),
      ]),
      zip: new FormControl<string | null>(address?.zip ?? null, [
        Validators.required,
        this.formValidationService.checkForErrors(this.validationErrors, 'zip'),
      ]),
      city: new FormControl<string | null>(address?.city ?? null, [
        Validators.required,
        this.formValidationService.checkForErrors(this.validationErrors, 'city'),
      ]),
      country: new FormControl<string | null>(country ?? null, []),
      email: new FormControl<string | null>(email ?? null, [
        this.formValidationService.optionalEmailValidation(),
      ]),
      subject: new FormControl<string | null>(subject ?? null),
      docType: new FormControl<string | null>(docType, [Validators.required]),
      postalDeliveryTypeDomestic: new FormControl<string | null>(
        postalDeliveryTypeDomestic,
        [Validators.required]
      ),
      printMetadata: new FormGroup({
        color: new FormControl<string | null>(printMetadata.color, [
          Validators.required,
        ]),
        printType: new FormControl<string | null>(printMetadata.printType, [
          Validators.required,
        ]),
      }),
    });

    this.recipientForm.markAllAsTouched();
    // disabled subject field on default
    if (!this.isSubjectEditMode) {
      this.recipientForm?.get('subject')?.disable();
    }
    // disabled docType field on default
    if (!this.isDocTypeEditMode) {
      this.recipientForm?.get('docType')?.disable();
    }
    // disabled deliveryType field on default
    if (!this.isDeliveryTypeEditMode) {
      this.recipientForm?.get('postalDeliveryTypeDomestic')?.disable();
    }
    // disabled color field on default
    if (!this.isColorEditMode) {
      this.recipientForm?.get('printMetadata.color')?.disable();
    }
    // disabled printType field on default
    if (!this.isPrintTypeEditMode) {
      this.recipientForm?.get('printMetadata.printType')?.disable();
    }
    this.recipientForm?.get('country')?.disable();

    // initialize form address warning
    this.formValidationService.setControlWarnings(this.recipientForm, this.validationWarnings);
    // initialize from fields flag state
    this.formValidationService.initializeFieldFlagsStates(this.recipientForm);
  }

  private isFieldCustomError(fieldName: string): boolean {
    return !this.recipientForm.get(fieldName)?.hasError('required') && 
           this.formValidationService.fieldFlagsStates[fieldName]?.hasCustomError;
  }

  get isRecipientCustomError(): boolean {
    return this.isFieldCustomError('recipient');
  }
  
  get isStreetCustomError(): boolean {
    return this.isFieldCustomError('street');
  }
  
  get isNumberCustomError(): boolean {
    return this.isFieldCustomError('number');
  }
  
  get isZipCustomError(): boolean {
    return this.isFieldCustomError('zip');
  }
  
  get isCityCustomError(): boolean {
    return this.isFieldCustomError('city');
  }

  private isFieldCustomWarning(fieldName: string): boolean {
    return !!this.formValidationService.fieldFlagsStates[fieldName]?.hasCustomWarning;
  }

  get isRecipientCustomWarning(): boolean {
    return this.isFieldCustomWarning('recipient');
  }
  
  get isStreetCustomWarning(): boolean {
    return this.isFieldCustomWarning('street');
  }
  
  get isNumberCustomWarning(): boolean {
    return this.isFieldCustomWarning('number');
  }
  
  get isZipCustomWarning(): boolean {
    return this.isFieldCustomWarning('zip');
  }
  
  get isCityCustomWarning(): boolean {
    return this.isFieldCustomWarning('city');
  }
  
  closeDialog() {
    this.dialog.closeAll();
  }

  onClickSave() {
    if (this.isAddressOverridden && !this.requiresBlankFirstPage) {
      let confirmationMessage = '';
      this.translate.get('pages.addressCheck.editRecipient.blankPage').subscribe((res: string) => {
        confirmationMessage = res;
      });
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: { message: confirmationMessage },
      });

      dialogRef.afterClosed().subscribe((confirmed) => {
        if (confirmed && this.selectedRecipient) {
          this.saveOverrideRecipient();
        }
      });
    } else {
      this.saveOverrideRecipient();
    }
  }

  saveOverrideRecipient() {
    const docId = this.selectedRecipient?.docId;
    const messageId = this.selectedRecipient?.messageId;
    const currentEditOrder = this.currentState.currentEditOrder;

    if (currentEditOrder) {
      const pdfAnalysisResults: PdfAnalysisResult[] = currentEditOrder?.pdfAnalysisResults || [];
      const selectedPdfAnalysisResult = pdfAnalysisResults.find(
        (pdfAnalysisResult) => pdfAnalysisResult.id === docId
      );

      if (selectedPdfAnalysisResult) {
        this.stateService.startLoading(LoadingState.OrderLoading);
        const updatedMessages = selectedPdfAnalysisResult.messages.map(
          (message) => {
            if (message.id === messageId) {
              const {
                email,
                subject,
                docType,
                postalDeliveryTypeDomestic,
                printMetadata,
                countryCode,
                ...otherFormValue
              } = this.recipientForm.getRawValue();
              const {
                emailValidationApiResults,
                recipientDetailsValidationResult,
                overrideAddressValidationApiResults,
              } = this.recipientValidation || {};
              const {
                overrideRecipientDetails: stateOverrideRecipientDetails = {},
                overridePrintMetadata: stateOverridePrintMetadata = {},
              } =
                getCurrentOrderPdfAnalysisResultMessage(
                  currentEditOrder.pdfAnalysisResults!,
                  docId!,
                  messageId!
                ) || {};
              let updatedMessage: any = {
                ...message,
                additionalBlankPage: true,
              };
              let overrideRecipientDetails: any = {
                ...stateOverrideRecipientDetails,
                countryCode: this.selectedRecipient?.address?.countryCode
              };
              let overridePrintMetadata: any = {
                ...stateOverridePrintMetadata,
              };

              if (emailValidationApiResults) {
                updatedMessage = {
                  ...updatedMessage,
                  overrideEmailValidationApiResults: emailValidationApiResults,
                  overrideAddressValidationApiResults,
                };
              }

              if (recipientDetailsValidationResult) {
                updatedMessage = {
                  ...updatedMessage,
                  overrideAddressValidationResult: recipientDetailsValidationResult.address,
                  overrideAddressValidationApiResults,
                };
              }

              if (this.isAddressOverridden) {
                overrideRecipientDetails.address = { ...otherFormValue, countryCode: this.selectedRecipient?.address?.countryCode };
              }

              if (
                this.isEmailOverridden &&
                this.isEmailValid &&
                this.isReValidated
              ) {
                overrideRecipientDetails.email = {
                  emailAddress: email,
                  isEmailValid: this.isEmailValid,
                };
                overrideRecipientDetails.countryCode = this.selectedRecipient?.address?.countryCode;
              }

              if (this.isSubjectOverridden) {
                updatedMessage = {
                  ...updatedMessage,
                  overrideSubject: subject,
                };
              }

              if (this.isDocTypeOverridden) {
                updatedMessage = {
                  ...updatedMessage,
                  overrideDocType: docType,
                };
              }

              if (this.isDeliveryTypeOverridden) {
                updatedMessage = {
                  ...updatedMessage,
                  overridePostalDeliveryTypeDomestic:
                    postalDeliveryTypeDomestic,
                };
              }

              if (this.isColorOverridden) {
                overridePrintMetadata.color = printMetadata.color;
              }

              if (this.isPrintTypeOverridden) {
                overridePrintMetadata.printType = printMetadata.printType;
              }

              if (!isObjectEmpty(overridePrintMetadata)) {
                updatedMessage = {
                  ...updatedMessage,
                  overridePrintMetadata,
                };
              }

              if (!isObjectEmpty(overrideRecipientDetails)) {
                updatedMessage = {
                  ...updatedMessage,
                  overrideRecipientDetails,
                };
              }

              return updatedMessage;
            }
            return message;
          }
        );

        this.pdfAnalysisResultService
          .updatePdfAnalysisResultDoc(currentEditOrder.id, docId!, {
            messages: updatedMessages,
          })
          .then((success) => {
            if (success) {
              const updatedCurrentEditOrder = {
                ...currentEditOrder!,
                pdfAnalysisResults: (
                  currentEditOrder?.pdfAnalysisResults || []
                ).map((pdfAnalysisResult) => {
                  if (pdfAnalysisResult.id === docId) {
                    return {
                      ...pdfAnalysisResult,
                      messages: updatedMessages,
                    };
                  }
                  return pdfAnalysisResult;
                }),
              };
              this.stateService.updateState({
                ...this.currentState,
                currentEditOrder: updatedCurrentEditOrder,
                isLoadingOrder: false,
              });
              // Perform any additional actions or close the dialog here
              this.dialogRef.close();
            } else {
              this.stateService.stopLoading(LoadingState.OrderLoading);
            }
          });
      }
    }
  }

  onCancel() {
    if (this.isReValidated || this.recipientForm.dirty) {
      let confirmationMessage = '';
      this.translate.get('pages.addressCheck.validations.discardChanges').subscribe((res: string) => {
        confirmationMessage = res;
      });
      const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
        width: '400px',
        data: { message: confirmationMessage },
      });
      dialogRef.afterClosed().subscribe((result) => {
        if (result) {
          this.dialogRef.close();
        }
      });
    } else {
      this.dialogRef.close();
    }
  }

  onRemoveRecipient() {
    const proceedRemoval = (confirmed: boolean) => {
      if (!confirmed) {
        return of(false);
      }
      this.stateService.startLoading(LoadingState.OrderLoading);

      if (this.selectedRecipient?.messageId) {
        const { docId, messageId } = this.selectedRecipient || {};
        return from(
          this.pdfAnalysisResultService
            .deleteMessage(docId, messageId)
            .then(() => {
              this.stateService.stopLoading(LoadingState.OrderLoading);
              return true;
            })
        );
      } else {
        this.stateService.stopLoading(LoadingState.OrderLoading);
        return of(false);
      }
    };

    let confirmationMessage = '';
    this.translate.get('pages.addressCheck.editRecipient.confirmationRemove').subscribe((res: string) => {
      confirmationMessage = res;
    });

    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      width: '400px',
      data: { message: confirmationMessage },
    });

    dialogRef
      .afterClosed()
      .pipe(
        switchMap(proceedRemoval),
        tap((updated) => {
          if (updated) {
            this.dialogRef.close();
          }
        })
      )
      .subscribe();
  }

  async onValidate() {
    this.isReValidated = true;
    try {
      const docId = this.selectedRecipient?.docId;
      const messageId = this.selectedRecipient?.messageId!;
      let validateForm: RecipientValidationRequest = {
        orderId: this.currentState.currentEditOrder?.id!,
        docId: docId!,
        messageId: messageId,
        recipientDetails: {
          address: {
            recipient: this.recipientForm.value.recipient,
            street: this.recipientForm.value.street,
            zip: this.recipientForm.value.zip,
            city: this.recipientForm.value.city,
          }
        },
      };
      if (this.recipientForm.value.number) {
        validateForm.recipientDetails.address.number =
        this.recipientForm.value.number;
      }
      if (this.recipientForm.value.email) {
        validateForm.recipientDetails.email = {
          emailAddress: this.recipientForm.value.email,
          isEmailValid: true,
        };
      }
      this.stateService.startLoading(LoadingState.OrderLoading);
      //start function
      await this.firebaseService
        .validateRecipient(validateForm)
        .then((response: RecipientValidationResponse) => {
          this.recipientValidation = response;
          this.suggestedAddress = {
            ...this.defaultAddressValidation,
            ...parseAddressValidationResultToAddress(
              response?.recipientDetailsValidationResult?.address
            ),
          };
          // Re-initialize custom validation
          const {
            errors: addressValidationErrors = [],
            warnings: addressValidationWarnings = [],
          } = analyzeAddressValidationFlags(
            response.recipientDetailsValidationResult.address
          );
          this.validationErrors = this.formValidationService.setValidationFlags(addressValidationErrors);
          this.validationWarnings = this.formValidationService.setValidationFlags(addressValidationWarnings);
          // Re-initialize form
          const subject = this.recipientForm.get('subject')!.value;
          const docType = this.recipientForm.get('docType')!.value;
          const postalDeliveryTypeDomestic = this.recipientForm.get(
            'postalDeliveryTypeDomestic'
          )!.value;
          const printMetadata = this.recipientForm.get('printMetadata')!.value;
          const address = validateForm?.recipientDetails?.address as Address;
          const emailAddress = response?.recipientDetailsValidationResult?.email
            ?.emailAddress || '' as string;
          this.isEmailValid = response?.recipientDetailsValidationResult?.email
            ?.isEmailValid || false as boolean;   

          this.initializeForm(
            address,
            emailAddress,
            subject,
            docType,
            postalDeliveryTypeDomestic,
            printMetadata,
          );
          this.cdr.detectChanges();
          this.toggleTooltip();

          this.stateService.stopLoading(LoadingState.OrderLoading);
        });
    } catch (error) {
      console.error('Error validating recipient:', error);
      this.stateService.stopLoading(LoadingState.OrderLoading);
    }
  }

  private isFieldOverridden(fieldName: string, subField: string = ''): boolean {
    if (!this.selectedRecipient || !this.recipientForm) {
        return false;
    }

    let originalValue: string = '';
    let formControlPath: string = fieldName;

    if (subField) {
        originalValue = (this.selectedRecipient as any)[fieldName]?.[subField];
        formControlPath = fieldName === 'address' ? subField : `${fieldName}.${subField}`;
    } else {
        originalValue = (this.selectedRecipient as any)[fieldName];
    }

    // Trim the originalValue
    if (typeof originalValue === 'string') {
        originalValue = originalValue.trim();
    }

    const formControl = this.recipientForm.get(formControlPath);
    if (!originalValue || !formControl) {
        return false;
    }

    const formValue = typeof formControl.value === 'string'
        ? formControl.value.trim()
        : formControl.value;

    return formValue && originalValue !== formValue;
  }

  get isRecipientOverridden(): boolean {
    return this.isFieldOverridden('address', 'recipient');
  }
  
  get isStreetOverridden(): boolean {
    return this.isFieldOverridden('address', 'street');
  }
  
  get isSubjectOverridden(): boolean {
    return this.isFieldOverridden('subject');
  }
  
  get isDocTypeOverridden(): boolean {
    return this.isFieldOverridden('docType');
  }
  
  get isDeliveryTypeOverridden(): boolean {
    return this.isFieldOverridden('postalDeliveryTypeDomestic');
  }
  
  get isColorOverridden(): boolean {
    return this.isFieldOverridden('printMetadata', 'color');
  }
  
  get isPrintTypeOverridden(): boolean {
    return this.isFieldOverridden('printMetadata', 'printType');
  }

  get isEmailOverridden(): boolean {
    const { email } = this.selectedRecipient as AddressValidationResult;
    return email !== this.recipientForm.value.email;
  }

  get isAddressOverridden(): boolean {
    if (!this.selectedRecipient || !this.recipientForm) {
      return false;
    }
    const { validationEngine, address } = this.selectedRecipient as AddressValidationResult;
  
    return (
      (validationEngine === 'eirene' && this.isFieldOverridden('address', 'recipient')) ||
      this.isFieldOverridden('address', 'street') ||
      this.isFieldOverridden('address', 'zip') ||
      this.isFieldOverridden('address', 'city') ||
      address.number !== this.recipientForm.value.number
    );
  }

  get isRecipientValid(): boolean {
    return this.isAddressOverridden || this.isEmailOverridden;
  }

  get isDisabledSaveBtn(): boolean {
    return !(
      this.isRecipientValid ||
      this.isRecipientOverridden ||
      this.isSubjectOverridden ||
      this.isDocTypeOverridden ||
      this.isDeliveryTypeOverridden ||
      this.isColorOverridden ||
      this.isPrintTypeOverridden
    );
  }

  toggleTooltip() {
    if (!this.isEmailValid && this.isRecipientValid) {
      this.tooltip.show();
    } else {
      this.tooltip.hide();
    }
  }

  toggleEditMode(controlName: string, propertyName: string): void {
    const control = this.recipientForm.get(controlName)!;
    const isEditMode = (this as any)[propertyName];
    (this as any)[propertyName] = !isEditMode;
    if ((this as any)[propertyName]) {
        control.enable();
    } else {
        control.disable();
    }
  }

  public getPresetBoxStyle() {
    const computePresetBoxDimensionAndPosition = (context: {
      pageDimension: { heightInMm: number; widthInMm: number };
      addressBoxLocation: PdfAnalysisPreset['postalAddressBoxLocation'];
    }) => {
      const { pageDimension, addressBoxLocation } = context;
      const { widthInMm, heightInMm } = pageDimension;
      const topLeftXPercent = (addressBoxLocation.topLeftX / widthInMm) * 100;
      const topLeftYPercent = (addressBoxLocation.topLeftY / heightInMm) * 100;
      const widthPercent = (addressBoxLocation.width / widthInMm) * 100;
      const heightPercent = (addressBoxLocation.height / heightInMm) * 100;

      return { topLeftXPercent, topLeftYPercent, widthPercent, heightPercent };
    };

    const { topLeftXPercent, topLeftYPercent, widthPercent, heightPercent } =
      computePresetBoxDimensionAndPosition({
        pageDimension: this.a4PageDimension,
        addressBoxLocation:
          this.stateService.appState$.value.currentEditOrder!
            .pdfAnalysisPresetSnapshot.postalAddressBoxLocation,
      });

    return {
      left: `${topLeftXPercent}%`,
      top: `${topLeftYPercent}%`,
      width: `${widthPercent}%`,
      height: `${heightPercent}%`,
    };
  }

  suggestedNumberValue(suggestedNumber: any): string {
    return `${suggestedNumber?.streetNo || ''}${suggestedNumber?.streetSubNo || ''}`
  }

  focusInput(fieldName: string): void {
    const input = document.querySelector(`input[formControlName='${fieldName}']`);
    if (input) {
        (input as HTMLElement).focus();
    }
  }

  hideAllPopovers(event?: Event) {
    if (event) {
        event.stopPropagation(); 
        event.preventDefault();
    }

    this.popoverDirectives.forEach((directive) => {
      directive.hideOverlay();
    });
 }

 handleEnterKey(event: Event, fieldName: string) {
    event.stopPropagation();
    event.preventDefault();
    this.hideAllPopovers();
    this.focusInput(fieldName);
  }

  handleSuggestionOverride(
    field: string, 
    value: string, 
    appendValue: string = '',
    event?: Event,
  ) {
    // Set the provided field's value first
    this.recipientForm.get(field)?.setValue(value);
    
    // close popover
    if (event) {
      this.handleEnterKey(event, field);
    } else {
      this.hideAllPopovers();
    }

    // If appendValue is provided, append it to the 'recipient' field
    if (field === 'street' && appendValue) {
      const currentRecipientValue = this.recipientForm.get('recipient')?.value || '';
      const updatedRecipientValue = `${currentRecipientValue}\n${appendValue}`;
      this.recipientForm.get('recipient')?.setValue(updatedRecipientValue);
    }
  }

  ngOnDestroy() {
    this.appStateSubscription?.unsubscribe();
  }
}
