import { Injectable, inject } from '@angular/core';
import {
  listAll,
  ref,
  Storage,
  deleteObject,
  getDownloadURL,
} from '@angular/fire/storage';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { AppStateService } from './app-state.service';
import { OrderService } from './order.service';
import { AppState } from '../state/app-state';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class StorageService {
  private readonly storage = inject(Storage);
  private appStateSubscription?: Subscription;
  private currentState!: AppState;

  constructor(
    private toastr: ToastrService,
    private translate: TranslateService,
    private orderService: OrderService,
    private appStateService: AppStateService
  ) {
    this.appStateSubscription = this.appStateService.appState$.subscribe(
      (state) => {
        this.currentState = state;
      }
    );
  }

  async deleteFile(
    filename: string,
    uploadType: 'pdf' | 'attachment' = 'pdf'
  ): Promise<void> {
    const orderId = this.currentState.currentEditOrder?.id!;
    const profileId = this.currentState.currentProfile?.id;
    let filePath = '';
    let updatedFiles = {};

    if (orderId && profileId && filename) {
      if (uploadType === 'pdf') {
        const messagePdfs = [
          ...(this.currentState.currentEditOrder?.messagePdfs || []).filter(
            (file) => file.filename !== filename
          ),
        ];
        filePath = `profiles/${profileId}/${orderId}/${filename}`;
        updatedFiles = { messagePdfs };
      }

      if (uploadType === 'attachment') {
        const attachmentPdfs = [
          ...(this.currentState.currentEditOrder?.attachmentPdfs || []).filter(
            (file) => file.filename !== filename
          ),
        ];
        filePath = `profiles/${profileId}/${orderId}/attachments/${filename}`;
        updatedFiles = { attachmentPdfs };
      }

      try {
        // Get a reference to the file in Firebase Storage
        const fileRef = ref(this.storage, filePath);
        // Delete the file from Firebase Storage
        await deleteObject(fileRef);
        // update order messagePdfs or attachmentPdfs
        await this.orderService.updateOrder(updatedFiles);
      } catch (error: any) {
        let errorMessage = error?.error?.error_message;
        if (!errorMessage) {
          this.translate.get('errMsg.deleteFile').subscribe((res: string) => {
            errorMessage = res;
          });
        }

        this.translate.get('errMsg.error').subscribe((res: string) => {
          this.toastr.error(errorMessage, res);
        });
      }
    } else {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate
          .get('errMsg.orderIdUserIdFilenameRequired')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
    }
  }

  async getImageUrls(filenames: string[], docId: string): Promise<string[]> {
    const orderId = this.currentState.currentEditOrder?.id!;
    const profileId = this.currentState.currentProfile?.id;
    const imageUrls: string[] = [];

    if (orderId && profileId && filenames.length > 0) {
      for (const filename of filenames) {
        const filePath = `profiles/${profileId}/${orderId}/output/${docId}/thumbnail/${filename}`;
        try {
          const fileRef = ref(this.storage, filePath);
          const url = await getDownloadURL(fileRef);
          imageUrls.push(url);
        } catch (error: any) {
          let errorMessage = error?.error?.error_message;
          if (!errorMessage) {
            this.translate.get('errMsg.imageUrl').subscribe((res: string) => {
              errorMessage = res;
            });
          }

          this.translate.get('errMsg.error').subscribe((res: string) => {
            this.toastr.error(errorMessage, res);
          });
        }
      }
    } else {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate
          .get('errMsg.orderId-userId-filename-required')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
    }
    return imageUrls;
  }

  async getFilenameUrlMap(
    docs: { docId: string; filenames: string[] }[]
  ): Promise<{ [key: string]: string }> {
    const orderId = this.currentState.currentEditOrder?.id!;
    const profileId = this.currentState.currentProfile?.id;
    const imageUrlMap: { [key: string]: string } = {};

    if (orderId && profileId) {
      for (const doc of docs) {
        if (doc.filenames.length > 0) {
          for (const filename of doc.filenames) {
            const filePath = `profiles/${profileId}/${orderId}/output/${doc.docId}/thumbnail/${filename}`;
            try {
              const fileRef = ref(this.storage, filePath);
              const url = await getDownloadURL(fileRef);
              imageUrlMap[`${doc.docId}-${filename}`] = url;
            } catch (error: any) {
              let errorMessage = error?.error?.error_message;
              if (!errorMessage) {
                this.translate
                  .get('errMsg.imageUrl')
                  .subscribe((res: string) => {
                    errorMessage = res;
                  });
              }

              this.translate.get('errMsg.error').subscribe((res: string) => {
                this.toastr.error(errorMessage, res);
              });
            }
          }
        }
      }
    } else {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate
          .get('errMsg.orderIdUserIdRequired')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
    }
    return imageUrlMap;
  }

  async getPresetThumbnail(presetId: string): Promise<string> {
    const profileId = this.currentState.profileId!;
    const filePath = `presets/${profileId}/${presetId}/thumbnail.jpg`;

    if (!profileId) {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate
          .get('errMsg.userProfileIdRequired')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      return '';
    }

    try {
      // Get a reference to the file in Firebase Storage
      const fileRef = ref(this.storage, filePath);

      // Get the download URL for the file
      return await getDownloadURL(fileRef);
    } catch (error: any) {
      let errorMessage = error?.error?.error_message;
      if (!errorMessage) {
        this.translate.get('errMsg.fileUrl').subscribe((res: string) => {
          errorMessage = res;
        });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      return '';
    }
  }

  async getOrderPresetThumbnail(orderId: string): Promise<string> {
    const profileId = this.currentState.profileId!;
    const filePath = `profiles/${profileId}/${orderId}/thumbnail.jpg`;

    if (!profileId) {
      let errorMessage = '';
      if (!errorMessage) {
        this.translate
          .get('errMsg.userProfileIdRequired')
          .subscribe((res: string) => {
            errorMessage = res;
          });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      return '';
    }

    try {
      // Get a reference to the file in Firebase Storage
      const fileRef = ref(this.storage, filePath);

      // Get the download URL for the file
      return await getDownloadURL(fileRef);
    } catch (error: any) {
      let errorMessage = error?.error?.error_message;
      if (!errorMessage) {
        this.translate.get('errMsg.fileUrl').subscribe((res: string) => {
          errorMessage = res;
        });
      }

      this.translate.get('errMsg.error').subscribe((res: string) => {
        this.toastr.error(errorMessage, res);
      });
      return '';
    }
  }

  async deletePresetFile(
    presetId: string,
    deleteType: 'pdf' | 'jpg' | 'both' = 'both'
  ): Promise<void> {
    const profileId = this.currentState.profileId!;
    const pdfPath = `presets/${profileId}/${presetId}/${presetId}.pdf`;
    const thumbnailPath = `presets/${profileId}/${presetId}/thumbnail.jpg`;

    if (!profileId) {
      let errorMessage = '';
      this.translate
        .get('errMsg.userProfileIdRequired')
        .subscribe((res: string) => {
          errorMessage = res;
          this.toastr.error(errorMessage, res);
        });
      return;
    }

    try {
      // Delete PDF if deleteType is 'pdf' or 'both'
      if (deleteType === 'pdf' || deleteType === 'both') {
        const fileRef = ref(this.storage, pdfPath);
        await deleteObject(fileRef);
      }

      // Delete the thumbnail if deleteType is 'jpg' or 'both'
      if (deleteType === 'jpg' || deleteType === 'both') {
        const thumbnailRef = ref(this.storage, thumbnailPath);
        await deleteObject(thumbnailRef);
      }
    } catch (error: any) {
      let errorMessage = error?.error?.error_message;
      if (!errorMessage) {
        this.translate.get('errMsg.deleteFile').subscribe((res: string) => {
          errorMessage = res;
          this.toastr.error(errorMessage, res);
        });
      } else {
        this.translate.get('errMsg.error').subscribe((res: string) => {
          this.toastr.error(errorMessage, res);
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.appStateSubscription?.unsubscribe();
  }
}
