import { Component, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, EMPTY } from 'rxjs';
import { takeUntil, take } from 'rxjs/operators';

import { AppState, KeycloakTokens } from './state/app-state';
import { LoadingComponent } from './components/shared/components/loading.component';
import { LanguageService, SupportedLanguages } from './services/language.service';
import { isObjectEmpty, isArrayEmpty } from './components/shared/utils/helper';
import { UserProfileService } from './services/user-profile.service';
import { AppStateService } from './services/app-state.service';
import { FirebaseService } from './services/firebase.service';
import { NetworkService } from './services/network.service';
import { SessionService } from './services/session.service';
import { environment } from '../environments/environment';

type Menu = {
  label: string;
  routerLink: string;
  redirectUrl?: string;
  icon: {
    default: string;
    active: string;
  };
};

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  public title = 'two-print-frontend';
  public selectedProfileId: string = '';
  public isSidenavMaximized: boolean = true;
  public menus: Menu[] = [
    {
      label: 'Orders',
      routerLink: '/orders',
      icon: {
        default: 'assets/icons/folder-icon-black.png',
        active: 'assets/icons/folder-icon-white.png',
      },
    },
    {
      label: 'Monitoring',
      routerLink: '/monitoring',
      icon: {
        default: 'assets/icons/monitor-chart-black.png',
        active: 'assets/icons/monitor-chart-white.png',
      },
    },
    {
      label: 'ConfigurationSets',
      routerLink: '/presets',
      icon: {
        default: 'assets/icons/email-action-settings-black.png',
        active: 'assets/icons/email-action-settings-white.png',
      },
    },
    {
      label: 'ePost',
      routerLink: '',
      redirectUrl: `${environment.ePostUrl}`,
      icon: {
        default: 'assets/logos/schweizerische-post-logo.svg',
        active: 'assets/icons/epost.png',
      },
    },
  ];
  public isOverlayVisible$: Observable<boolean> = EMPTY;

  constructor(
    private dialog: MatDialog,
    private auth: AngularFireAuth,
    private translate: TranslateService,
    public stateService: AppStateService,
    private networkService: NetworkService,
    private sessionService: SessionService,
    private firebaseService: FirebaseService,
    private languageService: LanguageService,
    private userProfileService: UserProfileService
  ) {
    let userLang: SupportedLanguages =
      this.languageService.currentLanguage || 'en';

    if (!userLang) {
      const supportedLanguages: SupportedLanguages[] = ['en', 'de', 'fr', 'it'];
      const browserLang = navigator.language.split('-')[0] as SupportedLanguages;
      userLang = supportedLanguages.includes(browserLang) ? browserLang : 'en';

      this.languageService.currentLanguage = userLang;
    }

    this.translate.use(userLang);
  }

  ngOnInit() {
    this.auth.user.subscribe(async (user) => {
      if (!!user) {
        this.stateService.appState$
          .pipe(take(1)) // Take the first emitted value
          .subscribe((state: AppState) => {
            if (!state.isLoggingIn) {
              // Only initialize if isLoggingIn is false
              this.userProfileService.initFromUser(user);
              // initialized refresh keycloak token
              this.sessionService.startRefreshInterval();
            }
          });
      }
    });

    let loadingDialogRef: MatDialogRef<LoadingComponent, any> | null;
    this.stateService.appState$
      .pipe(takeUntil(this.destroy$))
      .subscribe((appState) => {
        // set selected user profile id
        this.selectedProfileId = appState.profileId || '';
        this.isSidenavMaximized = appState.isNavExpanded;

        // REFACTOR. Separate loading dialog in every usecase.
        if (
          appState.orders.isLoading ||
          appState.isLoadingOrder ||
          appState.presets.isLoading ||
          appState.isLoadingPreset ||
          appState.isCreatingOrder ||
          appState.isLoggingIn ||
          appState.profiles.isLoading ||
          (!appState.profiles.isLoaded &&
            isArrayEmpty(appState.profiles.list) &&
            !isObjectEmpty(appState.currentUser))
        ) {
          if (!loadingDialogRef) {
            loadingDialogRef = this.dialog.open(LoadingComponent, {
              disableClose: true,
              panelClass: 'loading',
            });
          }
        } else {
          if (loadingDialogRef) {
            loadingDialogRef.close();
            loadingDialogRef = null;
          }
        }
      });

    this.isOverlayVisible$ = this.networkService.isOverlayVisible$;
  }

  openRedirectUrl(url: string): void {
    window.open(url, '_blank');
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
