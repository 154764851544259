import { Component, Input } from '@angular/core';
import {
  AddressValidationFlag,
  AddressValidationResult,
  PdfAnalysisMessage
} from '../../../../state/app-state';

import { ErrorMessagesService } from '../../../../services/error-messages.service';

@Component({
  selector: 'app-klara-status-flag',
  templateUrl: './klara-status-flag.component.html',
  styleUrls: ['./klara-status-flag.component.scss'],
})
export class KlaraStatusFlagComponent {
  @Input() pdfAnalysisMessage!: PdfAnalysisMessage;
  deliveryMessage: string | undefined;
  isFailed: boolean = false;

  constructor(
    public errorMessagesService: ErrorMessagesService,
  ) {}

  ngOnInit(): void {
    this.isFailed = this.pdfAnalysisMessage.klaraDeliveryStatus === 'FAILED';

    if (this.isFailed) {
      this.deliveryMessage = this.errorMessagesService.getErrorMessage(this.pdfAnalysisMessage.klaraDeliveryStatusMessage!); // temporary, will be replaced by the defined klara API error codes
    } else {
      this.deliveryMessage = this.pdfAnalysisMessage.klaraDeliveryStatus;
    }
  }
}
