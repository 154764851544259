<div class="background">
    <h1 class="title">{{"pages.invalidProfile.title" | translate}}</h1>
    <p class="message">
        {{"pages.invalidProfile.line1" | translate}}
    </p>
    <p class="message">
        {{"pages.invalidProfile.line2" | translate}}
    </p>
    <button mat-raised-button color="primary" class="action-btn" (click)="goToOrders()">
        {{"pages.invalidProfile.goToOrders" | translate}}
    </button>
</div>