import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

@Injectable()
export class DateConversionInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
          return event.clone({ body: this.convertDateStrings(event.body) });
        }
        return event;
      })
    );
  }

  private convertDateStrings(obj: any): any {
    if (typeof obj === 'string' && this.isDateString(obj)) {
      return new Date(obj);
    }

    if (typeof obj === 'object') {
      for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
          obj[key] = this.convertDateStrings(obj[key]);
        }
      }
    }

    return obj;
  }

  private isDateString(value: string): boolean {
    const dateRegEx = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?Z$/;
    return dateRegEx.test(value);
  }
}
