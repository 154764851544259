import {
  OnInit,
  OnDestroy,
  inject,
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  NgZone,
} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { onSnapshot, doc } from 'firebase/firestore';
import { Firestore } from '@angular/fire/firestore';
import {
  Storage,
  ref as storageRef,
  uploadBytesResumable,
} from '@angular/fire/storage';
import { getFunctions, httpsCallable } from '@angular/fire/functions';
import { MatDialog } from '@angular/material/dialog';
import { Subject, takeUntil, distinctUntilChanged } from 'rxjs';

import { SetBasePresetDialogComponent } from '../../shared/components/set-base-preset-dialog.component';
import { WarningDialogComponent } from '../../shared/components/app-warning-dialog.component';
import { PdfPresetService } from '../../../services/pdf-preset.service';
import { AppStateService } from '../../../services/app-state.service';
import { getCurrentDateTimeString } from '../../shared/utils/helper';
import { StorageService } from '../../../services/storage.service';
import {
  AppState,
  LoadingState,
  PdfAnalysisPreset,
} from '../../../state/app-state';

@Component({
  selector: 'app-preset-pdf-upload',
  templateUrl: './preset-pdf-upload.component.html',
  styleUrls: ['./preset-pdf-upload.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PresetPdfUploadComponent implements OnInit, OnDestroy {
  private destroy$ = new Subject<void>();
  private currentState!: AppState;
  private profileId: string = '';
  private storage: Storage = inject(Storage);
  private isEdit: boolean = false;
  public currentPreset: PdfAnalysisPreset | null = null;
  public isDragOver: boolean = false;
  public uploadProgressMap = new Map<string, { uploadProgress: number; isUploading: boolean }>();
  public uploadedFileNames = new Map<string, { id: string; filename: string; name: string; }>();
  private isDialogOpen: boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private zone: NgZone,
    private dialog: MatDialog,
    private firestore: Firestore,
    private pdfPresetService: PdfPresetService,
    private storageService: StorageService,
    private stateService: AppStateService
  ) {}

  async ngOnInit() {
    this.route.paramMap
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (params) => {
        const presetId = params.get('presetId');
        this.isEdit = !presetId ? false : true;
        if (presetId && !this.currentPreset) {
          this.stateService.startLoading(LoadingState.PresetLoading);
          await this.pdfPresetService.getPreset(presetId).then(() => {
            this.stateService.stopLoading(LoadingState.PresetLoading);
          });
        }
      });

    this.stateService.appState$
      .pipe(
        takeUntil(this.destroy$),
        distinctUntilChanged(
          (prev, curr) => prev.currentPreset === curr.currentPreset
        )
      )
      .subscribe((appState: AppState) => {
        this.currentState = appState;
        this.profileId = appState.profileId || '';
        this.currentPreset = appState.currentPreset;
        if (this.isEdit && this.currentPreset) {
          const { id, filename = '' } = this.currentPreset;
          const fileNameWithExtension = `${id}.pdf`;
          if (!this.uploadedFileNames.has(fileNameWithExtension)) {
            this.uploadedFileNames.set(fileNameWithExtension, {
              id,
              filename: fileNameWithExtension,
              name: filename,
            });
            this.uploadProgressMap.set(filename, {
              uploadProgress: 100,
              isUploading: false,
            });
          }
          this.openDialog();
        }
      });
  }

  get checkUploadsStatus(): 'completed' | 'uploading' {
    return Array.from(this.uploadProgressMap.values()).every(
      ({ isUploading }) => !isUploading
    )
      ? 'completed'
      : 'uploading';
  }

  get uploadedFileNamesArray(): {
    id: string;
    filename: string;
    name: string;
  }[] {
    return Array.from(this.uploadedFileNames.values());
  }

  async checkFileType(file: File): Promise<boolean> {
    if (!file.type.match('application/pdf.*')) {
      this.dialog.open(WarningDialogComponent, {
        width: '400px',
        data: {
          message:
            'Your file type is not supported. Please upload only PDF format files.',
        },
      });
      return false;
    }
    return true;
  }


  async uploadFile(file: File, filename: string, id: string) {
    const filePath = `presets/${this.profileId}/${id}/${filename}`;

    this.uploadProgressMap.set(filename, {
      uploadProgress: 0,
      isUploading: true,
    });

    this.uploadedFileNames.set(filename, {
      id,
      filename,
      name: file.name,
    });

    const fileRef = storageRef(this.storage, filePath);
    const task = uploadBytesResumable(fileRef, file);

    task.on(
      'state_changed',
      (snapshot) => {
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;

        this.uploadProgressMap.set(filename, {
          uploadProgress: progress,
          isUploading: true,
        });

        this.changeDetectorRef.detectChanges();

        switch (snapshot.state) {
          case 'paused':
            console.log(
              'Upload is paused',
              'name:',
              file.name,
              'progress:',
              progress
            );
            break;
          case 'running':
            console.log(
              'Upload is running',
              'name:',
              file.name,
              'progress:',
              progress
            );
            break;
        }
      },
      (error) => {
        console.log(error);
      },
      async () => {
        this.zone.run(async () => {
          // Update progress map
          this.uploadProgressMap.set(filename, {
            uploadProgress: 100,
            isUploading: false,
          });

          this.changeDetectorRef.detectChanges();
          this.openDialog();
        });
      }
    );
  }

  async selectUploadFile(input: HTMLInputElement) {
    if (!input.files || input.files.length === 0) {
      return;
    }

    const file = input.files[0];

    const isValidFileType = await this.checkFileType(file);
    if (!isValidFileType) {
      return;
    }

    const name = `New Preset ${getCurrentDateTimeString()}`;
    const partialPreset: Partial<PdfAnalysisPreset> = {
      nameDe: name,
      nameEn: name,
      nameFr: name,
      nameIt: name,
      profileIds: [this.profileId],
      filename: file.name,
    };

    try {
      this.stateService.startLoading(LoadingState.PresetLoading);
      const newPreset = await this.pdfPresetService.createNewPreset(partialPreset);
      const id = newPreset.id;
      const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? 'pdf';
      const fileNameWithExtension = `${id}.${fileExtension}`;

      this.stateService.stopLoading(LoadingState.PresetLoading);
      await this.uploadFile(file, fileNameWithExtension, id);

      // Reset input value
      input.value = '';
    } catch (error) {
      console.error('Failed to create new preset:', error);
    }
  }

  onDragOver(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = true;
  }

  onDragLeave(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;
  }

  async onFileDrop(event: DragEvent) {
    event.preventDefault();
    this.isDragOver = false;

    if (event.dataTransfer) {
      let file: File | null = null;

      if (
        event.dataTransfer.items &&
        event.dataTransfer.items[0].kind === 'file'
      ) {
        file = event.dataTransfer.items[0].getAsFile();
      } else if (event.dataTransfer.files.length > 0) {
        file = event.dataTransfer.files[0];
      }

      if (!file || this.uploadedFileNamesArray.length) {
        return;
      }

      const invalidFileType = await this.checkFileType(file);
      if (!invalidFileType) {
        return;
      }

      const name = `New Preset ${getCurrentDateTimeString()}`;
      const partialPreset: Partial<PdfAnalysisPreset> = {
        nameDe: name,
        nameEn: name,
        nameFr: name,
        nameIt: name,
        profileIds: [this.profileId],
        filename: file.name,
      };

      try {
        this.stateService.startLoading(LoadingState.PresetLoading);
        const newPreset = await this.pdfPresetService.createNewPreset(partialPreset);
        const id = newPreset.id;
        const fileExtension = file.name.split('.').pop()?.toLowerCase() ?? 'pdf';
        const fileNameWithExtension = `${id}.${fileExtension}`;

        this.stateService.stopLoading(LoadingState.PresetLoading);
        await this.uploadFile(file, fileNameWithExtension, id);
      } catch (error) {
        console.error('Failed to create new preset:', error);
      }
    }
  }

  async removeFile(filename: string, fileId: string) {
    this.stateService.startLoading(LoadingState.PresetLoading);

    // Delete the file from storage
    await this.storageService.deletePresetFile(fileId, 'pdf');

    // Delete the preset associated with the file
    await this.pdfPresetService.deletePreset(fileId);

    this.stateService.stopLoading(LoadingState.PresetLoading);

    // Update local state.
    this.uploadProgressMap.delete(filename);
    this.uploadedFileNames.delete(filename);

    setTimeout(() => {
      this.changeDetectorRef.detectChanges();
    });
  }

  async onNext(basePreset: PdfAnalysisPreset | null = null) {
    const userId = this.currentState?.currentUser?.uid;
    const { id: presetId, ...otherPresetProps } = this.currentPreset || {};

    if (!this.profileId || !userId || !presetId) {
      console.error('Profile, User and Preset ID are required.');
    } else {
      this.stateService.startLoading(LoadingState.PresetLoading);
      let updatePreset = { ...otherPresetProps, status: 'generating', deleteType: 'both' } as PdfAnalysisPreset;
      this.currentPreset = { ...this.currentPreset, ...updatePreset };
      if (basePreset) {
        const {
          subject,
          docType,
          processType,
          ebillDueDate,
          printMetadata,
          whiteLineBoxLocation,
          requiresBlankFirstPage,
          emailAddressBoxLocation,
          mobileNumberBoxLocation,
          postalAddressBoxLocation,
          postalDeliveryTypeDomestic,
          postalDeliveryTypeInternational,
          isAddressValidationRequired,
        } = basePreset;

        updatePreset = {
          ...updatePreset,
          subject,
          docType,
          processType,
          ebillDueDate,
          printMetadata,
          whiteLineBoxLocation,
          requiresBlankFirstPage,
          emailAddressBoxLocation,
          mobileNumberBoxLocation,
          postalAddressBoxLocation,
          postalDeliveryTypeDomestic,
          postalDeliveryTypeInternational,
          isAddressValidationRequired,
        } as PdfAnalysisPreset;
        await this.pdfPresetService.updatePreset(presetId, updatePreset);
      } else {
        await this.pdfPresetService.updatePreset(presetId, updatePreset);
      }

      const publishPresetEditorInitializer = httpsCallable(
        getFunctions(undefined, 'europe-west6'),
        'publishPresetEditorInitializer'
      );

      await publishPresetEditorInitializer({ presetId, userId, profileId: this.profileId })
        .then(({ data }: any) => {
          if (data.status === 'success') {
            // Real-time subscription to listen for status changes in Firestore
            const presetRef = doc(this.firestore, `pdf-analysis-presets/${presetId}`);
            const unsubscribe = onSnapshot(presetRef, (docSnapshot) => {
              const presetData = docSnapshot.data();

              if (presetData?.['status'] === 'processed') {
                unsubscribe(); // Important: Unsubscribe to stop listening for changes
                this.stateService.stopLoading(LoadingState.PresetLoading);
                this.router.navigate(['/', this.profileId, 'presets', presetId, 'edit']);
              }
            });
          } else {
            this.stateService.stopLoading(LoadingState.PresetLoading);
            console.log(
              'Error occurs on Publish Preset Editor Initializer',
              data.message
            );
          }
        })
        .catch((err: any) => {
          console.log('Error occurs on Publish Preset Editor Initializer', err);
          this.stateService.stopLoading(LoadingState.PresetLoading);
        });
    }
  }

  onCancel() {
    this.router.navigate([this.profileId, 'presets']);
  }

  openDialog(): void {
    if (!this.isDialogOpen) {
      this.isDialogOpen = true;
      const dialogRef = this.dialog.open(SetBasePresetDialogComponent, {
        width: '760px',
      });
    
      dialogRef.afterClosed().subscribe((result: any) => {
        if (result) {
          if (result.action === 'cancel') {
            this.onCancel();
          } else if (result.action === 'next') {
            const { data } = result || {};
            this.onNext(data?.basePreset);
          }
        }
        this.isDialogOpen = false;
      });
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
    this.uploadProgressMap.clear();
    this.uploadedFileNames.clear();
  }
}
