<div *ngIf="showStepIndicator" style="display: flex; flex-direction: row; margin: 32px 68px;">
    <div *ngFor="let step of steps;" style="
            display: flex; flex-direction: row; align-items: center;">
        <ng-template [ngIf]="step.number < 6" [ngIfElse]="completed">
            <div class="bullet {{step.status}}" [ngClass]="{first: step.number == 1}">
                <span style="font-size: 16px;">{{step.number}}</span>
            </div>
            <div class="caption {{step.status}}" style="margin-left: 16px; font-size: 16px;">
                {{ translateStepCaption(step.caption) }}
            </div>
        </ng-template>
        <ng-template #completed>
            <div class="bullet {{step.status}}">
                <mat-icon style="font-size: 24px">check</mat-icon>
            </div>
        </ng-template>
    </div>
</div>
